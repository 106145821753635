import { colors } from '../themes/generalStyles'
import StarIcon from '@mui/icons-material/Star'
import { Grid } from '@mui/material'

const ruleOfStar = (count: number): number => {
  if (count < 50) return 1
  else if (count < 200) return 2
  else if (count < 500) return 3
  else if (count < 700) return 4
  else if (count < 1000) return 5
  return 6
}

const Star = () => (
  <StarIcon
    sx={{
      color: colors.warning,
      fontSize: 40,
      transform: 'rotate(35deg)',
    }}
  />
)

const StarIcons = ({ count }: { count: number }) => {
  const emptyArr = Array.from({ length: ruleOfStar(count) }, () => 0)
  return (
    <div
      style={{
        width: 120,
        height: 100,
        transform: 'rotate(180deg)',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Grid container justifyContent='center' alignItems='center'>
        {emptyArr.map((_, key) => (
          <Star key={key} />
        ))}
      </Grid>
    </div>
  )
}

export default StarIcons
