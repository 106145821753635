import { useContext, useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { postInitiateSmsAuth, postSmsCode } from '../api/fetch'
import ModalWrapper from './ModalWrapper'
import UserContext from '../Contexts/UserContext'
import { buttonMainStyle, colors } from '../themes/generalStyles'
import useApi from '../hooks/useApi'

const Login = () => {
  const {
    setUserLogin,
    setUserData,
    userLogin,
    loggedIn,
    tokenInvalidatesAfter,
  } = useContext(UserContext)

  const { doFetch: doFetchInstantiateAuth, isLoading: instatiateIsLoading } =
    useApi(postInitiateSmsAuth)

  const { doFetch: doFetchSmsCode, isLoading: smsLoading } = useApi(postSmsCode)
  const isLoading = instatiateIsLoading || smsLoading

  const [smsAuth, setSmsAuth] = useState(false)
  const [error, setError] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState(
    (userLogin && userLogin.phoneNumber.replace('+47', '')) || '',
  )
  const [smsCode, setSmsCode] = useState('')
  const [disableSmsSender, setDisableSmsSender] = useState(false)
  const nowTime = new Date().getTime()

  const localStorageLoginValid = (): boolean => {
    return (
      !!(userLogin && userLogin.phoneNumber === `+47${phoneNumber}`) &&
      nowTime - userLogin.created < tokenInvalidatesAfter
    )
  }

  const buttonStyles = {
    borderRadius: 5,
    fontSize: 18,
    marginTop: 10,
  }
  useEffect(() => {
    setSmsAuth(false)
  }, [loggedIn])

  const executeSender = () => {
    setDisableSmsSender(true)
    doFetch(true)
    setTimeout(() => {
      setDisableSmsSender(false)
    }, 8000)
  }

  const doFetch = (requestCode = false) => {
    if (smsAuth && !requestCode) {
      doFetchSmsCode({
        data: { code: smsCode, phone_number: `+47${phoneNumber}` },
        onError: () => setError('Feil sms kode, prøv igjen'),
        onSuccess: data => {
          const created = new Date().getTime()
          // trying to log in again with the same user after logging out
          if (userLogin && userLogin?.phoneNumber === `+47${phoneNumber}`) {
            setUserLogin({
              ...userLogin,
              created: created,
              token: data.token,
            })
          } else {
            setUserLogin({
              created: created,
              token: data.token,
              phoneNumber: data.phone_number,
              newsCount: 0,
            })
          }
          location.href = '/'
        },
        customHeaderKeys: {
          code: smsCode,
          phone_number: `+47${phoneNumber}`,
        },
      })
    } else {
      doFetchInstantiateAuth({
        data: { phone_number: `+47${phoneNumber}` },
        onSuccess: () => {
          setSmsAuth(true)
          setError('')
        },
        onError: () =>
          setError(
            'Noe gikk galt, er du sikker du skrev inn riktig telefonnummer og at du har laget bruker hos oss? Hvis feilen vedvarer selv med riktig telefonnummer, ta gjerne kontakt.',
          ),
      })
    }
  }
  return (
    <ModalWrapper header='Logg inn' openModalKey='login'>
      <div style={{ width: '100vw', maxWidth: 500 }}>
        {error && <span style={{ color: 'red' }}>{error}</span>}
        {!error && smsAuth && localStorageLoginValid() && (
          <span style={{ color: colors.main }}>
            Du kan gjenbruke din forrige sms ved å finne den siste sms koden du
            fikk av ISTEADY AS
          </span>
        )}
        <br />
        <TextField
          id='outlined-basic'
          label={smsAuth ? 'Sms kode (4 tall)' : 'Telefonnummer'}
          variant='filled'
          value={smsAuth ? smsCode : phoneNumber}
          onChange={e => {
            if (!isNaN(Number(e.target.value))) {
              if (smsAuth) {
                setSmsCode(e.target.value)
              } else {
                setPhoneNumber(e.target.value)
              }
            }
          }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                {!smsAuth && '+47'}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {isLoading && <CircularProgress />}
              </InputAdornment>
            ),
          }}
        />
        <br />
        <Grid container alignItems='center' gap={2}>
          <Button
            sx={buttonMainStyle}
            style={{ ...buttonStyles, marginRight: 20 }}
            size='large'
            onClick={() =>
              !smsAuth && localStorageLoginValid()
                ? setSmsAuth(true)
                : doFetch()
            }>
            {smsAuth ? 'Logg inn med sms kode' : 'Be om innloggingskode'}
          </Button>
          {smsAuth && localStorageLoginValid() && (
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: disableSmsSender ? colors.disabled : colors.darkgrey,
              }}
              onClick={() => executeSender()}>
              {disableSmsSender ? 'Sender kode...' : 'Send ny sms kode'}
            </span>
          )}
        </Grid>
      </div>
    </ModalWrapper>
  )
}

export default Login
