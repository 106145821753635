import { Box } from '@mui/material'
import { boxNewsStyle } from '../themes/generalStyles'
import React, { useContext } from 'react'
import UserContext from '../Contexts/UserContext'
import Link from '../Utils/Link'

const NewsTag = () => {
  const { userData } = useContext(UserContext)
  const circleRadius = 25
  const unreadNews = userData && userData.newsCount
  return (
    <Link to='/nyheter'>
      <div style={{ color: 'black', fontSize: 22, marginRight: 30 }}>
        <Box sx={boxNewsStyle}>Nyheter</Box>
        {unreadNews !== 0 && (
          <div
            style={{
              borderRadius: 12,
              backgroundColor: 'red',
              color: 'white',
              position: 'absolute',
              transform: 'translate(80px, -40px)',
              width: circleRadius,
              height: circleRadius,
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {unreadNews}
          </div>
        )}
      </div>
    </Link>
  )
}

export default NewsTag
