import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { useContext } from 'react'
import createUserContext from '../../../Contexts/CreateUserContext'
import settings from '../../../config'
import screenScaleContext from '../../../Contexts/ScreenScaleContext'

const PageThree = () => {
  const { setFormData } = useContext(createUserContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <Grid container direction='row' justifyContent='center'>
      <h1 style={{ textAlign: 'center' }}>iSteady Personvern</h1>
      <iframe
        title='Personvern'
        style={{ width: '100%', height: '50vh' }}
        src={`${settings.bucket}/legal/privacyTerms.pdf`}
      />
      <h1 style={{ textAlign: 'center' }}>
        iSteady <br />
        {smallScreenWidth ? 'Medlemskap - vilkår' : 'Medlemskapvilkår'}
      </h1>
      <iframe
        title='Medlemskapvilkår'
        style={{ width: '100%', height: '50vh' }}
        src={`${settings.bucket}/legal/membershipTerms.pdf`}
      />
      <h1 style={{ textAlign: 'center' }}>
        iSteady {smallScreenWidth ? 'Nettbutikk - vilkår' : 'Nettbutikkvilkår'}
      </h1>
      <iframe
        title='Nettbutikkvilkår'
        style={{ width: '100%', height: '50vh' }}
        src={`${settings.bucket}/legal/webShopTerms.pdf`}
      />
      <div>
        <FormControlLabel
          onChange={(_, checked) =>
            setFormData(prevState => ({ ...prevState, terms: checked }))
          }
          sx={{ margin: 1 }}
          control={<Checkbox />}
          label='Jeg har lest og godkjent personvern og vilkårene til iSteady'
        />
      </div>
    </Grid>
  )
}

export default PageThree
