import { useContext, useState } from 'react'
import userContext from '../Contexts/UserContext'
import { AxiosRequestConfig } from 'axios'

type DoFetchProps = {
  data?: { [key: string]: any }
  onSuccess?: (data: any) => void | null
  onError?: () => void | null
  customHeaderKeys?: { [key: string]: string }
}

export type UseAPIType = {
  data: null | { [key: string]: any }
  doFetch: (props: DoFetchProps) => void
  isLoading: boolean
}

const useApi = (
  fn: (
    data: { [key: string]: any },
    headers: AxiosRequestConfig,
  ) => Promise<any>,
): UseAPIType => {
  const { userLogin } = useContext(userContext)
  const [apiResponse, setApiResponse] = useState<UseAPIType['data']>(null)
  const [isLoading, setIsloading] = useState<boolean>(false)

  const doFetch = (props: DoFetchProps) => {
    setIsloading(true)
    const { data, onSuccess, onError, customHeaderKeys } = props

    const phoneNumberFormatted = (userLogin && userLogin.phoneNumber) || ''
    const phoneNumber = phoneNumberFormatted.startsWith('+47')
      ? phoneNumberFormatted
      : `+47${phoneNumberFormatted}`

    const headers = {
      headers: {
        token: (userLogin && userLogin.token) || '',
        ...customHeaderKeys,
      },
    }
    const dataFormatted = data?.phone_number
      ? {
          ...data,
          phone_number: data.phone_number.startsWith('+47')
            ? data.phone_number
            : `+47${data.phone_number}`,
        }
      : { ...data, phone_number: phoneNumber }
    // do request
    fn(dataFormatted || {}, headers)
      .then(dta => {
        setApiResponse(dta.data)
        onSuccess && onSuccess(dta.data)
      })
      .catch(e => {
        console.warn(e)
        onError && onError()
      })
      .finally(() => setIsloading(false))
  }

  return {
    data: apiResponse || null,
    doFetch,
    isLoading,
  }
}

export default useApi
