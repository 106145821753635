import MainCard from '../MainCard'
import { Box, Grid } from '@mui/material'
import { useContext } from 'react'
import { colors } from '../../../themes/generalStyles'
import UserContext from '../../../Contexts/UserContext'
import DaySelect from './DaySelect'
import WorkoutContext from '../../../Contexts/WorkoutContext'
import daySorter from '../../../helpers/daySorter'
import { isEmpty } from 'lodash'
import TopLogoCard from '../TopLogoCard'
import ScheduleScrollButtons from '../../../Utils/ScheduleScrollButtons'
import Center from '../../../Utils/Center'
import screenScaleContext from '../../../Contexts/ScreenScaleContext'
import modalContext from '../../../Contexts/ModalContext'
import SessionInfoCard from '../../../Utils/SessionInfoCard'

const SelectTraining = () => {
  const { assignedSessions } = useContext(UserContext)
  const { smallScreenWidth, screenHeight, screenWidth } =
    useContext(screenScaleContext)
  const { setOpenModal } = useContext(modalContext)
  const { page, increment } = useContext(WorkoutContext)

  const activeDays = daySorter(Object.keys(assignedSessions))
  const hideScrollButtons = activeDays.length <= increment || increment === 10
  return (
    <>
      <Center>
        <Box
          sx={{
            width: '100%',
            maxWidth: 900,
          }}>
          <MainCard noPadding sx={{ width: '100%' }}>
            {!isEmpty(activeDays) ? (
              <>
                <Grid
                  container
                  gap={2}
                  sx={{ padding: 3, width: '100%' }}
                  justifyContent='center'>
                  {activeDays
                    .slice(page * increment, page * increment + increment)
                    .map((day, key) => (
                      <div
                        key={key}
                        onClick={() =>
                          smallScreenWidth &&
                          setOpenModal('smallerdeviceWorkoutDetails')
                        }>
                        <h1
                          style={{
                            borderRadius: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 0,
                            marginBottom: 10,
                            fontSize: 40,
                            color: colors.darkgrey,
                          }}>
                          {day[0].toUpperCase() + day.slice(1, day.length)}
                        </h1>
                        <DaySelect day={day} />
                      </div>
                    ))}
                </Grid>
                {!hideScrollButtons && (
                  <ScheduleScrollButtons
                    activeDays={activeDays}
                    increment={increment}
                  />
                )}
              </>
            ) : (
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                direction='column'
                sx={{
                  width: '100%',
                  minHeight: 300,
                }}>
                <h1
                  style={{
                    textAlign: 'center',
                    color: colors.secondGray,
                    maxWidth: '70%',
                    fontWeight: 'bold',
                  }}>
                  Her vises gruppetimene du melder deg på
                </h1>
              </Grid>
            )}
          </MainCard>
        </Box>
      </Center>
      {!smallScreenWidth && (
        <>
          <TopLogoCard
            content='Din neste gruppetime'
            sx={{ marginTop: 1, marginBottom: 1 }}
          />
          <SessionInfoCard />
        </>
      )}
    </>
  )
}

export default SelectTraining
