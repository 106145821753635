import { font } from '../../themes/generalStyles'
import { Grid } from '@mui/material'
import baseColors from '../../themes/baseColors'
import ShoulderAndKneesInfo from '../MainPage/Workouts/ShoulderAndKneesInfo'
import StrongAndSteadyInfo from '../MainPage/Workouts/StrongAndSteadyInfo'
import ModalWrapper from '../../Modals/ModalWrapper'
import sessionNames from '../../constants'
import settings from '../../config'

const SessionInfoModal = ({ sessionName }: { sessionName: string }) => {
  const largeSize = sessionName === sessionNames['strongAndSteady']
  const modalBodyStyle = {
    width: largeSize ? 900 : 400,
    backgroundColor: baseColors.modalBackgroundColor,
    borderRadius: 20,
    padding: 40,
    ...font,
  }
  return (
    <ModalWrapper
      header='Økt info'
      openModalKey='seassionSchedule'
      styles={{ height: largeSize ? 750 : 650, width: largeSize ? 900 : 400 }}>
      <Grid container justifyContent='center'>
        {settings.sessionNames.includes(sessionName) && (
          <ShoulderAndKneesInfo selectedWorkout={sessionName} />
        )}
        {sessionName === sessionNames['strongAndSteady'] && (
          <StrongAndSteadyInfo />
        )}
      </Grid>
    </ModalWrapper>
  )
}

export default SessionInfoModal
