const daySorter = (dys: string[]): string[] => {
  const days = [
    'mandag',
    'tirsdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lørdag',
    'søndag',
  ]
  return days.reduce((results, item) => {
    if (dys.includes(item)) {
      results.push(item)
    }
    return results
  }, [] as string[])
}

export default daySorter
