import MainCard from '../MainCard'
import { Grid } from '@mui/material'
import settings from '../../../config'
import sessionNames from '../../../constants'

const ImageLookup = (workoutName: string): string | null => {
  const base = {
    [sessionNames['pulse']]: 'bungieWoman.png',
    [sessionNames['strongKnees']]: 'handsoutImage.png',
    [sessionNames['strongShoulders']]: 'bungie.png',
  } as { [key: string]: string }
  return base[workoutName]
    ? `${settings.bucket}/root_images/${base[workoutName]}`
    : null
}

const ShoulderAndKneesInfo = ({
  header = false,
  selectedWorkout,
}: {
  header?: boolean
  selectedWorkout: string
}) => {
  const imageBase = ImageLookup(selectedWorkout)
  const image = imageBase || require('../../../Images/isteadyLogo.png')
  return (
    <MainCard
      noPadding
      style={{
        width: 350,
        height: 500,
        boxShadow:
          '0px 1px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 10px rgba(0, 0, 0, 0.25)',
      }}>
      <Grid container direction='column' alignItems='center'>
        <Grid item sx={{ width: 250 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h2 style={{ marginBottom: 0 }}>{selectedWorkout}</h2>
          </div>
        </Grid>
        <Grid
          item
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
            width: 250,
          }}>
          <img
            alt='some id 2'
            src={image}
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: 5,
            }}
          />
        </Grid>
        <Grid item sx={{ width: 250 }}>
          <div style={{ padding: 10 }}>
            {header && <h2>{selectedWorkout}</h2>}
            <div>
              {selectedWorkout === sessionNames['strongKnees'] && (
                <>
                  <strong>{sessionNames['strongKnees']}</strong> innebærer
                  mobiliserings- og styrkeøvelser for underkropp med fokus på
                  muskelaktivering.
                </>
              )}

              {selectedWorkout === sessionNames['strongShoulders'] && (
                <>
                  <strong>{sessionNames['strongShoulders']}</strong> innebærer
                  spesifikk trening av stabiliserende muskulatur og
                  bevegelighetsøvleser for skuldre og nakke.
                </>
              )}
              {selectedWorkout === sessionNames['pulse'] && (
                <>
                  <strong>{sessionNames['pulse']}</strong> er en rask og
                  intensiv økt med fokus på utholdenhet
                </>
              )}
            </div>
            <div style={{ marginTop: 20 }}>
              <strong>Varighet:</strong> 20 min <br />
              <strong>Utstyr:</strong>
              {selectedWorkout === sessionNames['pulse'] &&
                ' Strikk, stol og sko'}
              {selectedWorkout === sessionNames['strongKnees'] &&
                ' Strikk, stol og tykkere pute'}
              {selectedWorkout === sessionNames['strongShoulders'] &&
                ' Strikk og stol'}
            </div>
          </div>
        </Grid>
      </Grid>
    </MainCard>
  )
}

export default ShoulderAndKneesInfo
