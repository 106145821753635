import {
  buttonDisabledStyle,
  buttonSuccessStyle,
  colors,
} from '../../../themes/generalStyles'
import { Button, Grid, Grow } from '@mui/material'
import WorkoutBase from '../Workouts/WorkoutBase'
import { useContext } from 'react'
import UserContext from '../../../Contexts/UserContext'
import WorkoutContext from '../../../Contexts/WorkoutContext'
import modalContext from '../../../Contexts/ModalContext'
import { SessionType } from '../../../helpers/apiResponsFormatter'
import { postJoinOrLeaveWorkout } from '../../../api/fetch'
import useApi from '../../../hooks/useApi'
import PickAvailableSlot from './PickAvailableSlot'
import MainCard from '../MainCard'

const WorkoutDetailedInfo = ({
  selectedWorkout,
}: {
  selectedWorkout: string
}) => {
  const { userLogin, userData, loggedIn, setUserData } = useContext(UserContext)
  const { setOpenModal } = useContext(modalContext)
  const {
    selectedDay,
    selectedTime,
    selectedLevel,
    setSelectedWorkout,
    setApiModal,
  } = useContext(WorkoutContext)
  const { doFetch } = useApi(postJoinOrLeaveWorkout)
  const relevantWorkoutsUnderWorkouts = (userData?.allSessions || []).filter(
    item =>
      item.day === selectedDay?.toLowerCase() &&
      selectedWorkout &&
      item.sessionType?.name === selectedWorkout,
  )

  const joinWorkout = (): void => {
    if (!userData?.validSubscription) {
      setOpenModal('subscriptions')
    } else if (
      userLogin &&
      loggedIn &&
      selectedTime &&
      selectedWorkout &&
      typeof selectedLevel === 'number' &&
      selectedDay &&
      userData
    ) {
      // finding valid workout:
      const foundWorkout = userData.allSessions.find(
        item =>
          item.day === selectedDay.toLowerCase() &&
          item.time === selectedTime &&
          item.sessionType?.name === selectedWorkout &&
          item.sessionType?.level === selectedLevel,
      )
      foundWorkout &&
        doFetch({
          data: {
            action: 'create',
            session_type: foundWorkout.id,
          },
          onSuccess: () => {
            const dayLower = selectedDay?.toLowerCase()
            const foundWorkout = userData.allSessions.find(
              item =>
                item.day === dayLower &&
                item.time === selectedTime &&
                item.sessionType?.name === selectedWorkout &&
                item.sessionType?.level === selectedLevel,
            )
            if (dayLower && foundWorkout) {
              setUserData(prevState =>
                prevState
                  ? {
                      ...prevState,
                      sessions: [...prevState.sessions, foundWorkout],
                    }
                  : null,
              )
              setApiModal({
                key: 'joinedSession',
                header: 'Du er nå påmeldt denne gruppetimen ',
                day: selectedDay || '',
                level: String(selectedLevel),
                workoutName: selectedWorkout || '',
                time: selectedTime || '',
              })
              setSelectedWorkout('')
              setOpenModal('')
            }
          },
        })
    }
  }

  const { selectedWorkouts, notSelectedWorkouts, singleLevelType } =
    relevantWorkoutsUnderWorkouts.reduce(
      (results, item) => {
        if (item.sessionType?.level === 0) {
          results.singleLevelType = true
        }
        // determine if the workout has already been selected
        const selectedW = (userData?.sessions || [])
          .filter(it => it.day === selectedDay?.toLowerCase())
          .map(item_ => `${item_.sessionType?.level}${item_.time}`)
        if (selectedW.includes(`${item.sessionType?.level}${item.time}`)) {
          results.selectedWorkouts.push(item)
        } else {
          results.notSelectedWorkouts.push(item)
        }
        return results
      },
      {
        selectedWorkouts: [],
        notSelectedWorkouts: [],
        singleLevelType: false,
      } as {
        selectedWorkouts: SessionType[]
        notSelectedWorkouts: SessionType[]
        singleLevelType: boolean
      },
    )

  const todaySelectedSessions = (userData?.sessions || []).flatMap(item =>
    item.day === selectedDay?.toLowerCase() ? [item.time] : [],
  )
  const toManyThisDay = todaySelectedSessions.length >= 3
  const timeIsANoGo = todaySelectedSessions.includes(selectedTime)

  const disableButton =
    !selectedTime ||
    typeof selectedLevel !== 'number' ||
    toManyThisDay ||
    timeIsANoGo
  const imageDn =
    userData?.workoutImages[selectedWorkout + (selectedLevel || '').toString()]

  return (
    <MainCard
      noPadding
      style={{
        borderRadius: 8,
        padding: 10,
        marginTop: 0,
        paddingTop: 0,
      }}>
      <Grow in={true} timeout={500}>
        <div>
          <Grid
            container
            direction='row'
            alignItems='center'
            justifyContent='space-around'>
            <Grid item>
              <Grow in={true} timeout={800}>
                <div>
                  <WorkoutBase title={selectedWorkout} image={imageDn} />
                </div>
              </Grow>
            </Grid>
            <Grid item>
              {notSelectedWorkouts.length !== 0 ? (
                <PickAvailableSlot
                  notSelectedWorkouts={notSelectedWorkouts}
                  singleLevelType={singleLevelType}
                />
              ) : (
                <strong>Ingen tilgjengelig</strong>
              )}
            </Grid>
            <Grid item>
              <div>
                <Grid container direction='column' alignItems='center'>
                  <Grid item>
                    <Button
                      sx={
                        disableButton ? buttonDisabledStyle : buttonSuccessStyle
                      }
                      disabled={disableButton}
                      onClick={joinWorkout}
                      style={{
                        height: 60,
                        width: 180,
                        fontSize: 22,
                        borderRadius: 15,
                        fontWeight: 'bold',
                      }}>
                      Meld deg på
                    </Button>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <div
                        style={{
                          textAlign: 'center',
                          marginTop: 10,
                          display: 'flex',
                          fontSize: 12,
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          maxWidth: 180,
                        }}>
                        {toManyThisDay || timeIsANoGo ? (
                          toManyThisDay ? (
                            <span style={{ color: colors.error }}>
                              Du er meldt på maksimalt antall økter denne dagen,
                              og kan derfor ikke melde deg på en til. Hvis du
                              ønsker dette må du avmelde deg en av de andre
                              øktene.
                            </span>
                          ) : (
                            <span style={{ color: colors.error }}>
                              Du har allerede meldt deg på en økt for samme tid
                              denne dagen. Vennligst avmeld deg denne først
                            </span>
                          )
                        ) : (
                          'Du vil være meldt på gruppetimen til du bytter eller melder deg av'
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grow>
    </MainCard>
  )
}

export default WorkoutDetailedInfo
