import { TextField } from '@mui/material'
import { CSSProperties, Dispatch, SetStateAction } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import { FormDataType } from '../Contexts/CreateUserContext'

const TextInputField = ({
  error = '',
  disabled = false,
  label,
  value,
  setField,
  propKey,
  startAdornment = null,
  endAdornment = null,
  style = {},
}: {
  error?: string
  disabled?: boolean
  label: string
  value: string
  setField: Dispatch<SetStateAction<FormDataType>>
  propKey: string
  startAdornment?: JSX.Element | null
  endAdornment?: JSX.Element | null
  style?: CSSProperties
}) => {
  return (
    <TextField
      error={!!error}
      helperText={!!error && error}
      {...(startAdornment && {
        InputProps: {
          startAdornment: (
            <InputAdornment position='start'>{startAdornment}</InputAdornment>
          ),
          ...{
            endAdornment: endAdornment && (
              <InputAdornment position='end'>{endAdornment}</InputAdornment>
            ),
          },
        },
      })}
      disabled={disabled}
      style={{ marginTop: 15, ...style }}
      label={label}
      variant='filled'
      value={value}
      onChange={e => setField(prev => ({ ...prev, [propKey]: e.target.value }))}
      fullWidth
    />
  )
}

export default TextInputField
