import { Box, Grid } from '@mui/material'
import MainCard from '../Views/MainPage/MainCard'
import AutoSelectedInfo from '../Views/MainPage/WorkoutCalender/AutoSelectedInfo'
import NoDaySelectedPlaceholder from '../Views/MainPage/WorkoutCalender/NoDaySelectedPlaceholder'
import { colors } from '../themes/generalStyles'
import Center from './Center'
import { useContext } from 'react'
import userContext from '../Contexts/UserContext'
import workoutContext from '../Contexts/WorkoutContext'

const SessionInfoCard = () => {
  const { userData } = useContext(userContext)
  const { selectedWorkout, selectedDay, selectedWorkoutInfo } =
    useContext(workoutContext)
  return (
    <Center>
      <Box
        sx={{
          width: '100%',
          maxWidth: 900,
        }}>
        <MainCard>
          <>
            {!selectedDay ? (
              userData && userData.sessions.length && selectedWorkoutInfo ? (
                <AutoSelectedInfo selectedWorkoutInfo={selectedWorkoutInfo} />
              ) : (
                <NoDaySelectedPlaceholder />
              )
            ) : (
              <div>
                <Grid
                  container
                  direction='column'
                  gap={0}
                  sx={{ width: '100%' }}>
                  {selectedWorkoutInfo ? (
                    <AutoSelectedInfo
                      selectedWorkoutInfo={selectedWorkoutInfo}
                    />
                  ) : (
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      sx={{
                        color: colors.disabled,
                        minHeight: 300,
                        textAlign: 'center',
                      }}>
                      <h1>Her vises din neste påmeldte gruppetime</h1>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </>
        </MainCard>
      </Box>
    </Center>
  )
}

export default SessionInfoCard
