import { Box, Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import LogoImage from './LogoImage'
import baseColors from '../themes/baseColors'
import { Add, Cancel, CheckCircle, Person } from '@mui/icons-material'
import React, { useContext, useState } from 'react'
import CustomButton from '../Utils/CustomButton'
import UserContext from '../Contexts/UserContext'
import MenuTab from './MenuTab'
import { boxNewsStyle, colors, font } from '../themes/generalStyles'
import Link from '../Utils/Link'
import UnloggedInMenuTab from './UnloggedInMenuTab'
import BackButton from '../Utils/BackButton'
import { useLocation } from 'react-router-dom'
import modalContext from '../Contexts/ModalContext'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const TopNav = () => {
  const logo = require('../Images/isteadyLogo.png')
  const { loggedIn, userData, mediumScreen } = useContext(UserContext)
  const { setOpenModal } = useContext(modalContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const [menuComponent, setMenuComponent] = useState(null)
  const onMenuClose = () => setMenuComponent(null)
  const menuOpen = Boolean(menuComponent)
  const style = {
    backgroundColor: baseColors.navColor,
    height: 60,
    padding: 0,
    ...font,
  }
  const validSubscription = (userData && userData.validSubscription) || false
  const location = useLocation()
  const isBasePath = location.pathname === '/'
  const isVideoPath = location.pathname.includes('/session')

  return !isVideoPath ? (
    <AppBar style={style}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        {loggedIn && userData ? (
          <Grid
            direction='row'
            container
            alignItems='center'
            justifyContent='space-between'
            sx={{
              marginTop: -1,
              maxWidth: 1200,
            }}>
            <div
              style={{
                color: '#1DC3CF',
              }}>
              <Grid container direction='column'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link to='/'>
                    <Box sx={{ marginTop: 1, marginRight: 1, ...boxNewsStyle }}>
                      <img style={{ height: 40, width: 'auto' }} src={logo} />
                    </Box>
                  </Link>
                  <span>
                    {(isBasePath && smallScreenWidth) ||
                      (!smallScreenWidth && (
                        <Link to='/min-side'>
                          <Grid
                            container
                            direction='column'
                            sx={{ textAlign: 'center' }}>
                            <Grid
                              item
                              sx={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}>
                              <div>{userData.firstName}</div>
                            </Grid>
                            <Grid item>
                              <span
                                style={{
                                  color: 'grey',
                                  textDecoration: 'none',
                                }}>
                                #{userData.customerId}
                              </span>
                            </Grid>
                          </Grid>
                        </Link>
                      ))}
                  </span>
                  {'  '}
                  {validSubscription ? (
                    <CheckCircle
                      style={{ color: colors.success, marginLeft: 10 }}
                    />
                  ) : (
                    <Cancel
                      onClick={() => setOpenModal('subscriptions')}
                      style={{
                        color: colors.error,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </div>
              </Grid>
            </div>
            <MenuTab
              isBasePath={isBasePath}
              menuOpen={menuOpen}
              menuComponent={menuComponent}
              onMenuClose={onMenuClose}
              setMenuComponent={setMenuComponent}
            />
          </Grid>
        ) : (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            sx={{
              maxWidth: 1200,
              height: '100%',
            }}>
            <div
              style={{
                width: '100%',
                marginTop: 7,
              }}>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <Grid container alignItems='center' gap={1}>
                    <Link to='/'>
                      <LogoImage />
                    </Link>
                    {!smallScreenWidth && (
                      <>
                        <CustomButton
                          textBody='Logg inn'
                          iconPrefix={<Person />}
                          modalOpen='login'
                          clickFunction={true}
                        />
                        <Link to='/ny-bruker'>
                          <CustomButton
                            textBody='Meld deg inn'
                            iconPrefix={<Add />}
                            modalOpen=''
                            successStyle
                          />
                        </Link>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container gap={1}>
                    {!isBasePath && (
                      <Link to='..'>
                        <BackButton
                          sx={{ marginTop: 4 }}
                          onClick={() => null}
                        />
                      </Link>
                    )}
                    <UnloggedInMenuTab />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
    </AppBar>
  ) : (
    <></>
  )
}

export default TopNav
