import Login from './Login'
import Subscriptions from './Subscriptions'
import ContactUs from './ContactUs'
import SureToDeleteModal from './SureToDeleteModal'
import LogOut from './LogOut'
import { font } from '../themes/generalStyles'
import UpdateMyInfo from './UpdateMyInfo'

const Modals = () => {
  return (
    <div style={font}>
      <Login />
      <Subscriptions />
      <ContactUs />
      <SureToDeleteModal />
      <LogOut />
      <UpdateMyInfo />
    </div>
  )
}

export default Modals
