import { Box } from '@mui/material'
import { boxNewsStyle } from '../themes/generalStyles'

const LogoImage = ({ size = null }: { size?: number | null }): JSX.Element => {
  const isteadyLogo = require('../Images/isteadyLogo.png')
  const sizeModified = size ? size : 45
  return (
    <Box sx={boxNewsStyle}>
      <img
        alt='iSteady logo image'
        src={isteadyLogo}
        style={{
          width: sizeModified,
          height: sizeModified,
        }}
      />
    </Box>
  )
}

export default LogoImage
