import { Grid } from '@mui/material'
import { useContext } from 'react'
import createUserContext from '../../../Contexts/CreateUserContext'
import TextInputField from '../../../Utils/TextInputField'
import HouseIcon from '@mui/icons-material/House'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return emailRegex.test(email)
}

export const phoneNumberValidation = (phoneNumber: string): boolean => {
  const phoneRegex = /^\d{8}$/
  return phoneRegex.test(phoneNumber)
}

const PageTwo = () => {
  const { formData, setFormData } = useContext(createUserContext)
  const emailValid = formData.email_address
    ? validateEmail(formData.email_address)
      ? ''
      : 'Ugylding format, riktig format: example@email.com'
    : ''

  const phoneNumberValid = formData.phone_number
    ? phoneNumberValidation(formData.phone_number)
      ? ''
      : 'Ugyldig telefonnummer, må være 8 siffer langt'
    : ''
  return (
    <Grid container justifyContent='center'>
      <TextInputField
        startAdornment={<HouseIcon />}
        propKey='address'
        value={formData.address}
        label='Gateadresse'
        setField={setFormData}
      />
      <TextInputField
        startAdornment={<MarkunreadMailboxIcon />}
        propKey='postal_code'
        value={formData.postal_code}
        label='Postnummer'
        setField={setFormData}
      />
      <TextInputField
        error={phoneNumberValid}
        startAdornment={<div>+47</div>}
        propKey='phone_number'
        value={formData.phone_number}
        label='Telefonnummer'
        setField={setFormData}
      />
      <TextInputField
        error={emailValid}
        startAdornment={<AlternateEmailIcon />}
        propKey='email_address'
        value={formData.email_address}
        label='Epost'
        setField={setFormData}
      />
    </Grid>
  )
}

export default PageTwo
