export type DayType =
  | 'mandag'
  | 'tirsdag'
  | 'onsdag'
  | 'torsdag'
  | 'fredag'
  | 'lørdag'
  | 'søndag'

export type SessionType = {
  id: number
  day: DayType
  time: string
  sessionType: SessionTypeType | null
}

type SessionTypeType = {
  id: number
  info: string
  level: number
  name: string
  image__dn: string
}

type AllSessionType = {}

export type Subscription = {
  id: string
  name: string
  binding_period: string
  description: string
  price: number
  tag: { title: string; color: string | null }
}

export type UserDataType = {
  assignedSubscription: string
  address: string
  allSessions: SessionType[]
  birth: string
  created: string
  lastUpdated: string
  emailAddress: string
  firstName: string
  lastName: string
  phoneNumber: string
  postalCode: string
  id: number
  sessions: SessionType[]
  performedSessions: number | undefined
  subscriptions: Subscription[]
  validSubscription: boolean
  customerId: string
  usedFreeTrial: boolean
  subscriptionCancelAt: null | number
  newsCount: number
  gender: string
  stripeId: string
  notifications: boolean
  workoutImages: { [key: string]: string }
}

const apiResponsFormatter = (data: any): UserDataType => {
  const workoutImages = (
    (data.session_types as SessionTypeType[]) || []
  ).reduce((results, item) => {
    if (item.image__dn) {
      results[item.name] = item.image__dn
    }
    const uniqueName = item.name + item.level.toString()
    results[uniqueName] = item.image__dn
    return results
  }, {} as { [key: string]: string })

  return {
    stripeId: data.stripe_id || '',
    subscriptionCancelAt: data.subscription_cancel_at || null,
    usedFreeTrial: data.used_free_trial,
    assignedSubscription: data.active_subscription || '',
    address: data.address || '',
    birth: data.birth || '',
    created: data.created_at || '',
    lastUpdated: data.last_updated || '',
    emailAddress: data.email_address || '',
    firstName: data.first_name || '',
    lastName: data.last_name || '',
    phoneNumber: data.phone_number || '',
    postalCode: data.postal_code || '',
    id: data.id,
    sessions: (data.sessions || []).map((item: any) => ({
      id: item.id,
      day: item.day,
      time: item.time,
      sessionType:
        (data?.session_types || []).find(
          (it: any) => it.id === item.session_type_id,
        ) || null,
    })),
    allSessions: (data.all_sessions || []).map((item: any) => ({
      id: item.id,
      day: item.day,
      time: item.time,
      sessionType:
        (data?.session_types || []).find(
          (it: any) => it.id === item.session_type_id,
        ) || null,
    })),
    performedSessions: data.performed_sessions,
    subscriptions: data.subscriptions || [],
    validSubscription: data.valid_subscription || false,
    customerId: data.customer_id || '',
    newsCount: data.news_count || 0,
    gender: data.gender || '',
    notifications: !!data.notifications,
    workoutImages: workoutImages,
  }
}

export default apiResponsFormatter
