import { useContext } from 'react'
import { Button, Grow } from '@mui/material'
import {
  buttonMainStyle,
  colors,
  smallTextStyles,
} from '../../../themes/generalStyles'
import UserContext from '../../../Contexts/UserContext'
import WorkoutContext from '../../../Contexts/WorkoutContext'
import { sortBy } from 'lodash'

const DaySelect = ({ day }: { day: string }) => {
  const { assignedSessions } = useContext(UserContext)
  const { selectedWorkoutInfo, setSelectedWorkoutInfo } =
    useContext(WorkoutContext)

  const innerBox = {
    backgroundColor: colors.main,
    borderRadius: 10,
    border: `2px solid ${colors.darkgrey}`,
    boxShadow: '0px 0px 10px 3px #C4C4C4',
    ...smallTextStyles,
  }
  const parentBoxStyle = {
    minWidth: 125,
    width: 205,
    paddingTop: 0,
    display: 'grid',
    gap: 10,
    aspectRatio: 0.7,
  }
  const assignedSessionForThisDay = assignedSessions[day.toLowerCase()] || null
  return (
    <Grow in={true} timeout={1000} unmountOnExit unselectable={'on'}>
      <div style={parentBoxStyle}>
        {sortBy(assignedSessionForThisDay, ['time']).map((item, key) => (
          <Button
            key={key}
            onClick={() => {
              setSelectedWorkoutInfo(item)
            }}
            sx={{
              ...buttonMainStyle,
              transition: '0.5s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            style={{
              ...innerBox,
              alignItems: 'flex-start',
              ...(selectedWorkoutInfo === item
                ? { border: '4px solid black' }
                : {}),
            }}>
            <div>
              <div>
                <h2 style={{ margin: 0, color: 'white' }}>
                  {item.sessionType?.name}
                </h2>
              </div>
              <h4 style={{ color: colors.darkgrey, margin: 0 }}>
                {item.sessionType?.level !== 0 && (
                  <span style={{ marginRight: 10 }}>
                    Nivå {item.sessionType?.level}
                  </span>
                )}
                Kl {item.time}
              </h4>
            </div>
          </Button>
        ))}
      </div>
    </Grow>
  )
}

export default DaySelect
