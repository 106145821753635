import { Button, CircularProgress, Grid } from '@mui/material'
import ModalWrapper from './ModalWrapper'
import SubscriptionCard from '../Utils/SubscriptionCard'
import { useContext, useEffect, useState } from 'react'
import useApi from '../hooks/useApi'
import { getSubscriptions } from '../api/fetch'
import { Subscription } from '../helpers/apiResponsFormatter'
import { isEmpty, orderBy } from 'lodash'
import userContext from '../Contexts/UserContext'
import modalContext from '../Contexts/ModalContext'
import { buttonSuccessStyle } from '../themes/generalStyles'
import useRouteToStripe from '../hooks/useRouteToStripe'
import settings from '../config'

const BottomSubscriptionConditions = () => {
  return (
    <div>
      <ul>
        <li>Alle medlemsskapene gir full tilgang</li>
        <li style={{ marginTop: 20 }}>
          Det er ingen bindingstid til tjenesten
        </li>
        <li style={{ marginTop: 20 }}>
          Spar penger ved å betale for flere måneder
        </li>
      </ul>
    </div>
  )
}

const Subscriptions = () => {
  const { userData } = useContext(userContext)
  const { openModal } = useContext(modalContext)
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const { doFetch, isLoading } = useApi(getSubscriptions)
  const [newCustomerView, setNewCustomerView] = useState('')
  const [rubberBandId, setRubberBandId] = useState('')
  const [coupon, setCoupon] = useState('')
  const stripeAPI = useRouteToStripe()
  const error = stripeAPI.isError
  useEffect(() => {
    if (error) {
      setCoupon('')
      setNewCustomerView('')
    }
  }, [error])

  useEffect(() => {
    if (subscriptions.length === 0 && openModal === 'subscriptions') {
      doFetch({
        data: {},
        onSuccess: data => setSubscriptions(data.subscriptions),
      })
    }
    setNewCustomerView('')
  }, [openModal])
  const subscriptionToView = subscriptions.filter(
    item => !item.name.includes('treningsstrikker'),
  )
  const { subToView, rubberBandOffer } = subscriptions.reduce(
    (result, sub) => {
      if (sub.name.includes('treningsstrikker')) {
        result.rubberBandOffer = sub
      } else {
        result.subToView.push(sub)
      }
      return result
    },
    { subToView: [], rubberBandOffer: null } as {
      subToView: Subscription[]
      rubberBandOffer: null | Subscription
    },
  )
  const notUsedFreeTrial = !!(userData && !userData.usedFreeTrial)
  const orderedSubscriptions = orderBy(subToView, 'price', ['desc'])
  const first = orderedSubscriptions[0] || null
  return (
    <ModalWrapper
      header={newCustomerView ? 'Ny kunde' : 'Medlemskap og priser'}
      openModalKey='subscriptions'>
      <>
        {error && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'red',
            }}>
            Ugyldig kuppong
          </div>
        )}
        <Grid container sx={{ maxWidth: 800 }}>
          {newCustomerView ? (
            <div style={{ fontSize: 20 }}>
              <div>
                Som ny kunde betales kun en innmeldingsavgift på 89 kr. Dette
                beløpet inkluderer en 30 dagers prøveperiode og to
                treningsstrikker som er skreddersydd og tilpasset våre timer.
              </div>
              <Grid item>
                <Grid container justifyContent='center'>
                  <img
                    style={{ width: 300 }}
                    src={`${settings.bucket}/root_images/signupImage.png`}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent='center' sx={{ marginTop: 2 }}>
                <Button
                  sx={{ ...buttonSuccessStyle, fontSize: 20 }}
                  onClick={() =>
                    stripeAPI.buttonOnClick(
                      false,
                      newCustomerView,
                      rubberBandId,
                      coupon,
                    )
                  }>
                  Aktiver medlemskap
                </Button>
              </Grid>
            </div>
          ) : (
            <>
              <Grid container gap={2} justifyContent='center'>
                {!isEmpty(subToView) ? (
                  orderedSubscriptions.map((item, key) => (
                    <Grid key={key} xs={12} item>
                      <SubscriptionCard
                        coupon={undefined}
                        setCoupon={setCoupon}
                        apis={stripeAPI}
                        setNewCustomerView={setNewCustomerView}
                        notUsedFreeTrial={notUsedFreeTrial}
                        bindingPeriod={Number(item.binding_period)}
                        id={item.id}
                        name={item.name}
                        cost={item.price}
                        rubberBandId={rubberBandId}
                      />
                    </Grid>
                  ))
                ) : (
                  <CircularProgress size={50} />
                )}
                {!isEmpty(subToView) && first?.id && (
                  <Grid xs={12} item>
                    <SubscriptionCard
                      coupon={coupon}
                      setCoupon={setCoupon}
                      apis={stripeAPI}
                      setNewCustomerView={setNewCustomerView}
                      notUsedFreeTrial={notUsedFreeTrial}
                      bindingPeriod={0}
                      id={first.id || ''}
                      name=''
                      cost={0}
                      rubberBandId={rubberBandId}
                    />
                  </Grid>
                )}
              </Grid>
              <BottomSubscriptionConditions />
            </>
          )}
        </Grid>
      </>
    </ModalWrapper>
  )
}

export default Subscriptions
