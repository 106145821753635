import MainCard from './MainCard'
import { buttonSuccessStyle, colors } from '../../themes/generalStyles'
import { Button, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { CSSProperties, useContext } from 'react'
import modalContext from '../../Contexts/ModalContext'
import userContext from '../../Contexts/UserContext'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import AddSessionToCalendarButton from './AddSessionToCalendarButton'

const TopLogoCard = ({
  content,
  sx = {},
  button = false,
  backToButton = '',
}: {
  content: string
  sx?: CSSProperties
  button?: boolean
  backToButton?: string
}) => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  const logo = require('../../Images/isteadyLogoStripped.png')
  const { setOpenModal } = useContext(modalContext)
  const onButtonClick = () => {
    setOpenModal('addNewWorkout')
  }
  return (
    <MainCard
      noPadding
      sx={{ marginBottom: 0, padding: 2, ...sx }}
      style={{ boxShadow: colors.shadow }}>
      <>
        <span
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <span
            style={{
              fontSize: '3.5rem',
              fontWeight: 90,
              color: colors.main,
              textAlign: 'center',
              width: '100%',
            }}>
            <img
              alt='iSteady logo image'
              src={logo}
              style={{
                width: 'auto',
                height: '70px',
                transform: 'translate(0px, 15px)',
                padding: 0,
                marginBottom: 5,
              }}
            />
            {content}
            {smallScreenWidth && <br />}
            {button && (
              <AddSessionToCalendarButton onButtonClick={onButtonClick} />
            )}
          </span>
        </span>
      </>
    </MainCard>
  )
}

export default TopLogoCard
