import { Button, Grid, Grow } from '@mui/material'
import {
  buttonErrorStyle,
  buttonWarningStyle,
  font,
} from '../../../themes/generalStyles'
import { SessionType } from '../../../helpers/apiResponsFormatter'
import { useContext, useState } from 'react'
import workoutContext from '../../../Contexts/WorkoutContext'
import { postJoinOrLeaveWorkout } from '../../../api/fetch'
import userContext from '../../../Contexts/UserContext'
import ModalContext from '../../../Contexts/ModalContext'
import useApi from '../../../hooks/useApi'
import JoinWorkoutButton from './JoinWorkoutButton'
import MainCardCustom from '../../../Utils/MainCardCustom'
import settings from '../../../config'
import Center from '../../../Utils/Center'
import screenScaleContext from '../../../Contexts/ScreenScaleContext'
import RemoveWorkoutModal from '../../../Modals/RemoveWorkoutModal'

const AutoSelectedInfo = ({
  selectedWorkoutInfo,
}: {
  selectedWorkoutInfo: SessionType
}) => {
  const { userLogin, setUserData, userData } = useContext(userContext)
  const { setOpenModal } = useContext(ModalContext)
  const { screenWidth } = useContext(screenScaleContext)
  const smallWidthScreen = screenWidth < 700
  const { setSelectedWorkoutInfo, setApiModal } = useContext(workoutContext)
  const { doFetch } = useApi(postJoinOrLeaveWorkout)
  const [removeSessionModalOpen, setRemoveSessionModalOpen] = useState(false)

  const imagePath =
    userData &&
    userData.workoutImages &&
    selectedWorkoutInfo.sessionType?.name &&
    selectedWorkoutInfo.sessionType?.level.toString() &&
    userData.workoutImages[
      selectedWorkoutInfo.sessionType.name +
        selectedWorkoutInfo.sessionType?.level.toString()
    ]
      ? `${settings.bucket}/images/${
          userData.workoutImages[
            selectedWorkoutInfo.sessionType.name +
              selectedWorkoutInfo.sessionType?.level.toString()
          ]
        }`
      : require('../../../Images/isteadyLogo.png')

  const exitWorkout = () => {
    userLogin &&
      selectedWorkoutInfo &&
      doFetch({
        data: {
          action: 'delete',
          session_type: selectedWorkoutInfo.id,
        },
        onSuccess: () => {
          setUserData(prevState =>
            prevState
              ? {
                  ...prevState,
                  sessions: prevState.sessions.filter(
                    item => item.id !== selectedWorkoutInfo.id,
                  ),
                }
              : null,
          )
          setApiModal({
            key: 'removedSession',
            header: 'Du er nå avmeldt denne gruppetimen',
            day: selectedWorkoutInfo.day,
            level: String(selectedWorkoutInfo.sessionType?.level),
            workoutName: selectedWorkoutInfo.sessionType?.name || '',
            time: selectedWorkoutInfo.time,
          })
          setOpenModal('')
          setSelectedWorkoutInfo(null)
        },
      })
  }

  return (
    <>
      <RemoveWorkoutModal
        open={removeSessionModalOpen}
        setOpen={setRemoveSessionModalOpen}
        exitWorkout={exitWorkout}
      />
      <Grow in={true} timeout={1000}>
        <div style={{ ...font, padding: 20 }}>
          <Center>
            <h1
              style={{
                marginBottom: 20,
                marginTop: 0,
              }}>
              {selectedWorkoutInfo.sessionType?.name}
            </h1>
          </Center>
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={5}
            justifyContent='center'>
            {!smallWidthScreen && (
              <div>
                <img
                  alt='image'
                  src={imagePath}
                  style={{
                    border: '2px solid black',
                    borderRadius: 10,
                    padding: 0,
                    width: 230,
                    height: 230,
                    cursor: 'pointer',
                  }}
                />
              </div>
            )}
            <div style={font}>
              <Grid
                sx={{ width: 140, height: 250 }}
                container
                direction='column'
                gap={3}
                justifyContent='center'>
                <MainCardCustom>
                  <div style={{ fontSize: 25 }}>
                    <strong>
                      {selectedWorkoutInfo.day
                        ? selectedWorkoutInfo.day[0].toUpperCase() +
                          selectedWorkoutInfo.day.slice(1)
                        : ''}
                    </strong>
                  </div>
                </MainCardCustom>

                <MainCardCustom>
                  <div style={{ fontSize: 25 }}>
                    <strong>{selectedWorkoutInfo.time}</strong>
                  </div>
                </MainCardCustom>
                {selectedWorkoutInfo.sessionType?.level !== 0 && (
                  <MainCardCustom>
                    <div style={{ fontSize: 25 }}>
                      <strong>
                        Nivå {selectedWorkoutInfo.sessionType?.level}
                      </strong>
                    </div>
                  </MainCardCustom>
                )}
              </Grid>
            </div>
            <div>
              <Grid container direction='column' gap={3}>
                <div style={{ ...font, marginTop: 0 }}>
                  <JoinWorkoutButton />
                </div>
                <div>
                  <Grid container direction='row'>
                    <div style={{ width: 100 }}>
                      <Button
                        sx={buttonWarningStyle}
                        onClick={() => setOpenModal('sessionInfo')}
                        style={{
                          width: '100%',
                          fontSize: 15,
                          fontWeight: 'bold',
                          borderRadius: 10,
                          color: 'white',
                        }}>
                        Om timen
                      </Button>
                    </div>
                    <div style={{ marginLeft: 10, width: 120 }}>
                      <Button
                        sx={buttonErrorStyle}
                        onClick={() => setRemoveSessionModalOpen(true)}
                        style={{
                          width: 130,
                          fontSize: 15,
                          fontWeight: 'bold',
                          borderRadius: 10,
                          color: 'white',
                        }}>
                        Meld deg av
                      </Button>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </Grow>
    </>
  )
}

export default AutoSelectedInfo
