const parseEpochToDate = (epoch: number | null): string => {
  if (!epoch) {
    return ''
  }
  const date = new Date(epoch * 1000)
  const parsedDate = date.toLocaleDateString('no-NO')
  return parsedDate
    .split('.')
    .map(item => (item.length === 1 ? `0${item}` : item))
    .join('.')
}

export default parseEpochToDate
