import { useEffect, useState } from 'react'
import { UserContextType } from '../Contexts/UserContext'
import { UserDataType } from '../helpers/apiResponsFormatter'
import useTime from './useTime'

const useLoggedIn = (
  userData: UserDataType | null,
  nowTime: number,
  tokenInvalidatesAfter: number,
  userLogin: UserContextType['userLogin'],
): { loggedIn: boolean } => {
  const [loggedIn, setLoggedIn] = useState(false)

  // updating loggedIn State
  useEffect(() => {
    setLoggedIn(
      Boolean(
        userLogin &&
          userData &&
          nowTime - userLogin.created < tokenInvalidatesAfter &&
          userLogin.token,
      ),
    )
  }, [userLogin, tokenInvalidatesAfter, userData])

  return { loggedIn }
}

export default useLoggedIn
