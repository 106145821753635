import { Grid } from '@mui/material'
import WhatIsCard from './Cards/WhatIsCard'
import FreePeriodCard from './Cards/FreePeriodCard'
import CustomCard from './Cards/CustomCard'
import { standardPadding } from '../../themes/generalStyles'
import { useContext } from 'react'
import screenScaleContext from '../../Contexts/ScreenScaleContext'

const UnLoggedPage = () => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <>
      <Grid
        container
        sx={{
          marginTop: 1,
          ...(smallScreenWidth
            ? {}
            : { marginLeft: standardPadding, marginRight: standardPadding }),
        }}>
        <Grid container gap={2}>
          <WhatIsCard />
          <FreePeriodCard />
        </Grid>
        <div style={{ marginTop: 20, width: '100%' }}>
          <CustomCard />
        </div>
      </Grid>
    </>
  )
}

export default UnLoggedPage
