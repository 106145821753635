import {
  buttonMainStyle,
  standardPadding,
  standardWidthLarger,
} from '../../themes/generalStyles'
import { Button, CardHeader, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import MainCard from '../MainPage/MainCard'
import settings from '../../config'
import Link from '../../Utils/Link'
import RouteBackText from '../News/RouteBackText'

export type NewsType = {
  title: string
  info: string
  created: string
  image__dn: string
}

const NoContent = () => {
  return (
    <MainCard sx={{ width: standardWidthLarger, marginBottom: 10 }}>
      <>
        <Grid
          container
          justifyContent='center'
          sx={{
            paddingLeft: standardPadding,
            paddingRight: standardPadding,
          }}>
          <img
            style={{
              borderRadius: 10,
              width: '90%',
              marginBottom: 40,
            }}
            src={`${settings.bucket}/root_images/noContent.jpg`}
          />
          <div style={{ position: 'absolute', top: 400 }}>
            <RouteBackText />
          </div>
        </Grid>
      </>
    </MainCard>
  )
}

export default NoContent
