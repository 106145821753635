import { colors } from '../../../themes/generalStyles'
import { Grid } from '@mui/material'
import settings from '../../../config'
import MainCard from '../MainCard'
import Link from '../../../Utils/Link'

const FreePeriodCard = () => {
  return (
    <Link to='ny-bruker'>
      <Grid container>
        <MainCard
          sx={{
            width: '100%',
            cursor: 'pointer',
            transition: '0.2s',
            '&:hover': {
              transform: 'scale(1.01)',
            },
          }}>
          <Grid container justifyContent='center'>
            <h2 style={{ textAlign: 'center', width: '100%', maxWidth: 720 }}>
              Ta med deg partner og venner og tren sammen i stua uten ekstra
              kostnader
            </h2>
            <img
              alt='iSteady hands out image'
              src={`${settings.bucket}/root_images/bungie.png`}
              style={{
                width: '90%',
                maxWidth: 700,
                height: 'auto',
                boxShadow: colors.shadow,
                borderRadius: 5,
              }}
            />
          </Grid>
        </MainCard>
      </Grid>
    </Link>
  )
}

export default FreePeriodCard
