import { Grid, Step, StepConnector, StepLabel, Stepper } from '@mui/material'
import { Dispatch, SetStateAction, useContext } from 'react'
import requiredKeysForPage from '../../helpers/requiredKeysForPage'
import createUserContext from '../../Contexts/CreateUserContext'
import screenScaleContext from '../../Contexts/ScreenScaleContext'

const Progress = ({
  page,
  setPage,
}: {
  page: number
  setPage: Dispatch<SetStateAction<number>>
}) => {
  const { formData } = useContext(createUserContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const labels = ['Personalia', 'Adresse', 'Brukervilkår', 'Telefonnummer']
  return smallScreenWidth ? (
    <Grid container justifyContent='center' sx={{ width: '100%' }}>
      <Stepper
        sx={{ width: '100%' }}
        orientation='horizontal'
        activeStep={page}
        connector={<StepConnector />}>
        {labels.map(
          (item, key) =>
            (key === page || key === page + 1) && (
              <Step
                key={key}
                onClick={() => {
                  ;(key !== 0 || requiredKeysForPage(key - 1, formData)) &&
                    setPage(key)
                }}>
                <StepLabel>{item}</StepLabel>
              </Step>
            ),
        )}
      </Stepper>
    </Grid>
  ) : (
    <Grid container justifyContent='center' sx={{ width: '100%' }}>
      <Stepper
        sx={{ width: '100%' }}
        orientation='horizontal'
        activeStep={page}
        connector={<StepConnector />}>
        {labels.map((item, key) => (
          <Step
            key={key}
            onClick={() => {
              ;(key !== 0 || requiredKeysForPage(key - 1, formData)) &&
                setPage(key)
            }}>
            <StepLabel>{item}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  )
}

export default Progress
