import { Button, CircularProgress, Grid } from '@mui/material'
import CartRow from './CartRow'
import { buttonSuccessStyle } from '../../themes/generalStyles'
import { Dispatch, SetStateAction, useContext } from 'react'
import { isEmpty } from 'lodash'
import { CartType } from './WebShop'
import useApi from '../../hooks/useApi'
import { createShopPortal } from '../../api/fetch'
import userContext from '../../Contexts/UserContext'

const ShoppingCart = ({
  cart,
  setCart,
}: {
  cart: CartType[]
  setCart: Dispatch<SetStateAction<CartType[]>>
}) => {
  const { userData } = useContext(userContext)
  const { doFetch, isLoading } = useApi(createShopPortal)
  const goToPortal = () => {
    if (userData) {
      doFetch({
        data: {
          stripe_id: userData.stripeId,
          items: cart.map(item => ({
            price_id: item.priceId,
            quantity: item.qty,
            name: item.name,
            price: item.price,
            id: item.id,
          })),
        },
        onSuccess: data => {
          location.href = data.url
        },
      })
    }
  }
  return (
    <>
      <Grid
        direction='column'
        container
        justifyContent='flex-start'
        alignItems='center'
        sx={{
          border: '1px dashed black',
          borderRadius: 2,
          height: 'calc(100% - 45px)',
          overflowY: 'auto',
        }}>
        <h1>Handlevogn</h1>
        {isEmpty(cart) ? (
          <div>Ingen artikler valgt</div>
        ) : (
          cart.map((item, key) => (
            <CartRow key={key} cart={item} setCart={setCart} />
          ))
        )}
      </Grid>
      <Grid direction='column' container justifyContent='flex-end'>
        <Button
          onClick={goToPortal}
          sx={{ ...buttonSuccessStyle, fontSize: 20, height: '45px' }}>
          {isLoading ? <CircularProgress size={35} /> : 'Til betaling'}
        </Button>
      </Grid>
    </>
  )
}

export default ShoppingCart
