import MainCard from '../MainCard'
import { Button, CircularProgress, Grid } from '@mui/material'
import LogoImage from '../../../TopNav/LogoImage'
import {
  buttonMainStyle,
  buttonSuccessStyle,
  font,
} from '../../../themes/generalStyles'
import { useContext, useEffect } from 'react'
import ModalContext from '../../../Contexts/ModalContext'
import useApi from '../../../hooks/useApi'
import { fetchFrontNews } from '../../../api/fetch'
import screenScaleContext from '../../../Contexts/ScreenScaleContext'
import Center from '../../../Utils/Center'

const CustomCard = () => {
  const { setOpenModal } = useContext(ModalContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const { data, isLoading, doFetch } = useApi(fetchFrontNews)
  useEffect(() => {
    doFetch({})
  }, [])
  const buttonInfo = data?.button || {}
  const buttonText = buttonInfo?.text || ''
  return (
    <Grid container>
      <MainCard noPadding sx={{ width: '100%' }}>
        <Grid
          container
          sx={{ width: '100%' }}
          direction='row'
          justifyContent='center'
          alignItems='center'>
          <Grid item xs={smallScreenWidth ? 12 : 3}>
            <Center>
              <div style={{ padding: 20 }}>
                <LogoImage size={100} />
              </div>
            </Center>
          </Grid>
          <Grid
            item
            xs={smallScreenWidth ? 12 : 6}
            style={{ backgroundColor: '#C4C4C421', width: '100%' }}>
            <Grid
              container
              direction='column'
              alignItems='center'
              style={{
                ...font,
                padding: 20,
              }}>
              <h1 style={{ marginTop: 0, textAlign: 'center' }}>
                {isLoading ? 'Laster... ' : data?.header}
              </h1>
              <div style={{ fontSize: 20, minHeight: 100 }}>
                {isLoading ? <CircularProgress /> : <div>{data?.content}</div>}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={smallScreenWidth ? 12 : 3}>
            <Grid
              container
              direction='column'
              style={{ padding: 20 }}
              alignItems='center'
              justifyContent='center'
              gap={5}>
              <strong style={{ fontSize: 20, textAlign: 'center' }}>
                {buttonInfo?.header}
              </strong>
              <div>
                <Button
                  sx={
                    buttonInfo?.variant === 'success'
                      ? buttonSuccessStyle
                      : buttonMainStyle
                  }
                  onClick={() => {
                    if (buttonText === 'Kontakt oss') {
                      setOpenModal('contactUs')
                    } else {
                      location.href = location.href = buttonInfo?.link
                        ? buttonInfo.link
                        : '/'
                    }
                  }}
                  size='small'>
                  {buttonInfo?.text}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  )
}

export default CustomCard
