import ModalWrapper from './ModalWrapper'
import { Button, CircularProgress, Grid, TextField } from '@mui/material'
import useApi from '../hooks/useApi'
import { postInitiateSmsAuth, updateDeleteUser } from '../api/fetch'
import { useContext, useState } from 'react'
import userContext from '../Contexts/UserContext'
import modalContext from '../Contexts/ModalContext'
import { useNavigate } from 'react-router-dom'

const SureToDeleteModal = () => {
  const navigate = useNavigate()
  const { userLogin, setUserLogin } = useContext(userContext)
  const { setOpenModal } = useContext(modalContext)
  const [isError, setIsError] = useState(false)
  const { doFetch, isLoading, data } = useApi(updateDeleteUser)
  const style = {
    height: 400,
    width: 600,
  }
  const deleteUser = () => {
    if (userLogin) {
      doFetch({
        data: {
          action: 'delete',
          phone_number: userLogin.phoneNumber,
        },
        onSuccess: () => {
          setOpenModal('')
          setUserLogin(prevState => null)
          navigate('/')
        },
        onError: () => {
          setIsError(true)
        },
      })
    }
  }
  return (
    <ModalWrapper
      header='Slett bruker hos iSteady'
      openModalKey='deleteUser'
      styles={style}>
      <div>
        <div>
          Du er nå i ferd med å permanent slette bruker hos oss i iSteady. Dette
          er en irreversibel process og du vil ikke kunne få igjen dataen etter
          at denne brukeren er slettet. Det er likevel alltid mulig å opprette
          en ny konto hos oss ved en senere anledning.
        </div>
        {isError && (
          <div style={{ color: 'red', margin: 20 }}>
            Her var det noe som gikk galt. Vennligst kontakt isteady teamet ved
            å trykke{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setOpenModal('contactUs')}>
              her
            </span>
          </div>
        )}
        <Grid
          container
          direction='row'
          justifyContent='center'
          style={{ marginTop: 20 }}>
          <Button
            style={{
              backgroundColor: 'red',
              color: 'black',
              border: '2px solid black',
            }}
            onClick={() => deleteUser()}>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              'Permanent Slett brukeren min'
            )}
          </Button>
        </Grid>
      </div>
    </ModalWrapper>
  )
}

export default SureToDeleteModal
