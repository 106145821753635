import { Button } from '@mui/material'
import { CSSProperties, useContext } from 'react'
import ModalContext from '../Contexts/ModalContext'
import { buttonMainStyle, buttonSuccessStyle } from '../themes/generalStyles'

type CustomButtonProps = {
  textBody: string
  iconPrefix?: JSX.Element | null
  modalOpen: string
  clickFunction?: boolean
  successStyle?: boolean
  sx?: CSSProperties
}

const CustomButton = ({
  textBody,
  iconPrefix = null,
  modalOpen,
  clickFunction = false,
  successStyle = false,
  sx = {},
}: CustomButtonProps): JSX.Element => {
  const { setOpenModal } = useContext(ModalContext)
  return (
    <Button
      sx={{
        ...(successStyle ? buttonSuccessStyle : buttonMainStyle),
        height: 40,
        ...sx,
      }}
      size='small'
      onClick={() => (clickFunction ? setOpenModal(modalOpen) : null)}>
      {iconPrefix} {textBody}
    </Button>
  )
}

export default CustomButton
