import { Dispatch, SetStateAction, useContext } from 'react'
import {
  buttonMainStyle,
  centerStyle,
  colors,
  font,
} from '../themes/generalStyles'
import { ApiModalType } from '../Contexts/WorkoutContext'
import { Button, Grid, Modal } from '@mui/material'
import HeaderLine from '../Utils/HeaderLine'
import MainCardCustom from '../Utils/MainCardCustom'
import userContext from '../Contexts/UserContext'
import settings from '../config'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const ActionResponse = ({
  apiModal,
  setApiModal,
}: {
  apiModal: ApiModalType
  setApiModal: Dispatch<SetStateAction<ApiModalType>>
}) => {
  const { userData } = useContext(userContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const style = {
    backgroundColor: colors.lightGray,
    borderRadius: 5,
    padding: 40,
    ...font,
  }
  const imagePath =
    userData &&
    userData.workoutImages &&
    apiModal &&
    apiModal.workoutName &&
    userData.workoutImages[apiModal.workoutName || '']
      ? `${settings.bucket}/images/${
          userData.workoutImages[apiModal.workoutName]
        }`
      : require('../Images/isteadyLogo.png')

  return (
    apiModal && (
      <Modal
        open={Boolean(apiModal)}
        onClose={() => setApiModal(null)}
        style={{ ...centerStyle }}>
        <div style={style}>
          <>
            <h2
              style={{
                marginTop: 0,
                textAlign: 'center',
              }}>
              {apiModal.header}
            </h2>
            <HeaderLine />
            <Grid container style={{ fontSize: 18, marginTop: 20 }}>
              <Grid container justifyContent='space-around' gap={1}>
                {!smallScreenWidth && (
                  <Grid item>
                    <img
                      style={{
                        border: '1px solid black',
                        borderRadius: 10,
                        width: 250,
                        height: 'auto',
                      }}
                      src={imagePath}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    alignItems='center'
                    gap={1}>
                    <h2
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 0,
                      }}>
                      {apiModal.workoutName}
                    </h2>
                    <MainCardCustom>{apiModal.day}</MainCardCustom>
                    <MainCardCustom>{apiModal.time}</MainCardCustom>
                    {apiModal.level !== '0' && (
                      <MainCardCustom>Nivå {apiModal.level}</MainCardCustom>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-end'>
              <div style={{ position: 'relative', right: 0 }}>
                <Button
                  sx={buttonMainStyle}
                  onClick={() => setApiModal(null)}
                  style={{ color: 'white', backgroundColor: '#1DC3CF' }}>
                  Lukk
                </Button>
              </div>
            </Grid>
          </>
        </div>
      </Modal>
    )
  )
}

export default ActionResponse
