import sessionNames from './constants'

const env = process.env

const settings = {
  backendUrl: env.REACT_APP_BACKEND_URL,
  frontendUrl: env.REACT_APP_FRONTEND_URL,
  bucket: env.REACT_APP_BUCKET,
  sessionNames: [
    sessionNames['strongShoulders'],
    sessionNames['strongKnees'],
    sessionNames['pulse'],
  ],
}

export default settings
