import { centerStyle, colors, font } from '../themes/generalStyles'
import HeaderLine from '../Utils/HeaderLine'
import { Grid, Modal } from '@mui/material'
import { WhereByType } from '../hooks/useWhereby'
import { Close } from '@mui/icons-material'
import SelectCamera from './Utils/SelectCamera'
import SelectMic from './Utils/SelectMic'
import { useContext } from 'react'
import VideoContext from '../Contexts/VideoContext'
import ReloadButton from '../Utils/ReloadButton'

const VideoSettingModal = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: WhereByType['setSettings']
}) => {
  const { localMedia } = useContext(VideoContext)
  const { actions, state } = localMedia
  const { setCameraDevice, setMicrophoneDevice } = actions
  const {
    cameraDeviceError,
    cameraDevices,
    microphoneDevices,
    currentCameraDeviceId,
    currentMicrophoneDeviceId,
  } = state
  return (
    <Modal
      sx={font}
      disableAutoFocus={true}
      open={open}
      onClose={() => setOpen(false)}
      style={centerStyle}>
      <div
        style={{
          backgroundColor: colors.secondGray,
          borderRadius: 5,
          width: 300,
          height: 550,
        }}>
        <Close
          style={{
            position: 'relative',
            zIndex: 999,
            marginTop: 5,
            marginRight: 20,
            float: 'inline-end',
            fontSize: 40,
            cursor: 'pointer',
            borderRadius: 20,
          }}
          sx={{
            backgroundColor: '#e4e3e4',
            '&:hover': { backgroundColor: 'grey' },
          }}
          onClick={() => setOpen(false)}
        />

        <div style={{ transform: 'translate(0px, -30px)' }}>
          <Grid container justifyContent='center'>
            <h1
              style={{
                paddingLeft: 30,
                margin: 0,
                textAlign: 'center',
                marginRight: 70,
              }}>
              Video innstillinger
            </h1>
          </Grid>
          <HeaderLine />
        </div>
        <Grid
          container
          sx={{ padding: 2, marginTop: -4 }}
          justifyContent='space-around'>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <h4>Velg Kamera</h4>
              <SelectCamera
                selectedCamera={currentCameraDeviceId}
                mediaDevices={cameraDevices}
                setNewCamera={setCameraDevice}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <h4>Velg Mikrofon & Lyd</h4>
              <SelectMic
                selectedMic={currentMicrophoneDeviceId}
                mediaDevices={microphoneDevices}
                setNewMic={setMicrophoneDevice}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <h4>Har du problemer, last inn siden på nytt.</h4>
              <ReloadButton />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default VideoSettingModal
