import { CSSProperties } from 'react'
import settings from '../config'
import ReactPlayer from 'react-player'

const LoadVideo = ({
  video,
  style,
  thumb,
}: {
  video: string
  thumb: string
  style: CSSProperties
}) => {
  return (
    <ReactPlayer
      width='100%'
      height='auto'
      max-height='100px'
      id={`unique-id-${video}`}
      stopOnUnmount
      style={{ ...style, cursor: 'pointer' }}
      playing={true}
      controls={true}
      light={
        <img
          alt={`thumb-${thumb}`}
          src={`${settings.bucket}/videos/${thumb}`}
          style={{ ...style, cursor: 'pointer' }}
        />
      }
      playsinline
      url={`${settings.bucket}/videos/${video}`}
    />
  )
}

export default LoadVideo
