import { Button, Grid } from '@mui/material'
import Link from '../../Utils/Link'
import { buttonMainStyle, colors } from '../../themes/generalStyles'
import HeaderLine from '../../Utils/HeaderLine'

const RouteBackText = () => {
  return (
    <Grid
      container
      alignItems='center'
      direction='column'
      gap={2}
      sx={{
        backgroundColor: colors.lightGray,
        borderRadius: 5,
        opacity: 0.95,
      }}>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ marginBottom: 0 }}>Siden finnes ikke</h1>
        <HeaderLine />
        <div>
          Siden du leter etter er enten flyttet eller eksisterer ikke lenger.
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <Link to='/'>
          <Button sx={buttonMainStyle}>Gå til forside</Button>
        </Link>
      </div>
    </Grid>
  )
}

export default RouteBackText
