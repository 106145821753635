import {
  buttonDisabledStyle,
  buttonSuccessStyle,
} from '../../themes/generalStyles'
import { Button } from '@mui/material'
import createUserContext, {
  responseType,
} from '../../Contexts/CreateUserContext'
import { Dispatch, SetStateAction, useContext } from 'react'

const NextButton = ({
  disableNext,
  page,
  smsResponse,
  setPage,
}: {
  disableNext: boolean
  page: number
  smsResponse: responseType
  setPage: Dispatch<SetStateAction<number>>
}) => {
  const { createUser, getSmsCode } = useContext(createUserContext)
  return (
    <Button
      disabled={disableNext}
      size='large'
      sx={{
        ...(disableNext ? buttonDisabledStyle : buttonSuccessStyle),
        width: 100,
        marginLeft: 20,
      }}
      onClick={() => {
        if (page === 2 && smsResponse === 'idle') {
          getSmsCode()
          setPage(3)
        } else if (page === 3) {
          createUser()
        } else if (page !== 3) {
          setPage(prevState => prevState + 1)
        }
      }}>
      {page === 3 ? 'Fullfør' : 'Neste'}
    </Button>
  )
}

export default NextButton
