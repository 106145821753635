import ModalWrapper from './ModalWrapper'
import MainCard from '../Views/MainPage/MainCard'
import { buttonDisabledStyle, buttonErrorStyle } from '../themes/generalStyles'
import { Button } from '@mui/material'
import { useContext } from 'react'
import modalContext from '../Contexts/ModalContext'
import userContext from '../Contexts/UserContext'

const LogOut = () => {
  const { setUserLogin } = useContext(userContext)
  const { setOpenModal } = useContext(modalContext)
  return (
    <ModalWrapper
      header='Logg ut'
      openModalKey='logout'
      styles={{ width: 400, overflow: 'hidden' }}>
      <>
        <MainCard>
          <div style={{ fontSize: 20 }}>
            Du trenger ikke logge ut av enheter som du normalt bruker til
            isteady.no
          </div>
        </MainCard>
        <div
          style={{
            float: 'right',
            marginTop: 20,
          }}>
          <Button sx={buttonDisabledStyle} onClick={() => setOpenModal('')}>
            Avbryt
          </Button>
          <Button
            sx={{ ...buttonErrorStyle, marginLeft: 2 }}
            onClick={() => {
              setUserLogin(prevState =>
                prevState ? { ...prevState, token: '' } : null,
              )
              location.href = '..'
            }}>
            Logg ut
          </Button>
        </div>
      </>
    </ModalWrapper>
  )
}

export default LogOut
