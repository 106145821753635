import { colors } from '../themes/generalStyles'
import { useContext } from 'react'
import { Grid, IconButton } from '@mui/material'
import WorkoutContext from '../Contexts/WorkoutContext'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const ScheduleScrollButtons = ({
  activeDays,
  increment,
}: {
  activeDays: string[]
  increment: number
}) => {
  const { page, setPage } = useContext(WorkoutContext)
  const pages = Math.ceil(activeDays.length / increment)
  const marginTop = '-315px'
  const disableBack = page === 0
  const disableForward = pages < 1 || page >= pages - 1

  return (
    <Grid container direction='row' justifyContent='space-between'>
      <div
        style={{
          marginTop: marginTop,
        }}>
        <IconButton
          disabled={disableBack}
          onClick={() => setPage(prevState => prevState - 1)}
          sx={{
            color: colors.darkgrey,
            height: 300,
            width: 70,
            borderRadius: 2,
          }}>
          <ArrowBackIosNewIcon sx={{ fontSize: 100 }} />
        </IconButton>
      </div>
      <div
        style={{
          marginTop: marginTop,
          // width: '100%',
        }}>
        <IconButton
          onClick={() => setPage(prevState => prevState + 1)}
          disabled={disableForward}
          sx={{
            color: colors.darkgrey,
            height: 300,
            width: 70,
            borderRadius: 2,
          }}>
          <ArrowForwardIosIcon sx={{ fontSize: 100 }} />
        </IconButton>
      </div>
    </Grid>
  )
}

export default ScheduleScrollButtons
