import ModalWrapper from './ModalWrapper'
import SessionInfoCard from '../Utils/SessionInfoCard'

const WorkoutDetailsSmallDevices = () => {
  return (
    <ModalWrapper
      header='Økt info'
      openModalKey='smallerdeviceWorkoutDetails'
      styles={{ height: '100vh' }}>
      <SessionInfoCard />
    </ModalWrapper>
  )
}

export default WorkoutDetailsSmallDevices
