import { createContext, Dispatch, SetStateAction, useState } from 'react'

type ModalContextType = {
  actionModalData: { header: string; key: string }
  openModal: string
  setActionModalData: Dispatch<SetStateAction<{ header: string; key: string }>>
  setOpenModal: Dispatch<SetStateAction<string>>
}

const ModalContext = createContext({} as ModalContextType)

export const ModalContextProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const [openModal, setOpenModal] = useState('')
  const [actionModalData, setActionModalData] = useState({
    header: '',
    key: '',
  })

  const value = {
    actionModalData,
    openModal,
    setActionModalData,
    setOpenModal,
  }
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export default ModalContext
