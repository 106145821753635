import { Box, Button, Card, CardContent, CardMedia, Grid } from '@mui/material'
import { buttonSuccessStyle, colors } from '../../themes/generalStyles'
import { ArticleType, CartType } from './WebShop'
import { Dispatch, SetStateAction } from 'react'

const Article = ({
  article,
  setCart,
}: {
  article: ArticleType
  setCart: Dispatch<SetStateAction<CartType[]>>
}) => {
  const onArticleClick = () => {
    setCart(prevState => {
      if (!prevState.find(item => item.id === article.id)) {
        return [...prevState, { ...article, qty: 1 }]
      } else {
        return prevState
      }
    })
  }
  return (
    <Box
      onClick={onArticleClick}
      sx={{
        width: 200,
        transition: '0.5s',
        cursor: 'pointer',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}>
      <Card
        elevation={5}
        sx={{
          width: '100%',
          // maxHeight: 320,
          backgroundColor: colors.lightGray,
          marginBottom: 0,
        }}>
        <CardContent sx={{ marginBottom: -2 }}>
          <CardMedia
            sx={{
              width: '100%',
              height: 150,
              borderRadius: 2,
            }}
            image={article.image}
          />
          <Grid container justifyContent='center' sx={{ padding: 1 }}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              sx={{
                backgroundColor: colors.disabled,
                width: '100%',
                borderRadius: 2,
                padding: 1,
                marginBottom: 1,
              }}>
              <div style={{ textAlign: 'center' }}>{article.name}</div>
            </Grid>
            <h4 style={{ margin: 0, fontSize: 30 }}>{article.price},-</h4>
          </Grid>
        </CardContent>
      </Card>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: -10,
        }}>
        <Button
          sx={{
            ...buttonSuccessStyle,
            width: '100%',
            marginTop: 1,
            borderStartStartRadius: 0,
            borderStartEndRadius: 0,
          }}>
          Legg i handlekurv
        </Button>
      </div>
    </Box>
  )
}

export default Article
