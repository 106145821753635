import { Button, CircularProgress, Grid } from '@mui/material'
import { useContext, useState } from 'react'
import createUserContext from '../../../Contexts/CreateUserContext'
import PhoneFormValidator from './PhoneFormValidator'
import TextInputField from '../../../Utils/TextInputField'
import { buttonMainStyle, colors } from '../../../themes/generalStyles'
import modalContext from '../../../Contexts/ModalContext'

const PageFour = () => {
  const {
    formData,
    setFormData,
    smsResponse,
    isLoading,
    getSmsCode,
    createUserResponse,
  } = useContext(createUserContext)
  const { setOpenModal } = useContext(modalContext)
  const [disableButton, setDisableButton] = useState(false)
  return (
    <Grid container justifyContent='center'>
      {isLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      <div>
        {createUserResponse === 'error' && (
          <h3>
            Det virket som at det var et problem å opprette brukeren din.
            Vennligst merk at du ikke allerede har en bruker hos oss (du kan
            prøve å logge inn{' '}
            <span
              onClick={() => setOpenModal('login')}
              style={{
                color: colors.main,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}>
              her
            </span>
            ).
          </h3>
        )}
        {smsResponse === 'success' && createUserResponse !== 'error' && (
          <h3>
            Du skal nå ha fått en sms med en kode som skal skrives inn i feltet
            under. Dette vil brukes til å verifisere telefonnummeret ditt mot
            din iSteady bruker.
          </h3>
        )}
        {smsResponse === 'error' && !disableButton && (
          <div style={{ width: '100%' }}>
            <h3>
              Det virket som at det var et problem å sende deg en sms. Vennligst
              dobbeltsjekk at du har skrevet inn riktig nummer.{' '}
            </h3>
            <Grid container direction='column' alignItems='center' gap={2}>
              <div style={{ width: 300 }}>
                <TextInputField
                  startAdornment={<div>+47</div>}
                  propKey='phone_number'
                  value={formData.phone_number}
                  label='Telefonnummer'
                  setField={setFormData}
                />
              </div>
              <div>Noe gikk galt. Prøv igjen:</div>
              <div>
                <Button
                  disabled={disableButton}
                  sx={buttonMainStyle}
                  onClick={() => {
                    setDisableButton(true)
                    getSmsCode()
                  }}>
                  Send ny sms
                </Button>
              </div>
            </Grid>
          </div>
        )}
        {smsResponse === 'error' && disableButton && (
          <div>Noe er galt, vennligst kontakt oss.</div>
        )}
      </div>
      {smsResponse !== 'error' && (
        <Grid container justifyContent='center' sx={{ marginTop: 2 }}>
          <PhoneFormValidator />
        </Grid>
      )}
    </Grid>
  )
}

export default PageFour
