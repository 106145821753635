import ModalWrapper from './ModalWrapper'
import { useContext } from 'react'
import WorkoutContext from '../Contexts/WorkoutContext'
import StrongAndSteadyInfo from '../Views/MainPage/Workouts/StrongAndSteadyInfo'
import ShoulderAndKneesInfo from '../Views/MainPage/Workouts/ShoulderAndKneesInfo'
import { Grid } from '@mui/material'
import settings from '../config'
import sessionNames from '../constants'

const SessionInfo = () => {
  const { selectedWorkoutInfo } = useContext(WorkoutContext)
  const selectedWorkout =
    (selectedWorkoutInfo && selectedWorkoutInfo.sessionType?.name) || ''
  return (
    <ModalWrapper
      header='Info om timen'
      openModalKey='sessionInfo'
      styles={{ maxHeight: '80vh', width: 900 }}>
      <Grid container justifyContent='center'>
        {settings.sessionNames.includes(selectedWorkout) && (
          <Grid container justifyContent='center'>
            <ShoulderAndKneesInfo
              selectedWorkout={
                (selectedWorkoutInfo &&
                  selectedWorkoutInfo.sessionType?.name) ||
                ''
              }
            />
          </Grid>
        )}
        {selectedWorkout === sessionNames['strongAndSteady'] && (
          <StrongAndSteadyInfo />
        )}
      </Grid>
    </ModalWrapper>
  )
}

export default SessionInfo
