const PrettyPhoneFormatter = ({
  phoneNumber,
}: {
  phoneNumber: string | null
}) => {
  const phoneNumberClean = phoneNumber ? phoneNumber?.replace('+47', '') : ''
  const start = phoneNumberClean.slice(0, 3)
  const middle = phoneNumberClean.slice(3, 5)
  const end = phoneNumberClean.slice(5, 10)
  return phoneNumber ? (
    <>
      <span>+47</span>{' '}
      {[start, middle, end].map((item, key) => (
        <span key={key}> {item}</span>
      ))}
    </>
  ) : (
    <></>
  )
}

export default PrettyPhoneFormatter
