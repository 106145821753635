import { Grid } from '@mui/material'
import { colors } from '../../themes/generalStyles'
import { WhereByType } from '../../hooks/useWhereby'
import { isEmpty } from 'lodash'
import { useEffect, useRef } from 'react'

const ChatMessage = ({
  message,
  ownMessage,
  sender,
}: {
  sender: string
  message: string
  ownMessage: boolean
}) => {
  const flexDirection = ownMessage ? 'flex-end' : 'flex-start'
  const nameMargin = ownMessage ? { marginRight: 5 } : { marginLeft: 5 }
  return (
    <Grid
      item
      sx={{
        paddingLeft: 1,
        paddingRight: 1,
      }}>
      <Grid container>
        <div
          style={{
            display: 'flex',
            justifyContent: flexDirection,
            width: '100%',
            fontSize: 12,
            ...nameMargin,
          }}>
          {sender}
        </div>
        <Grid container justifyContent={flexDirection}>
          <Grid
            item
            sx={{
              width: '80%',
              color: 'black',
              backgroundColor: ownMessage ? colors.main : colors.disabled,
              padding: 1,
              borderRadius: 3,
            }}>
            <span
              style={{
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                wordBreak: 'break-all',
                whiteSpace: 'normal',
                hyphens: 'auto',
              }}>
              {message}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ChatHistory = ({
  userId,
  chatMessages,
  namesInMeeting,
}: {
  namesInMeeting: WhereByType['namesInMeeting']
  chatMessages: WhereByType['chatMessages']
  userId: string | null
}) => {
  const noChats = isEmpty(chatMessages)
  const flippedChatMessages = [...chatMessages].reverse()
  return (
    <div
      style={{
        flexDirection: 'column-reverse',
        display: 'flex',
        width: '100%',
        height: 'calc(100% - 150px)',
        overflowY: 'auto',
      }}>
      {noChats ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          Ingen meldinger enda
        </div>
      ) : (
        flippedChatMessages.map((chat, key) => (
          <ChatMessage
            sender={
              chat.senderId === userId
                ? 'Deg'
                : namesInMeeting[chat.senderId] || 'Ukjent navn'
            }
            message={chat.text}
            ownMessage={chat.senderId === userId}
            key={key}
          />
        ))
      )}
    </div>
  )
}

export default ChatHistory
