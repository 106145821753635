import PrettyPhoneFormatter from '../../Utils/PrettyPhoneFormatter'
import { Grid } from '@mui/material'
import { useContext } from 'react'
import UserContext from '../../Contexts/UserContext'
import screenScaleContext from '../../Contexts/ScreenScaleContext'

const MyPageUserDetails = () => {
  const underlineStyle = { style: { textDecoration: 'underline' } }
  const { userData, setUserLogin, updateUserData, mediumScreen } =
    useContext(UserContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const lineHeight = 1.7
  return smallScreenWidth ? (
    <Grid
      container
      direction='row'
      gap={10}
      alignItems='center'
      justifyContent='flex-start'>
      <div style={{ lineHeight: lineHeight, fontSize: 22 }}>
        <div>
          <strong style={{ textDecoration: 'underline' }}>Navn:</strong>{' '}
          {userData?.firstName} {userData?.lastName}
        </div>
        <div>
          <strong {...underlineStyle}>Adresse:</strong> {userData?.address}
        </div>
        <div>
          <strong {...underlineStyle}>Mobil:</strong>{' '}
          <PrettyPhoneFormatter phoneNumber={userData?.phoneNumber || null} />
        </div>
        <div>
          <strong {...underlineStyle}>Epost:</strong> {userData?.emailAddress}
        </div>
      </div>
    </Grid>
  ) : (
    <Grid
      container
      direction='row'
      gap={10}
      alignItems='center'
      justifyContent='flex-start'>
      <div style={{ lineHeight: lineHeight, fontSize: 22 }}>
        <div>
          <strong style={{ textDecoration: 'underline' }}>Navn:</strong>
        </div>
        <div>
          <strong {...underlineStyle}>Adresse:</strong>
        </div>
        <div>
          <strong {...underlineStyle}>Mobil:</strong>
        </div>
        <div>
          <strong {...underlineStyle}>Epost:</strong>
        </div>
      </div>
      <div style={{ lineHeight: lineHeight, fontSize: 22 }}>
        <div>
          {userData?.firstName} {userData?.lastName}
        </div>
        <div>{userData?.address}</div>
        <div>
          <PrettyPhoneFormatter phoneNumber={userData?.phoneNumber || null} />
        </div>
        <div>{userData?.emailAddress}</div>
      </div>
    </Grid>
  )
}

export default MyPageUserDetails
