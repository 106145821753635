import { buttonDisabledStyle } from '../themes/generalStyles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Button } from '@mui/material'
import { CSSProperties, useContext } from 'react'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const BackButton = ({
  onClick,
  sx = {},
}: {
  onClick: () => void
  sx?: CSSProperties
}) => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <Button
      sx={buttonDisabledStyle}
      style={{
        fontSize: 20,
        height: 40,
        borderRadius: 5,
        // width: 110,
        ...sx,
      }}
      startIcon={<KeyboardBackspaceIcon fontSize='large' />}
      onClick={() => onClick()}>
      {!smallScreenWidth && 'Tilbake'}
    </Button>
  )
}

export default BackButton
