const GridIt = ({
  children,
  columns,
  placements,
}: {
  children: JSX.Element[]
  columns: number
  placements: [string, string][]
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gap: '20px',
      }}>
      {children.map((item, key) => {
        const [row, col] = placements[key]
        const [rowFrom, rowTo] = row.split(':')
        const [colFrom, colTo] = col.split(':')
        const rowString = row.includes(':') ? `${rowFrom}/${rowTo}` : row
        const colString = col.includes(':') ? `${colFrom}/${colTo}` : col
        return (
          <div
            key={key}
            style={{
              gridColumn: colString,
              gridRow: rowString,
              height: '100%',
            }}>
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default GridIt
