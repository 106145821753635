import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { postSmsCode } from '../api/fetch'
import apiResponsFormatter, {
  SessionType,
  UserDataType,
} from '../helpers/apiResponsFormatter'
import useLoggedIn from '../hooks/useLoggedIn'
import useApi from '../hooks/useApi'

type UserLoginType = {
  created: number
  token: string
  phoneNumber: string
  newsCount: number
}

export type UserContextType = {
  tokenInvalidatesAfter: number
  assignedSessions: { [key: string]: SessionType[] }
  userLogin: UserLoginType | null
  setUserLogin: Dispatch<SetStateAction<UserLoginType | null>>
  loggedIn: boolean
  userData: UserDataType | null
  setAssignedSessions: Dispatch<
    SetStateAction<{ [key: string]: SessionType[] }>
  >
  setUserData: Dispatch<SetStateAction<UserDataType | null>>
  fetchingUserData: boolean
  updateUserData: () => void
  smallScreen: boolean
  mediumScreen: boolean
  path: string
  setPath: Dispatch<SetStateAction<string>>
}

const UserContext = createContext({} as UserContextType)

export const UserContextProvider = ({
  children,
  setLoadingPage,
}: {
  children: JSX.Element
  setLoadingPage: Dispatch<SetStateAction<boolean>>
}) => {
  // See if user has the data attached to the local storage:
  const userInfoLocalStorage = window.localStorage.getItem('isteady-user')
  const userInfoLocalStorageJs = userInfoLocalStorage
    ? (JSON.parse(userInfoLocalStorage) as UserLoginType)
    : null
  const [userLogin, setUserLogin] = useState<UserContextType['userLogin']>(
    userInfoLocalStorageJs || null,
  )

  const [path, setPath] = useState('')

  const [userData, setUserData] = useState<UserDataType | null>(null)
  const [assignedSessions, setAssignedSessions] = useState<
    UserContextType['assignedSessions']
  >({})

  const nowTime = new Date().getTime()
  const tokenInvalidatesAfter =
    Number(process.env.REACT_APP_TOKEN_INVALIDATES_AFTER) * 1000

  const fetchAndUpdateUserData = () => {
    if (userLogin) {
      doFetch({
        data: { phone_number: userLogin.phoneNumber },
        customHeaderKeys: {
          phone_number: userLogin.phoneNumber,
          token: userLogin.token,
        },
        onSuccess: data => {
          setUserData(apiResponsFormatter(data))
        },
      })
    }
  }

  const { doFetch, isLoading: fetchingUserData } = useApi(postSmsCode)
  // each time userLogin updates, we update the localstorage
  useEffect(() => {
    if (userLogin) {
      window.localStorage.setItem(
        'isteady-user',
        JSON.stringify({
          token: userLogin.token,
          phoneNumber: userLogin.phoneNumber,
          created: userLogin.created,
          newsCount: userLogin.newsCount || 0,
        }),
      )
    }
  }, [userLogin])

  useEffect(() => {
    if (
      userLogin?.token &&
      userLogin.phoneNumber &&
      nowTime - userLogin?.created < tokenInvalidatesAfter
    ) {
      fetchAndUpdateUserData()
    }
    setLoadingPage(false)
  }, [])

  // update assigned sessions state based on userdata changes
  useEffect(() => {
    if (userData?.sessions) {
      const { activeSession } = userData.sessions.reduce(
        (results, item) => {
          results.activeSession[item.day] = [
            ...(results.activeSession[item.day] || []),
            item,
          ]
          return results
        },
        { activeSession: {} } as {
          activeSession: { [key: string]: SessionType[] }
        },
      )
      setAssignedSessions(activeSession)
    }
  }, [userData])

  // check if user is logged in as well as any buttons available for joining
  const { loggedIn } = useLoggedIn(
    userData,
    nowTime,
    tokenInvalidatesAfter,
    userLogin,
  )
  const value = {
    tokenInvalidatesAfter,
    assignedSessions,
    userLogin,
    setUserLogin,
    loggedIn,
    userData,
    setAssignedSessions,
    setUserData,
    fetchingUserData,
    updateUserData: fetchAndUpdateUserData,
    smallScreen: window.innerWidth < 900 || window.innerHeight < 900,
    mediumScreen: window.innerWidth < 1200,
    path,
    setPath,
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContext
