import MainCard from '../Views/MainPage/MainCard'
import { ReactNode } from 'react'

const MainCardCustom = ({ children }: { children: ReactNode }) => (
  <MainCard
    noPadding
    style={{
      borderRadius: 8,
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      width: 120,
    }}>
    <>{children}</>
  </MainCard>
)

export default MainCardCustom
