import { Box, Grid, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { buttonDisabledStyle } from '../../themes/generalStyles'
import { CartType } from './WebShop'
import { Dispatch, SetStateAction } from 'react'

const CartRow = ({
  cart,
  setCart,
}: {
  cart: CartType
  setCart: Dispatch<SetStateAction<CartType[]>>
}) => {
  const onQtyAdjustClick = (increase: boolean) => {
    if (!increase) {
      setCart(prevState => {
        if (cart.qty === 1) {
          return prevState.filter(item => item.id !== cart.id)
        } else {
          return prevState.map(item =>
            item.id === cart.id ? { ...item, qty: item.qty - 1 } : item,
          )
        }
      })
    } else {
      setCart(prevState =>
        prevState.map(item =>
          item.id === cart.id ? { ...item, qty: item.qty + 1 } : item,
        ),
      )
    }
  }
  return (
    <Grid
      container
      justifyContent='space-around'
      alignItems='center'
      gap={2}
      sx={{ borderTop: '1px dashed black', padding: 1 }}>
      <Grid item>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          gap={1}
          sx={{ ...buttonDisabledStyle, width: 110, borderRadius: 2 }}>
          <IconButton size='small' onClick={() => onQtyAdjustClick(false)}>
            <RemoveIcon />
          </IconButton>
          <Box>{cart.qty}</Box>
          <IconButton size='small' onClick={() => onQtyAdjustClick(true)}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs='auto'>
        <div style={{ display: 'inline-block', maxWidth: 130 }}>
          {cart.name}
        </div>
      </Grid>
      <Grid item xs='auto'>
        <div>{cart.price * cart.qty},-</div>
      </Grid>
    </Grid>
  )
}

export default CartRow
