import { InlineWidget } from 'react-calendly'
import { useContext } from 'react'
import userContext from '../../Contexts/UserContext'
import { Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'

const CalendlyAdd = () => {
  const { userData } = useContext(userContext)
  const ref = process.env['REACT_APP_CALENDLY_URL']

  return (
    <Grid container justifyContent='center'>
      <TopLogoCard
        backToButton='..'
        sx={{ width: '100%' }}
        content='iSteady treningsoversikt'
      />
      {ref && userData && (
        <Grid container justifyContent='center' style={{ width: '100%' }}>
          <InlineWidget
            prefill={{
              email: userData.emailAddress,
              firstName: userData.firstName,
              lastName: userData.lastName,
            }}
            styles={{
              width: '100%',
              height: '100vh',
              marginTop: -30,
              padding: 0,
            }}
            url={ref}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default CalendlyAdd
