import { Box, Grid } from '@mui/material'
import MainCard from '../MainCard'
import { colors } from '../../../themes/generalStyles'
import settings from '../../../config'
import { ReactNode, useContext } from 'react'
import screenScaleContext from '../../../Contexts/ScreenScaleContext'
import sessionNames from '../../../constants'

const LevelCustom = ({
  color,
  children,
  header,
  smallWidth,
}: {
  color: string
  header: string
  children: ReactNode
  smallWidth: boolean
}) => (
  <Box
    sx={{
      width: smallWidth ? '95%' : '32%',
      minWidth: 180,
      boxShadow: colors.shadow,
      borderRadius: 2,
    }}>
    <Grid
      container
      sx={{
        backgroundColor: color,
        opacity: 0.8,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      }}
      justifyContent='center'>
      <h2 style={{ margin: 0 }}>{header}</h2>
    </Grid>
    <Grid sx={{ padding: 2, fontSize: 18 }}>
      <div style={{ textAlign: 'center' }}>{children}</div>
    </Grid>
  </Box>
)

const StrongAndSteadyInfo = () => {
  const { screenWidth } = useContext(screenScaleContext)
  const smallScreen = screenWidth < 700
  return (
    <div style={{ maxWidth: 750 }}>
      <MainCard sx={{ width: '100%' }}>
        <Grid container sx={{ padding: 2 }}>
          <Grid item sx={smallScreen ? { width: '100%' } : { width: '40%' }}>
            <Grid container direction='column' alignItems='center'>
              <h2 style={{ margin: 5, textAlign: 'center' }}>
                {sessionNames['strongAndSteady']}
              </h2>
              <img
                alt='some id'
                src={`${settings.bucket}/root_images/squating.png`}
                style={{ width: '100%', height: 'auto', borderRadius: 5 }}
              />
            </Grid>
          </Grid>
          <Grid item sx={smallScreen ? { width: '100%' } : { width: '60%' }}>
            <Grid
              sx={{ padding: 3 }}
              container
              direction='column'
              alignItems='center'
              gap={2}
              justifyContent='center'>
              <div>
                <strong>{sessionNames['strongAndSteady']}</strong> tar for seg
                hele kroppen med elementer av styrke, balanse og utholdenhet.
                Treningen foregår mye i stående, men avhenger av nivå.
              </div>
              <div>
                <strong>Nivå:</strong> Velg mellom 3 ulike nivåer <br />
                <strong>Varighet:</strong> 45 min <br />
                <strong>Utstyr:</strong> Strikk og stol + balansepute el. på
                nivå 2 og 3
              </div>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <Grid
        container
        {...(smallScreen
          ? {
              justifyContent: 'center',
            }
          : {
              justifyContent: 'space-between',
            })}
        sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <LevelCustom
          color={colors.success}
          header='Nivå 1'
          smallWidth={smallScreen}>
          <div>
            <div>
              Nivå 1 er det som krever minst bevegelighet, koordinasjon og
              styrke fra før. Det passer for deg som er lettere ustø og som
              trenger lett støtte ved utførelse av øvelser i stånde
            </div>
            <div>
              Trening foregår i sittende og stående med støtte fra stolen
            </div>
          </div>
        </LevelCustom>
        <LevelCustom
          color={colors.warning}
          header='Nivå 2'
          smallWidth={smallScreen}>
          <div>
            <div>
              Nivå 2 passer for deg som kan stå og gå uten støtte. Flere av
              øvelse krever at du kan stå på en fot med lett støtte fra stol
            </div>
            <br />
            <div>Mesteparten av treningen skjer i stående stilling</div>
          </div>
        </LevelCustom>
        <LevelCustom
          color={colors.error}
          header='Nivå 3'
          smallWidth={smallScreen}>
          <div>
            <div>
              Nivå 3 passer for deg som ønsker en større utfordring når det
              kommer til styrke, koordinasjon og bevegelsesutfoldelse
            </div>
            <br />
            <div>
              Treningen foregår hovedsaklig i stående og krever at du er i stand
              til å stå på en fot uten støtte
            </div>
          </div>
        </LevelCustom>
      </Grid>
    </div>
  )
}

export default StrongAndSteadyInfo
