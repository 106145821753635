import { Button } from '@mui/material'
import {
  buttonDisabledStyle,
  buttonSuccessStyle,
  buttonWarningStyle,
} from '../../themes/generalStyles'
import { Dispatch, SetStateAction, useContext } from 'react'
import createUserContext from '../../Contexts/CreateUserContext'
import requiredKeysForPage from '../../helpers/requiredKeysForPage'
import NextButton from './NextButton'
import { Link } from 'react-router-dom'

const CreateUserNavigationButtons = ({
  page,
  setPage,
  isUser,
}: {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  isUser: boolean
}) => {
  const { formData, getSmsCode, smsResponse, createUser } =
    useContext(createUserContext)
  const disableNext = !requiredKeysForPage(page, formData)
  return isUser ? (
    <>
      <Link to='/'>
        <Button sx={{ ...buttonSuccessStyle, width: 150 }}>Fullfør</Button>
      </Link>
    </>
  ) : (
    <>
      <Button
        size='large'
        disabled={page === 0}
        sx={{
          ...(page === 0 ? buttonDisabledStyle : buttonWarningStyle),
          width: 100,
        }}
        onClick={() => setPage(prevState => prevState - 1)}>
        Tilbake
      </Button>
      <NextButton
        disableNext={disableNext}
        page={page}
        setPage={setPage}
        smsResponse={smsResponse}
      />
    </>
  )
}

export default CreateUserNavigationButtons
