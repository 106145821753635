import { Button, Grid } from '@mui/material'
import { buttonSuccessStyle } from '../../themes/generalStyles'
import AddIcon from '@mui/icons-material/Add'
import { useContext } from 'react'
import screenScaleContext from '../../Contexts/ScreenScaleContext'

const AddSessionToCalendarButton = ({
  onButtonClick,
}: {
  onButtonClick: () => void
}) => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <span
      style={
        smallScreenWidth
          ? {}
          : { float: 'right', transform: 'translate(0px, 10px)' }
      }>
      <Button
        onClick={onButtonClick}
        sx={{
          ...buttonSuccessStyle,
          fontSize: 18,
          fontWeight: 'bold',
        }}>
        <Grid container>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <AddIcon fontSize='large' />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1.2,
            }}>
            Legg til <br /> gruppetime
          </div>
        </Grid>
      </Button>
    </span>
  )
}

export default AddSessionToCalendarButton
