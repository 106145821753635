import MainCard from '../Views/MainPage/MainCard'
import { Button, Grid } from '@mui/material'
import {
  buttonSuccessStyle,
  centerStyle,
  colors,
} from '../themes/generalStyles'

export type CalendlyOutputDataType = {
  duration: number
  end_time: string
  name: string
  start_time: string
  zoom_url: string
  date: string
}

const BookingSessionCard = ({ data }: { data: CalendlyOutputDataType }) => {
  const buttonOnClick = () => {
    location.href = data.zoom_url
  }

  return (
    <MainCard
      noPadding
      style={{
        border: '2px solid black',
        padding: 0,
      }}>
      <div style={{ padding: 0 }}>
        <h3
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: 0,
            textDecoration: 'underline',
            marginBottom: 5,
          }}>
          {data.name}
        </h3>
        <div style={{ fontSize: 13, color: colors.darkgrey }}>
          <Grid container justifyContent='center' gap={2}>
            <Grid item>
              <strong>Dato:</strong> {data.date}
            </Grid>
            <Grid item>
              <strong>Tid:</strong> {data.duration} min
            </Grid>
          </Grid>
          <Grid container justifyContent='center'>
            <div>
              <strong>Klokkeslett: </strong> {data.start_time} - {data.end_time}
            </div>
          </Grid>
          <div style={{ ...centerStyle, marginTop: 5, marginBottom: 15 }}>
            <Button onClick={() => buttonOnClick()} sx={buttonSuccessStyle}>
              Bli med på møtet
            </Button>
          </div>
        </div>
      </div>
    </MainCard>
  )
}

export default BookingSessionCard
