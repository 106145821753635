import { UserContextType } from '../Contexts/UserContext'

const subscriptionState = (
  userData: UserContextType['userData'],
  sub_id: string,
): 'active' | 'cancelled' | 'disabeled' | 'selectable' => {
  const isActive = userData?.assignedSubscription === sub_id
  if (isActive && userData?.subscriptionCancelAt) {
    return 'cancelled'
  } else if (isActive) {
    return 'active'
  } else if (userData?.assignedSubscription) {
    return 'disabeled'
  } else {
    return 'selectable'
  }
}

export default subscriptionState
