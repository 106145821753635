import {
  buttonMainStyle,
  centerStyle,
  colors,
  font,
} from '../themes/generalStyles'
import HeaderLine from '../Utils/HeaderLine'
import { Button, Grid, Modal } from '@mui/material'
import { useContext } from 'react'
import VideoContext from '../Contexts/VideoContext'

const VideoErrorModal = () => {
  const { localMedia, localStream } = useContext(VideoContext)
  const meetingStartingError = localMedia.state.startError !== undefined
  return (
    <Modal
      sx={font}
      disableAutoFocus={true}
      open={meetingStartingError}
      style={centerStyle}>
      <div
        style={{
          backgroundColor: colors.secondGray,
          borderRadius: 5,
          width: 400,
          height: 400,
        }}>
        <div style={{ transform: 'translate(0px, -30px)' }}>
          <Grid container justifyContent='center'>
            <h1
              style={{
                paddingLeft: 30,
                margin: 0,
                textAlign: 'center',
                marginRight: 70,
              }}>
              Video Feil
            </h1>
          </Grid>
          <HeaderLine />
        </div>
        <div style={{ padding: 15 }}>
          Isteady trenger tilgang til kamera og mikrofon for å kunne delta i
          timen. Last inn siden på nytt og prøv igjen ved å klikke på knappen
          under. Hvis dette ikke løser problemet, ta kontakt med kundeservice.
        </div>
        <Grid container justifyContent='center' sx={{ padding: 5 }}>
          <Button sx={buttonMainStyle} onClick={() => location.reload()}>
            Last inn siden på nytt
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

export default VideoErrorModal
