import GridUpDown from '../../../Utils/GridUpDown'
import { colors } from '../../../themes/generalStyles'
import settings from '../../../config'

const WorkoutBase = ({
  image,
  title,
}: {
  image: string | undefined
  title: string
}) => {
  const baseImage = require('../../../Images/isteadyLogo.png')
  const imagePath = image ? `${settings.bucket}/images/${image}` : baseImage
  return (
    <GridUpDown>
      <h2
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 0,
          color: colors.darkgrey,
        }}>
        {title}
      </h2>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img
          alt={title}
          src={imagePath}
          style={{
            boxShadow: colors.shadow,
            borderRadius: 5,
            width: '90%',
            maxWidth: 250,
            padding: 0,
            height: 'auto',
            cursor: 'pointer',
          }}
        />
      </div>
    </GridUpDown>
  )
}

export default WorkoutBase
