import {
  buttonDisabledStyle,
  buttonSuccessStyle,
  buttonWarningStyle,
  colors,
} from '../themes/generalStyles'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material'
import { Dispatch, SetStateAction, useContext, useState } from 'react'
import userContext from '../Contexts/UserContext'
import subscriptionState from '../helpers/subscriptionState'
import parseEpochToDate from '../helpers/parseEpochToDate'
import { UseRouteToStripeType } from '../hooks/useRouteToStripe'

const SubscriptionCard = ({
  bindingPeriod,
  id,
  cost,
  name,
  rubberBandId = '',
  setNewCustomerView,
  notUsedFreeTrial,
  apis,
  coupon,
  setCoupon,
}: {
  bindingPeriod: number
  id: string
  cost: number
  name: string
  rubberBandId?: string
  setNewCustomerView: Dispatch<SetStateAction<string>>
  notUsedFreeTrial: boolean
  apis: UseRouteToStripeType
  coupon: string | undefined
  setCoupon: Dispatch<SetStateAction<string>>
}) => {
  const { loggedIn, userData } = useContext(userContext)
  const { isLoading, buttonOnClick } = apis

  const subState = subscriptionState(userData, id)
  const subscriptionIsActive = ['active', 'cancelled'].includes(subState)
  const disableOther = subState === 'disabeled'
  const couponView = coupon !== undefined
  const buttonStyle = (
    subscriptionIsActive && !couponView
      ? buttonWarningStyle
      : disableOther
      ? buttonDisabledStyle
      : buttonSuccessStyle
  ) as any
  // inject boxshadow into buttonStyle
  buttonStyle['&:hover'] = {
    ...buttonStyle['&:hover'],
    boxShadow: colors.shadow,
    opacity: 0.7,
  }
  const deactivationText =
    subState === 'cancelled'
      ? parseEpochToDate(userData?.subscriptionCancelAt || null)
      : ''
  return (
    <Grid
      container
      justifyContent='center'
      sx={{ width: '100%', fontSize: 23 }}>
      <Box
        sx={{
          boxShadow: colors.shadow,
          borderRadius: 1,
          width: '100%',
          color: colors.darkgrey,
          padding: 2,
        }}>
        <Grid container justifyContent='space-around' alignItems='center'>
          <Grid item sx={{ width: 120 }}>
            {couponView
              ? 'Kupong'
              : `${bindingPeriod} Måned${bindingPeriod !== 1 ? 'er' : ''}`}
          </Grid>
          <Grid item>
            {couponView ? (
              <>
                <TextField
                  value={coupon}
                  onChange={e => setCoupon(e.target.value)}
                  id='coupon-input-id'
                  label='Kupongkode'
                  variant='outlined'
                />
              </>
            ) : (
              <Grid container direction='column' alignItems='center'>
                <div>{cost},-/mnd</div>
                <div style={{ fontSize: 10 }}>
                  Totalt <strong>kr {cost * bindingPeriod}</strong>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Tooltip
              arrow
              title={
                disableOther ? (
                  <div style={{ maxWidth: 200 }}>
                    For å melde seg på denne må du først avslutte et annet
                    løpende abonnement.
                  </div>
                ) : (
                  ''
                )
              }>
              <div>
                <Button
                  sx={{ ...buttonStyle, boxShadow: colors.shadow }}
                  disabled={disableOther}
                  size='small'
                  style={{
                    height: 50,
                    width: 200,
                    fontSize: 18,
                  }}
                  variant='contained'
                  onClick={() => {
                    if (loggedIn) {
                      notUsedFreeTrial
                        ? setNewCustomerView(id)
                        : buttonOnClick(
                            subscriptionIsActive,
                            id,
                            rubberBandId,
                            coupon || '',
                          )
                    } else {
                      window.location.href = '/ny-bruker'
                    }
                  }}>
                  {isLoading ? (
                    <CircularProgress size={30} />
                  ) : subscriptionIsActive && !couponView ? (
                    'Rediger medlemskap'
                  ) : (
                    'Aktiver medlemskap'
                  )}
                </Button>
                {deactivationText && !coupon && (
                  <div
                    style={{
                      fontSize: 12,
                      width: '100%',
                      textAlign: 'center',
                      color: colors.warning,
                    }}>
                    Vil deaktiveres {deactivationText}
                  </div>
                )}
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default SubscriptionCard
