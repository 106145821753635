import MainCard from '../../Views/MainPage/MainCard'
import { Button, CardHeader, CircularProgress, Grid } from '@mui/material'
import {
  buttonSuccessStyle,
  centerStyle,
  colors,
  standardWidthLarger,
} from '../../themes/generalStyles'
import HeaderLine from '../../Utils/HeaderLine'
import { isEmpty } from 'lodash'
import BookingSessionCard, {
  CalendlyOutputDataType,
} from '../../Utils/BookingSessionCard'
import { useContext, useEffect } from 'react'
import userContext from '../../Contexts/UserContext'
import useApi from '../../hooks/useApi'
import { postCalendlyData } from '../../api/fetch'
import Link from '../../Utils/Link'
import TopLogoCard from '../MainPage/TopLogoCard'
import settings from '../../config'

const ReserveCalendlyBooking = () => {
  const { userData, loggedIn } = useContext(userContext)
  const { data, doFetch, isLoading } = useApi(postCalendlyData)

  // load calendly data on mount
  useEffect(() => {
    if (userData && userData.emailAddress && loggedIn) {
      doFetch({ data: { email_address: userData.emailAddress } })
    }
  }, [userData, loggedIn])
  const borderCss = '1px solid black'

  return (
    <>
      <MainCard
        sx={{ width: standardWidthLarger, marginBottom: 5 }}
        cardHeader={
          <CardHeader
            title={
              <Grid container>
                <TopLogoCard
                  backToButton='/'
                  sx={{ width: '100%' }}
                  content='Book Oppfølgingstime'
                />
              </Grid>
            }
          />
        }
        cardFooter={
          userData?.validSubscription ? (
            <Link to='/calendly-booking-ny'>
              <Button sx={{ ...buttonSuccessStyle, fontSize: 20 }}>
                Reserver ny time
              </Button>
            </Link>
          ) : (
            <></>
          )
        }>
        <Grid container style={{ height: 540 }}>
          <Grid item xs={7} style={{ height: '100%' }}>
            <MainCard noPadding style={{ border: borderCss, height: '100%' }}>
              <div
                style={{
                  backgroundColor: '#C4C4C4',
                  paddingBottom: 40,
                  height: '100%',
                }}>
                <Grid
                  style={{ padding: 20 }}
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'>
                  <div>
                    <img
                      alt='someid9'
                      src={`${settings.bucket}/root_images/newBookingImage.png`}
                      style={{
                        width: 250,
                        height: 'auto',
                        borderRadius: 10,
                        border: borderCss,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: '40%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginRight: 20,
                    }}>
                    <div
                      style={{
                        color: colors.darkgrey,
                      }}>
                      <h1 style={{ margin: 0, fontSize: 50 }}>349,-</h1>
                      <span style={{ marginLeft: 10 }}>(engangskjøp)</span>
                      <HeaderLine />
                    </div>
                  </div>
                </Grid>
                <div
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    padding: 20,
                    backgroundColor: 'white',
                    borderRadius: 10,
                  }}>
                  <div>
                    Denne timen varer i 30 minutter og er for deg som ønsker
                    oppfølging.
                  </div>
                  <br />
                  <div>
                    Timen er ment som en samtale mellom deltaker og instruktør,
                    hvor du skal kunne stille spørsmål og få hjelp med aktuell
                    problemstilling.
                  </div>
                  <br />
                  <div>
                    Det vil være muligheter for at instruktøren illustrerer
                    hvordan ulike øvelser gjennomføres, eller vise hvordan
                    øvelsene kan tilpasses ditt funksjonsnivå. Timen er ikke
                    ment som en privat treningstime.
                  </div>
                </div>
              </div>
            </MainCard>
          </Grid>
          <Grid item xs={5} style={{ height: '100%' }}>
            <div style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 10,
                  paddingTop: 0,
                  height: '100%',
                }}>
                <MainCard
                  style={{
                    width: '100%',
                    height: '100%',
                    border: borderCss,
                  }}>
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <h2
                        style={{
                          marginBottom: 0,
                          textDecoration: 'underline',
                        }}>
                        Din neste time:
                      </h2>
                    </div>
                    {isLoading ? (
                      <div style={{ ...centerStyle, marginTop: '40%' }}>
                        <CircularProgress size={70} />
                      </div>
                    ) : data &&
                      data?.calendly_data &&
                      !isEmpty(data.calendly_data) ? (
                      <div>
                        {(data.calendly_data || []).map(
                          (item: CalendlyOutputDataType, key: number) => (
                            <div
                              key={key}
                              style={{
                                marginBottom: 10,
                                marginTop: 10,
                              }}>
                              <BookingSessionCard data={item} />
                            </div>
                          ),
                        )}
                      </div>
                    ) : (
                      <div style={{ display: 'flex', marginTop: '10%' }}>
                        <h3 style={{ color: colors.darkgrey }}>
                          Du har ikke meldt deg opp til noen oppfølgingstimer
                          enda.
                        </h3>
                      </div>
                    )}
                  </>
                </MainCard>
              </div>
            </div>
          </Grid>
        </Grid>
      </MainCard>
    </>
  )
}

export default ReserveCalendlyBooking
