import { CardHeader, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import { standardWidthLarger } from '../../themes/generalStyles'
import MainCard from '../MainPage/MainCard'
import settings from '../../config'

const Terms = () => {
  const height = 800
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='..'
                sx={{ width: '100%' }}
                content='iSteady personvern'
              />
            </Grid>
          }
        />
      }
      sx={{ width: standardWidthLarger, marginBottom: 10 }}>
      <Grid container justifyContent='center'>
        <h1>iSteady Personvern</h1>
        <iframe
          title='Personvern'
          style={{ width: '100%', height: height }}
          src={`${settings.bucket}/legal/privacyTerms.pdf`}
        />
        <h1>iSteady Medlemskapvilkår</h1>
        <iframe
          title='Medlemskapvilkår'
          style={{ width: '100%', height: height }}
          src={`${settings.bucket}/legal/membershipTerms.pdf`}
        />
        <h1>iSteady Nettbutikkvilkår</h1>
        <iframe
          title='Nettbutikkvilkår'
          style={{ width: '100%', height: height }}
          src={`${settings.bucket}/legal/webShopTerms.pdf`}
        />
      </Grid>
    </MainCard>
  )
}

export default Terms
