import { Autocomplete, Grid, TextField } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import MainCard from '../Views/MainPage/MainCard'
import createUserContext from '../Contexts/CreateUserContext'
import { colors } from '../themes/generalStyles'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const BirthDateSelect = () => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  const { setFormData } = useContext(createUserContext)
  const [day, setDay] = useState<string | null>(null)
  const [month, setMonth] = useState<string | null>(null)
  const [year, setYear] = useState<string | null>(null)
  const months = [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ]
  const dateSelected = !!(day && month && year)
  const date = dateSelected
    ? new Date(
        `${year}/${months.indexOf(month) + 1}/${Number(day) + 1}`,
      ).toJSON()
    : null

  const faultyDate = date === null && dateSelected
  useEffect(() => {
    if (day && month && year) {
      if (date) {
        setFormData(prevState => ({ ...prevState, birth: date.split('T')[0] }))
      }
    }
  }, [day, month, year])

  return (
    <MainCard
      sx={{
        marginTop: 2,
        width: '100%',
        border: faultyDate ? '1px solid red' : null,
      }}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        gap={2}
        sx={{ width: '100%' }}>
        <div>Fødselsdag:</div>
        <div>
          <Grid
            container
            direction='row'
            gap={3}
            justifyContent='space-between'>
            <Grid
              item
              {...(smallScreenWidth
                ? {
                    xs: 12,
                  }
                : { sx: { width: 100 } })}>
              <Autocomplete
                fullWidth
                disablePortal
                value={day}
                id='some id again 22'
                options={[...Array(31)].map((_, key) => String(key + 1))}
                onChange={(_, option, a) => {
                  if (a === 'clear') {
                    setDay(null)
                  } else {
                    setDay(option)
                  }
                }}
                renderInput={params => <TextField {...params} label='Dag' />}
              />
            </Grid>
            <Grid
              item
              {...(smallScreenWidth
                ? {
                    xs: 12,
                  }
                : { sx: { width: 150 } })}>
              <Autocomplete
                fullWidth
                disablePortal
                value={month}
                id='some id again 222'
                options={months}
                onChange={(_, option, a) => {
                  if (a === 'clear') {
                    setMonth(null)
                  } else {
                    option && setMonth(option)
                  }
                }}
                renderInput={params => <TextField {...params} label='Måned' />}
              />
            </Grid>
            <Grid
              item
              {...(smallScreenWidth
                ? {
                    xs: 12,
                  }
                : { sx: { width: 150 } })}>
              <Autocomplete
                fullWidth
                disablePortal
                value={year}
                id='some id again 2222'
                options={[...Array(122)].map((_, key) => String(key + 1900))}
                onChange={(_, option, a) => {
                  if (a === 'clear') {
                    setYear(null)
                  } else {
                    setYear(option)
                  }
                }}
                renderInput={params => (
                  <TextField {...params} value={year} label='År' />
                )}
              />
            </Grid>
          </Grid>
        </div>
        {faultyDate && (
          <div style={{ color: colors.warning }}>
            Datoen er ikke gyldig vennligst dobbeltsjekk
          </div>
        )}
      </Grid>
    </MainCard>
  )
}

export default BirthDateSelect
