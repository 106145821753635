import { Button, Grid } from '@mui/material'
import { VideoView } from '@whereby.com/browser-sdk/react'
import { CSSProperties, Dispatch, SetStateAction } from 'react'
import { WhereByType } from '../../hooks/useWhereby'
import { buttonDisabledStyle, colors } from '../../themes/generalStyles'
import ReloadButton from '../../Utils/ReloadButton'

const VideoOwner = ({
  onVideoClick,
  offset,
  stream,
}: {
  onVideoClick: () => void
  offset: string
  stream: WhereByType['ownerStream']
}) => {
  const offsetTop = 0
  const allStyles: CSSProperties = {
    transition: 'width 0.5s',
    position: 'absolute',
    top: offsetTop,
    left: 0,
    zIndex: 1101,
    backgroundColor: colors.secondGray,
    width: `calc(100vw - ${offset})`,
    height: '100%',
  }
  return stream ? (
    <VideoView
      onClick={() => onVideoClick()}
      style={allStyles}
      mirror
      stream={stream}
    />
  ) : (
    <Grid
      style={allStyles}
      container
      direction='column'
      justifyContent='center'
      gap={1}
      alignItems='center'>
      <h1>Venter på host...</h1>
      <div>Ventet lenge?</div>
      <ReloadButton />
    </Grid>
  )
}

export default VideoOwner
