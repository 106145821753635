import ReactMarkdown from 'react-markdown'
import { Grid } from '@mui/material'
import MainCard from '../MainPage/MainCard'
import { NewsType } from './News'

const formatDate = (created: string): string => {
  const [year, month, day] = created.split('-')
  return [day, month, year].join('-')
}

const NewsCard = ({ news }: { news: NewsType }) => {
  const totalWidth = '100%'
  const pictureWidth = 200
  return (
    <div style={{ width: totalWidth }}>
      <div style={{ marginTop: 40, fontSize: 25 }}>
        {formatDate(news.created)}
      </div>
      <MainCard>
        <Grid container alignItems='center' justifyContent='space-around'>
          <Grid
            item
            sx={{
              maxWidth: pictureWidth,
              height: '100%',
            }}
            alignItems='center'>
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              direction='row'
              sx={{ height: '100%', width: '100%' }}>
              <img
                style={{
                  width: '100%',
                  maxWidth: 200,
                  height: 'auto',
                  aspectRatio: 1,
                  borderRadius: 10,
                }}
                src={`https://isteadyqabucket.ams3.cdn.digitaloceanspaces.com/images/${news.image__dn}`}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ maxWidth: 600, width: '100%' }}>
            <div>
              <ReactMarkdown>{news.info}</ReactMarkdown>
            </div>
          </Grid>
        </Grid>
      </MainCard>
    </div>
  )
}

export default NewsCard
