import baseColors from '../themes/baseColors'
import { centerStyle, font } from '../themes/generalStyles'
import { Box, Grid, Modal } from '@mui/material'
import { CSSProperties, useContext } from 'react'
import ModalContext from '../Contexts/ModalContext'
import HeaderLine from '../Utils/HeaderLine'
import { Close } from '@mui/icons-material'
import settings from '../config'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const ModalWrapper = ({
  children,
  header,
  openModalKey,
  styles = {},
  onClose = () => null,
  modalSuperHeight = false,
}: {
  children: JSX.Element
  header: string
  openModalKey: string | string[]
  styles?: CSSProperties
  onClose?: () => void
  modalSuperHeight?: boolean
}) => {
  const { smallScreenWidth, screenWidth } = useContext(screenScaleContext)
  const { openModal, setOpenModal } = useContext(ModalContext)
  const modalBodyStyle = {
    backgroundColor: baseColors.modalBackgroundColor,
    borderRadius: 20,
    padding: 0,
    paddingTop: 20,
    maxWidth: '100vw',
    maxHeight: modalSuperHeight ? '97vh' : '80vh',
    ...font,
    ...styles,
    ...(smallScreenWidth
      ? {
          position: 'absolute',
          top: 0,
          maxWidth: screenWidth,
          minHeight: '60vh',
        }
      : {}),
    overflow: 'auto',
  } as CSSProperties
  const bodyPadding = smallScreenWidth ? 10 : 40
  return (
    <Modal
      disableAutoFocus={true}
      open={
        typeof openModalKey === 'string'
          ? openModal === openModalKey
          : openModalKey.includes(openModal)
      }
      onClose={() => {
        setOpenModal('')
        onClose()
      }}
      style={centerStyle}>
      <div style={modalBodyStyle}>
        <Close
          style={{
            position: 'relative',
            zIndex: 999,
            marginTop: 0,
            marginRight: 20,
            float: 'inline-end',
            fontSize: 40,
            cursor: 'pointer',
            borderRadius: 20,
          }}
          sx={{
            backgroundColor: '#e4e3e4',
            '&:hover': { backgroundColor: 'grey' },
          }}
          onClick={() => setOpenModal('')}
        />
        {header && (
          <div style={{ transform: 'translate(0px, -30px)' }}>
            <Grid container justifyContent='center'>
              <h1
                style={{
                  paddingLeft: 30,
                  margin: 0,
                  textAlign: 'center',
                  marginRight: 70,
                }}>
                {header}
              </h1>
            </Grid>
            <HeaderLine />
          </div>
        )}
        <div
          style={{
            overflow: 'auto',
            paddingTop: 0,
            paddingBottom: bodyPadding,
            paddingLeft: bodyPadding,
            paddingRight: bodyPadding,
          }}>
          {children}
          <Grid container justifyContent='center'>
            {openModal === 'subscriptions' && (
              <Box
                sx={{
                  marginTop: 3,
                  width: 300,
                  fontSize: 15,
                  padding: 1,
                  borderRadius: 3,
                }}>
                <Grid container alignItems='center'>
                  <Grid item xs={8}>
                    <div>
                      Vi bruker Stripe som vår betalingsløsningsleverandør
                    </div>
                  </Grid>
                  <Grid item xs={4} justifyContent='flex-end'>
                    <img
                      alt='some id 2'
                      src={`${settings.bucket}/root_images/stripeSmall.png`}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </div>
      </div>
    </Modal>
  )
}

export default ModalWrapper
