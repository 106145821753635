import { Card, CardActions, CardContent } from '@mui/material'
import { CSSProperties, MutableRefObject } from 'react'
import { colors, font } from '../../themes/generalStyles'

const MainCard = ({
  children,
  marginTop = null,
  noPadding = false,
  style = {},
  refCustom = null,
  sx = null,
  cardFooter = null,
  cardHeader = null,
}: {
  children: JSX.Element
  marginTop?: boolean | null
  noPadding?: boolean
  style?: CSSProperties
  refCustom?: MutableRefObject<any> | null
  sx?: null | any
  cardFooter?: JSX.Element | null
  cardHeader?: JSX.Element | null
}) => {
  return (
    <Card
      sx={sx}
      ref={refCustom}
      variant='elevation'
      style={{
        borderRadius: 5,
        ...(marginTop ? { marginTop: 30 } : {}),
        boxShadow: colors.shadow,
        ...style,
      }}>
      {cardHeader && cardHeader}
      <CardContent
        {...(noPadding && { style: { padding: 0, marginBottom: 0 } })}>
        <div style={{ width: '100%', padding: 0, ...font }}>{children}</div>
      </CardContent>
      {cardFooter && (
        <CardActions sx={{ display: 'flex', float: 'right' }}>
          {cardFooter}
        </CardActions>
      )}
    </Card>
  )
}

export default MainCard
