import useApi from '../hooks/useApi'
import { useContext, useEffect } from 'react'
import { postCreateStripePortal } from '../api/fetch'
import { Button, CircularProgress } from '@mui/material'
import { buttonMainStyle } from '../themes/generalStyles'
import UserContext from '../Contexts/UserContext'
import ModalContext from '../Contexts/ModalContext'

const StripeCustomerPortalLink = ({
  customButtonStyle,
}: {
  customButtonStyle: { [key: string]: string | number }
}) => {
  const { setUserLogin } = useContext(UserContext)
  const { setOpenModal } = useContext(ModalContext)
  const { data, doFetch, isLoading } = useApi(postCreateStripePortal)

  useEffect(() => {
    if (data && data.url) {
      // route user to the stripe url
      window.location.href = data.url
    }
  }, [data])

  return (
    <div>
      {isLoading ? (
        <CircularProgress size={30} />
      ) : (
        <Button
          sx={{ ...buttonMainStyle, ...customButtonStyle }}
          onClick={() => {
            doFetch({})
          }}>
          Faktura oversikt
        </Button>
      )}
    </div>
  )
}

export default StripeCustomerPortalLink
