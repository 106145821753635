import { Grid } from '@mui/material'
import { CSSProperties } from 'react'

const Center = ({
  children,
  sx = {},
}: {
  children: JSX.Element | JSX.Element[]
  sx?: CSSProperties
}) => {
  return (
    <Grid container justifyContent='center' sx={sx || {}}>
      {children}
    </Grid>
  )
}

export default Center
