import { useEffect, useRef, useState } from 'react'

export type NowTimeType = [number, number, number]

export const nowTimeFormatter = (): NowTimeType => {
  const d = new Date()
  return [d.getDay(), d.getHours(), d.getMinutes()]
}

const useTime = (): NowTimeType => {
  const intervalRef = useRef<any>(null)
  const [nowTime, setNowTime] = useState<[number, number, number]>(
    nowTimeFormatter(),
  )
  const updateFrequencyMinutes = 1
  useEffect(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(
      () => {
        setNowTime(nowTimeFormatter())
      },
      updateFrequencyMinutes * 60 * 1000,
      [],
    )
  }, [])

  return nowTime
}

export default useTime
