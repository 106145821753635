import useApi, { UseAPIType } from './useApi'
import { postCreateStripePortal, postCreateSubscription } from '../api/fetch'
import { useContext, useState } from 'react'
import modalContext from '../Contexts/ModalContext'
import userContext from '../Contexts/UserContext'

export interface UseRouteToStripeType extends UseAPIType {
  buttonOnClick: (
    subscriptionIsActive: boolean,
    id: string,
    rubberBandId: string,
    couponId: string,
  ) => void
  isError: boolean
}

const useRouteToStripe = (): UseRouteToStripeType => {
  const { userLogin, loggedIn, userData } = useContext(userContext)
  const [isError, setIsError] = useState<boolean>(false)

  // this is for viewing personal info in stripe
  const createSubscriptionResponse = useApi(postCreateSubscription)
  const {
    data,
    doFetch: createPortal,
    isLoading: subIsLoading,
  } = useApi(postCreateStripePortal)

  const buttonOnClick = (
    subscriptionIsActive: boolean,
    id: string,
    rubberBandId: string,
    couponId: string,
  ): void => {
    if (userLogin && loggedIn) {
      if (subscriptionIsActive) {
        createPortal({ onSuccess: data => (window.location.href = data.url) })
      } else {
        createSubscriptionResponse.doFetch({
          onSuccess: e => {
            if (e?.url) {
              window.location.href = e.url
            }
          },
          onError: () => {
            setIsError(true)
          },
          data: {
            subscription_id: id,
            rubber_band_id: rubberBandId,
            coupon_id: couponId,
          },
        })
      }
    }
  }

  return {
    ...createSubscriptionResponse,
    isLoading: createSubscriptionResponse.isLoading || subIsLoading,
    buttonOnClick,
    isError,
  }
}

export default useRouteToStripe
