import ModalWrapper from './ModalWrapper'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import { useContext, useEffect } from 'react'
import WorkoutOverView from '../Views/MainPage/WorkoutCalender/WorkoutOverView'
import WorkoutDetailedInfo from '../Views/MainPage/WorkoutCalender/WorkoutDetailedInfo'
import workoutContext from '../Contexts/WorkoutContext'
import BackButton from '../Utils/BackButton'
import { colors } from '../themes/generalStyles'
import screenScaleContext from '../Contexts/ScreenScaleContext'
import userContext from '../Contexts/UserContext'

const SelectDayTab = () => {
  const { userData } = useContext(userContext)
  const { selectedDay, setSelectedDay } = useContext(workoutContext)
  const allDays = [
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag',
    'Søndag',
  ]
  const dayLookup = (userData?.allSessions || []).map(item =>
    item.day.toLowerCase(),
  )
  const relevantDays = allDays.flatMap(item =>
    dayLookup.includes(item.toLowerCase()) ? item : [],
  )
  useEffect(() => {
    setSelectedDay('')
  }, [])

  return (
    <Grid container justifyContent='center' gap={1} alignItems='center'>
      {relevantDays.map((day, key) => {
        const dayIsSelected = selectedDay === day
        return (
          <Box
            onClick={() =>
              dayIsSelected ? setSelectedDay('') : setSelectedDay(day)
            }
            sx={{
              cursor: 'pointer',
              borderRadius: 3,
              border: `0.5px solid ${
                dayIsSelected ? colors.main : colors.secondGray
              }`,
              marginTop: 1,
              padding: 2,
              backgroundColor: dayIsSelected ? colors.main : colors.lightGray,
              opacity: selectedDay ? (dayIsSelected ? 1 : 0.5) : 1,
              transform: dayIsSelected ? 'scale(1.10)' : 'scale(1)',
              transition: '0.5s',
              color: dayIsSelected ? 'white' : colors.darkgrey,
              '&:hover': {
                transform: 'scale(1.10)',
              },
            }}
            key={key}>
            {day}
          </Box>
        )
      })}
    </Grid>
  )
}

const AddNewWorkout = () => {
  const {
    autoSelectNextWorkout,
    selectedWorkout,
    setSelectedWorkout,
    selectedDay,
  } = useContext(workoutContext)
  const { smallScreenWidth } = useContext(screenScaleContext)

  return (
    <ModalWrapper
      modalSuperHeight={smallScreenWidth}
      styles={{
        width: '100vw',
        maxWidth: 1000,
        minHeight: 500,
      }}
      onClose={() => autoSelectNextWorkout()}
      header='Legg til ny gruppetime'
      openModalKey='addNewWorkout'>
      <div>
        <SelectDayTab />
        {!selectedWorkout && <WorkoutOverView />}
        {selectedDay && selectedWorkout && (
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ marginTop: 0 }}>
            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              sx={{ maxWidth: 800 }}>
              <h1
                style={{
                  textDecoration: 'underline',
                  color: colors.secondGray,
                }}>
                {selectedDay}{' '}
              </h1>
              <BackButton onClick={() => setSelectedWorkout('')} />
              <div style={{ width: '100%' }}>
                <WorkoutDetailedInfo selectedWorkout={selectedWorkout} />
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </ModalWrapper>
  )
}

export default AddNewWorkout
