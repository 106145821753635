import React, { useContext, useEffect, useState } from 'react'
import VideoOwner from './VideoOwner'
import LocalVideo from './LocalVideo'
import BottomBar from './BottomBar'
import ChatBox, { ChatField } from './ChatBox'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import VideoContext from '../../Contexts/VideoContext'
import parseHostChat from './utils/parseHostChat'
import VideoErrorModal from '../../Modals/VideoErrorModal'
import UserContext from '../../Contexts/UserContext'

const Video = () => {
  const { userData } = useContext(UserContext)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const {
    chatMessages,
    localStream,
    sendChatMessage,
    ownerStream,
    userId,
    namesInMeeting,
    activeMic,
    toggleMic,
    toggleCamera,
    activeCamera,
    streamOwner,
  } = useContext(VideoContext)
  const [activeChat, setActiveChat] = useState(false)
  const [unreadMessages, setUnreadMessages] = useState<number>(0)
  const [showBottomBar, setShowBottomBar] = useState(true)
  const offset = smallScreenWidth
    ? activeChat
      ? '100vw'
      : '0px'
    : activeChat
    ? '300px'
    : '0px'
  // read messages each time chat is activated
  useEffect(() => {
    if (activeChat) {
      setShowBottomBar(false)
      setUnreadMessages(0)
    }
  }, [activeChat])

  const totalChat = chatMessages.length

  useEffect(() => {
    if (totalChat) {
      // checking if last message from owner is mute all
      const lastMessage = chatMessages[totalChat - 1]
      parseHostChat(
        streamOwner,
        lastMessage,
        activeMic,
        toggleMic,
        userData?.phoneNumber ?? null,
      )
      if (!activeChat) {
        setUnreadMessages(prevState => prevState + 1)
        setShowBottomBar(true)
      }
    }
  }, [totalChat])

  const onVideoClick = () => {
    setActiveChat(false)
    setShowBottomBar(true)
  }

  // hiding bottom bar after 5 seconds
  useEffect(() => {
    if (showBottomBar) {
      setTimeout(() => {
        setShowBottomBar(false)
      }, 20000)
    }
  }, [showBottomBar, unreadMessages])

  return (
    <div onClick={() => !activeChat && setShowBottomBar(true)}>
      <VideoErrorModal />
      <VideoOwner
        onVideoClick={onVideoClick}
        stream={ownerStream}
        offset={offset}
      />
      <LocalVideo stream={localStream} offset={offset} />
      <BottomBar
        showBottomBar={showBottomBar}
        setActiveChat={setActiveChat}
        unreadMessages={unreadMessages}
        activeCamera={activeCamera}
        activeMic={activeMic}
        toggleMic={toggleMic}
        toggleCamera={toggleCamera}
      />
      {activeChat && (
        <ChatBox
          offset={offset}
          setActiveChat={setActiveChat}
          chatMessages={chatMessages}
          userId={userId}
          sendChatMessage={sendChatMessage}
          namesInMeeting={namesInMeeting}
        />
      )}
      {activeChat && (
        <ChatField sendChatMessage={sendChatMessage} offset={offset} />
      )}
    </div>
  )
}

export default Video
