import MainCard from '../MainPage/MainCard'
import { CardHeader, CircularProgress, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import { standardWidthLarger } from '../../themes/generalStyles'
import Article from './Article'
import ShoppingCart from './ShoppingCart'
import useApi from '../../hooks/useApi'
import { fetchWebshop } from '../../api/fetch'
import { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import ShoppingHistory from './ShoppingHistory'
import screenScaleContext from '../../Contexts/ScreenScaleContext'

export type ArticleType = {
  id: string
  price: number
  description: string
  name: string
  image: string
  priceId: string
}

export type CartType = ArticleType & {
  qty: number
}

type ArticlesFromDb = {
  stripe_id: string
  qty: number
}

const WebShop = () => {
  const { screenWidth } = useContext(screenScaleContext)
  const { doFetch, isLoading, data } = useApi(fetchWebshop)
  const [cart, setCart] = useState<CartType[]>([])
  const smallScreen = screenWidth < 1000
  useEffect(() => {
    if (isEmpty(data)) {
      doFetch({})
    }
  }, [])

  const webshopItems: ArticleType[] = data?.webshopItems || []
  // update cart if started order exists
  const startedOrder = data?.startedOrder || null

  useEffect(() => {
    if (startedOrder) {
      const startedArticles = (
        (startedOrder?.articles as ArticlesFromDb[]) || []
      ).reduce((results, item) => {
        const foundItem = webshopItems.find(a => a.id === item.stripe_id)
        if (foundItem) {
          results.push({ ...foundItem, qty: item.qty })
        }
        return results
      }, [] as CartType[])
      setCart(startedArticles)
    }
  }, [startedOrder])

  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='..'
                sx={{ width: '100%' }}
                content='Nettbutikk'
              />
            </Grid>
          }
        />
      }
      sx={{ width: standardWidthLarger, marginBottom: 10 }}>
      <>
        <Grid
          container
          justifyContent='center'
          gap={3}
          sx={{ marginBottom: 5 }}>
          {isLoading ? (
            <CircularProgress size={50} />
          ) : (
            webshopItems.map((article, key) => (
              <Article key={key} article={article} setCart={setCart} />
            ))
          )}
        </Grid>
        <Grid container sx={{ minHeight: 250 }}>
          <Grid item xs={smallScreen ? 12 : 6} sx={{ padding: 2 }}>
            <ShoppingCart cart={cart} setCart={setCart} />
          </Grid>
          <Grid item xs={smallScreen ? 12 : 6} sx={{ padding: 2 }}>
            <ShoppingHistory />
          </Grid>
        </Grid>
      </>
    </MainCard>
  )
}

export default WebShop
