import { Box, Grid } from '@mui/material'
import { colors } from '../../themes/generalStyles'
import { SessionType } from './Schedule'
import settings from '../../config'
import sessionNames from '../../constants'

const WorkoutSlot = ({
  session,
  setOpenModal,
}: {
  session: SessionType
  setOpenModal: (prev: string) => void
}) => {
  const onClick = () => {
    setOpenModal(session.name)
  }
  const imageBase = {
    [sessionNames['pulse']]: `${settings.bucket}/root_images/pulse.png`,
    [sessionNames[
      'strongKnees'
    ]]: `${settings.bucket}/root_images/strongKnees.png`,
    [sessionNames[
      'strongAndSteady'
    ]]: `${settings.bucket}/root_images/strongArm.png`,
    [sessionNames[
      'strongShoulders'
    ]]: `${settings.bucket}/root_images/strongShoulders.png`,
  }
  // @ts-ignore
  const image: string =
    imageBase[session?.name] || imageBase[sessionNames['pulse']]

  const levelColor =
    session.level === 1
      ? colors.success
      : session.level === 2
      ? colors.warning
      : colors.error

  return (
    <Box
      onClick={onClick}
      sx={{
        padding: 1,
        margin: 1,
        backgroundColor: colors.main,
        color: 'white',
        borderRadius: 5,
        fontSize: '1.5rem',
        transitionDuration: '0.5s',
        boxShadow: colors.shadow,
        border: `1px dashed ${colors.main}`,
        cursor: 'pointer',
        '&:hover': {
          transform: 'scale(1.05)',
        },
      }}>
      <Grid
        direction='column'
        container
        justifyContent='space-between'
        alignItems='center'
        gap={1}
        style={{ position: 'relative', height: 170 }}>
        <strong style={{ fontSize: 18 }}>kl: {session.time}</strong>
        <div style={{ textAlign: 'center' }}>{session.name}</div>
        <img src={image} />
        {session.level !== 0 ? (
          <div
            style={{
              paddingLeft: 4,
              paddingRight: 4,
              textAlign: 'center',
              fontSize: '1rem',
              backgroundColor: levelColor,
              borderRadius: 15,
            }}>
            Nivå: {session.level}
          </div>
        ) : (
          <div></div>
        )}
      </Grid>
    </Box>
  )
}

export default WorkoutSlot
