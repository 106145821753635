import { CardHeader, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import {
  standardPadding,
  standardWidthLarger,
} from '../../themes/generalStyles'
import MainCard from '../MainPage/MainCard'
import ColumnContainer from './ColumnContainer'
import useApi from '../../hooks/useApi'
import { allSessions } from '../../api/fetch'
import { useContext, useEffect, useState } from 'react'
import SessionInfoModal from './SessionInfoModal'
import modalContext from '../../Contexts/ModalContext'
import screenScaleContext from '../../Contexts/ScreenScaleContext'

export type SessionType = {
  day: string
  level: number
  name: string
  time: string
}

const allDays = [
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lørdag',
  'Søndag',
]

const Schedule = () => {
  const { openModal, setOpenModal } = useContext(modalContext)
  const { isLoading, doFetch, data } = useApi(allSessions)
  const { smallScreenWidth } = useContext(screenScaleContext)
  const [selectedSession, setSelectedSession] = useState<string>('')
  useEffect(() => {
    doFetch({})
  }, [])

  const interactModal = (prev: string) => {
    if (prev) {
      setOpenModal('seassionSchedule')
      setSelectedSession(prev)
    } else {
      setOpenModal('')
      setSelectedSession('')
    }
  }
  const sessions: SessionType[] = data?.sessions || []
  return (
    <>
      <SessionInfoModal sessionName={selectedSession} />
      <MainCard
        cardHeader={
          <CardHeader
            title={
              <Grid container>
                <TopLogoCard
                  sx={{ width: '100%' }}
                  content={
                    smallScreenWidth
                      ? 'Gruppe-timeoversikt'
                      : 'Gruppetimeoversikt'
                  }
                />
              </Grid>
            }
          />
        }
        sx={{
          width: standardWidthLarger,
          marginBottom: 10,
        }}>
        <Grid
          container
          justifyContent='center'
          gap={1}
          sx={{
            paddingLeft: standardPadding,
            paddingRight: standardPadding,
            paddingBottom: standardPadding,
          }}>
          {allDays.map((day, key) => (
            <ColumnContainer
              setOpenModal={interactModal}
              sessions={sessions}
              key={key}
              day={day}
              isLoading={isLoading}
            />
          ))}
        </Grid>
      </MainCard>
    </>
  )
}

export default Schedule
