import { ChatMessage } from '@whereby.com/browser-sdk/react'
import { WhereByType } from '../../../hooks/useWhereby'

const parseHostChat = (
  streamOwner: WhereByType['streamOwner'],
  lastMessage: ChatMessage,
  activeMic: WhereByType['activeMic'],
  toggleMic: WhereByType['toggleMic'],
  myPhoneNumber: string | null,
) => {
  // check that the message is indeed the stream owner
  if (streamOwner && lastMessage.senderId === streamOwner.id) {
    const lastMessageText = lastMessage.text.toLowerCase()
    // mute participants
    if (['/muteall', '/mute'].includes(lastMessageText) && activeMic) {
      toggleMic(false)
    } else if (['/endmeeting', '/end'].includes(lastMessageText)) {
      window.location.href = '/'
    } else if (
      myPhoneNumber &&
      [`/reload ${myPhoneNumber}`].includes(lastMessageText)
    ) {
      // reload page for this particular participant
      location.reload()
    }
  }
}

export default parseHostChat
