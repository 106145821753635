import { Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { Toc } from '@mui/icons-material'
import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import ModalContext from '../Contexts/ModalContext'
import UserContext from '../Contexts/UserContext'
import NewsTag from './NewsTag'
import Link from '../Utils/Link'

const UnloggedInMenuTab = () => {
  const { setOpenModal } = useContext(ModalContext)
  const [menuComponent, setMenuComponent] = useState(null)
  const onMenuClose = () => setMenuComponent(null)
  const menuOpen = Boolean(menuComponent)
  return (
    <div style={{ margin: 0 }}>
      <Grid container alignItems='center' justifyContent='center'>
        <IconButton
          style={{ fontSize: 60, marginTop: -10, padding: 0 }}
          onClick={e => (e ? setMenuComponent(e.currentTarget as any) : null)}>
          <Grid container direction='column' alignItems='center'>
            <Toc
              style={{
                marginTop: 0,
                height: 60,
                width: 70,
                padding: 0,
                margin: 0,
              }}
            />
            <span style={{ fontSize: 14, transform: 'translate(0px,-15px)' }}>
              Meny
            </span>
          </Grid>
        </IconButton>
      </Grid>
      <Menu
        id='some unique id'
        open={menuOpen}
        anchorEl={menuComponent}
        onBlur={onMenuClose}>
        <MenuItem
          style={{ height: 50, fontSize: 20 }}
          onClick={() => setOpenModal('login')}>
          Logg inn
        </MenuItem>
        <Link to='/ny-bruker'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>Meld deg inn</MenuItem>
        </Link>
        <Link to='/info-økter'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>
            Om treningstilbudet
          </MenuItem>
        </Link>
        <MenuItem
          style={{ height: 50, fontSize: 20 }}
          onClick={() => setOpenModal('subscriptions')}>
          Medlemskap og priser
        </MenuItem>
        <Link to='/ukeplan'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>
            Gruppetimeoversikt
          </MenuItem>
        </Link>
        <MenuItem
          style={{ height: 50, fontSize: 20 }}
          onClick={() => setOpenModal('contactUs')}>
          Kontakt oss
        </MenuItem>
        <Link to='/om-oss'>
          <MenuItem style={{ height: 50, fontSize: 20 }}>Om oss</MenuItem>
        </Link>
      </Menu>
    </div>
  )
}

export default UnloggedInMenuTab
