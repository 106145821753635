import { Grid } from '@mui/material'
import { centerStyle, font } from '../themes/generalStyles'
import { useContext } from 'react'
import screenScaleContext from '../Contexts/ScreenScaleContext'

const BodyWrapper = ({ children }: { children: JSX.Element }) => {
  const { smallScreenHeight } = useContext(screenScaleContext)
  const bodyStyle = {
    paddingTop: 8,
    marginBottom: smallScreenHeight ? 5 : 20,
    ...font,
  }
  return (
    <Grid container sx={bodyStyle} justifyContent='center'>
      {children}
    </Grid>
  )
}

export default BodyWrapper
