import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { ShopHistoryType } from './ShoppingHistory'
import { isEmpty } from 'lodash'
import { colors } from '../../themes/generalStyles'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import { useContext } from 'react'

const Row = ({ order }: { order: ShopHistoryType }) => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  const date = new Date(order.created)
  const price = order.articles.reduce((results, item) => {
    return results + item.price * item.qty
  }, 0)
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {!smallScreenWidth && (
        <TableCell component='th' scope='row'>
          {date.toLocaleString('en-GB').split(',')[0]}
        </TableCell>
      )}
      <TableCell>
        {smallScreenWidth && date.toLocaleString('en-GB').split(',')[0]}
        {order.articles.map((item, key) => (
          <div key={key}>
            {item.qty} Stk. {item.name}
          </div>
        ))}
      </TableCell>
      <TableCell align='right'>{price},-</TableCell>
      <TableCell align='right'>
        <Chip
          label={order.status}
          sx={{
            backgroundColor:
              order.status === 'betalt' ? colors.main : colors.success,
          }}
        />
      </TableCell>
    </TableRow>
  )
}

const HistoryTable = ({ orders }: { orders: ShopHistoryType[] | null }) => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return orders && !isEmpty(orders) ? (
    <Table sx={{ overflow: 'auto' }}>
      <TableHead>
        <TableRow>
          {!smallScreenWidth && <TableCell>Dato</TableCell>}
          <TableCell>Artikler</TableCell>
          <TableCell align='right'>Pris</TableCell>
          <TableCell align='right'>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((item, key) => (
          <Row key={key} order={item} />
        ))}
      </TableBody>
    </Table>
  ) : (
    <h4 style={{ marginTop: 0 }}>Ingen historikk</h4>
  )
}

export default HistoryTable
