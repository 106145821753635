import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useContext } from 'react'
import createUserContext from '../../../Contexts/CreateUserContext'

const GenderPick = () => {
  const { formData, setFormData } = useContext(createUserContext)
  return (
    <ToggleButtonGroup
      color='success'
      value={formData.gender || undefined}
      aria-label='text formatting'>
      {['Mann', 'Kvinne', 'Annet'].map((item, key) => (
        <ToggleButton
          size={'large'}
          key={key}
          value={item}
          aria-label='bold'
          onClick={() =>
            setFormData(prevState => ({ ...prevState, gender: item }))
          }>
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default GenderPick
