import { CardHeader, Grid } from '@mui/material'
import StrongAndSteadyInfo from '../MainPage/Workouts/StrongAndSteadyInfo'
import ShoulderAndKneesInfo from '../MainPage/Workouts/ShoulderAndKneesInfo'
import MainCard from '../MainPage/MainCard'
import { standardWidthLarger } from '../../themes/generalStyles'
import TopLogoCard from '../MainPage/TopLogoCard'
import { useContext } from 'react'
import screenScaleContext from '../../Contexts/ScreenScaleContext'
import sessionNames from '../../constants'

const WorkoutInfo = () => {
  const { smallScreenWidth } = useContext(screenScaleContext)
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='/'
                sx={{ width: '100%' }}
                content={
                  smallScreenWidth
                    ? 'Om trenings-tilbudet'
                    : 'Om treningstilbudet'
                }
              />
            </Grid>
          }
        />
      }
      sx={{ maxWidth: standardWidthLarger, marginBottom: 10 }}>
      <Grid container justifyContent='center' gap={2}>
        <StrongAndSteadyInfo />
        <ShoulderAndKneesInfo
          selectedWorkout={sessionNames['strongShoulders']}
        />
        <ShoulderAndKneesInfo selectedWorkout={sessionNames['strongKnees']} />
        <ShoulderAndKneesInfo selectedWorkout={sessionNames['pulse']} />
      </Grid>
    </MainCard>
  )
}

export default WorkoutInfo
