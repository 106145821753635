import autoSelected from '../helpers/autoSelected'
import daySorter from '../helpers/daySorter'
import { useContext } from 'react'
import workoutContext, { WorkoutContextType } from '../Contexts/WorkoutContext'
import userContext from '../Contexts/UserContext'

const useAutoSelectNextWorkout = (
  setSelectedWorkoutInfo: WorkoutContextType['setSelectedWorkoutInfo'],
  increment: WorkoutContextType['increment'],
  setPage: WorkoutContextType['setPage'],
) => {
  const { assignedSessions } = useContext(userContext)

  return () => {
    const foundWorkout = autoSelected(assignedSessions)
    if (foundWorkout) {
      setSelectedWorkoutInfo(foundWorkout)
      const day = foundWorkout.day
      const sortedDays = daySorter(Object.keys(assignedSessions))

      const pge = sortedDays.findIndex(item => item === day) + 1 || 2
      setPage(Math.ceil(pge / increment) - 1)
    }
  }
}

export default useAutoSelectNextWorkout
