export const colors = {
  main: '#1DC3CF',
  darkgrey: '#424242',
  success: '#80F6A1',
  warning: '#FFB800',
  error: '#FC6882',
  disabled: '#D9D9D9',
  secondGray: '#969696',
  lightGray: '#EBEBEB',
  shadow:
    '0px 1px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 10px rgba(0, 0, 0, 0.25)',
}
// #0ECBF4
export const font = {
  fontFamily: 'Roboto Flex',
  color: colors.darkgrey,
  fontSize: 20,
}

export const smallTextStyles = {
  ...font,
  fontSize: 15,
}

export const textStyles = {
  ...font,
  fontSize: 20,
}

export const centerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const buttonBase = {
  textTransform: 'none',
  color: colors.darkgrey,
  borderRadius: 2,
  boxShadow: 5,
}

export const buttonMainStyle = {
  backgroundColor: colors.main,
  '&:hover': {
    backgroundColor: colors.main,
    boxShadow: 10,
  },
  ...buttonBase,
  ...font,
  color: 'white',
}

export const buttonSuccessStyle = {
  backgroundColor: colors.success,
  '&:hover': {
    backgroundColor: colors.success,
    boxShadow: 10,
  },
  ...buttonBase,
  ...font,
}

export const buttonWarningStyle = {
  backgroundColor: colors.warning,
  '&:hover': {
    backgroundColor: colors.warning,
    boxShadow: 10,
  },
  ...buttonBase,
  ...font,
}

export const buttonErrorStyle = {
  backgroundColor: colors.error,
  '&:hover': {
    backgroundColor: colors.error,
    boxShadow: 10,
  },
  ...buttonBase,
}

export const buttonDisabledStyle = {
  backgroundColor: colors.disabled,
  '&:hover': {
    backgroundColor: colors.disabled,
    boxShadow: 10,
  },
  ...buttonBase,
  ...font,
}

export const boxNewsStyle = {
  padding: 0,
  cursor: 'pointer',
  transition: '0.5s',
  '&:hover': {
    borderRadius: 2,
    boxShadow: '0px 0px 10px 2px lightgrey',
  },
}

export const standardWidth = 700
export const standardWidthLarger = 1000

export const standardPadding = 5
export const inPagePadding = 10
