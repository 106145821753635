import { CircularProgress, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { useContext } from 'react'
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock'
import createUserContext from '../../../Contexts/CreateUserContext'

const PhoneFormValidator = () => {
  const { formData, setFormData, isLoading } = useContext(createUserContext)
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <PhonelinkLockIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            {!isLoading && <CircularProgress size='' />}
          </InputAdornment>
        ),
      }}
      error={false}
      // helperText='Feil sms kode'
      sx={{ marginTop: 1, width: 200 }}
      label='SMS-kode (bare tall)'
      variant='filled'
      value={formData.sms_code}
      onChange={e =>
        setFormData(prev => ({ ...prev, sms_code: e.target.value || '' }))
      }
      fullWidth
    />
  )
}

export default PhoneFormValidator
