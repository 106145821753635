import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import useApi from '../hooks/useApi'
import { postInitiateSmsAuth, postNewUser } from '../api/fetch'
import { isEmpty } from 'lodash'
import apiResponsFormatter from '../helpers/apiResponsFormatter'
import userContext from './UserContext'

export type FormDataType = {
  address: string
  postal_code: string
  email_address: string
  first_name: string
  last_name: string
  phone_number: string
  birth: string
  sms_code: string
  gender: string
  terms: boolean
}

export type responseType = 'idle' | 'success' | 'error'

type CreateUserContextType = {
  formData: FormDataType
  setFormData: Dispatch<SetStateAction<FormDataType>>
  getSmsCode: () => void
  isLoading: boolean
  smsResponse: responseType
  setSmsResponse: Dispatch<SetStateAction<responseType>>
  createUser: () => void
  createUserResponse: responseType
}

const CreateUserContext = createContext({} as CreateUserContextType)

export const CreateUserContextProvider = ({
  children,
  setIsUser,
}: {
  children: JSX.Element
  setIsUser: Dispatch<SetStateAction<boolean>>
}) => {
  const { userData, setUserData, loggedIn, setUserLogin } =
    useContext(userContext)
  const { doFetch, isLoading } = useApi(postInitiateSmsAuth)
  const {
    data,
    doFetch: createUserFetch,
    isLoading: createUserLoading,
  } = useApi(postNewUser)

  useEffect(() => {
    if (!isEmpty(data)) {
      const created = new Date().getTime()
      setUserData(apiResponsFormatter(data))
      setUserLogin({
        token: data.token,
        phoneNumber: data.phone_number,
        newsCount: data.news_count || 0,
        created: created,
      })
    }
  }, [data])

  const [smsResponse, setSmsResponse] = useState<responseType>('idle')
  const [createUserResponse, setCreateUserResponse] =
    useState<responseType>('idle')
  const getSmsCode = () => {
    doFetch({
      data: {
        phone_number: `+47${formData.phone_number}`,
        create_user_type: true,
      },
      onSuccess: () => setSmsResponse('success'),
      onError: () => setSmsResponse('error'),
    })
  }

  const createUser = () => {
    const { terms, ...dataToSend } = formData
    createUserFetch({
      data: dataToSend,
      onSuccess: () => {
        setIsUser(true)
      },
      onError: () => setCreateUserResponse('error'),
    })
  }
  const [formData, setFormData] = useState<FormDataType>({
    address: '',
    postal_code: '',
    email_address: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    birth: '',
    sms_code: '',
    gender: '',
    terms: false,
  })
  const value = {
    formData,
    setFormData,
    getSmsCode,
    isLoading: isLoading || createUserLoading,
    smsResponse,
    setSmsResponse,
    createUser,
    createUserResponse,
  }
  return (
    <CreateUserContext.Provider value={value}>
      {children}
    </CreateUserContext.Provider>
  )
}

export default CreateUserContext
