import { Box, Grid } from '@mui/material'
import { colors, font } from '../themes/generalStyles'
import { useContext } from 'react'
import ModalContext from '../Contexts/ModalContext'
import baseColors from '../themes/baseColors'
import userContext from '../Contexts/UserContext'
import { useLocation } from 'react-router-dom'

const BottomBar = () => {
  const { loggedIn, fetchingUserData } = useContext(userContext)
  const norwayFlag = require('../Images/norge.png')
  const isteadyLogo = require('../Images/isteadyLogo.png')
  const { setOpenModal } = useContext(ModalContext)
  const overwritingFont = { ...font, fontSize: 15 }
  const { pathname } = useLocation()
  return !loggedIn && pathname === '/' && !fetchingUserData ? (
    <Grid container justifyContent='center'>
      <Box
        position='static'
        sx={{
          width: '100%',
          paddingBottom: 10,
          bottom: 0,
          overflow: 'hidden',
          height: 50,
          backgroundColor: baseColors.navColor,
          ...overwritingFont,
        }}>
        <Grid container justifyContent='center'>
          <Grid
            sx={{
              maxWidth: 800,
              paddingTop: 1,
              paddingBottom: 1,
            }}
            container
            direction='row'
            justifyContent='space-around'
            alignItems='center'>
            <Grid item xs={4}>
              <Grid container direction='column' gap={1}>
                <span
                  onClick={() => (location.href = '/om-oss')}
                  style={{ cursor: 'pointer' }}>
                  <strong>Om oss</strong>
                </span>{' '}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => (location.href = '/user-terms')}>
                  <strong>Brukervilkår</strong>
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => (location.href = '/cookie-terms')}>
                  <strong>Personvern og Cookies</strong>
                </span>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid
                direction='column'
                alignItems='center'
                container
                justifyContent='center'>
                <img
                  alt='iSteady logo image'
                  src={isteadyLogo}
                  style={{ width: 70, height: 70 }}
                />
                <div
                  style={{
                    color: colors.disabled,
                    fontSize: 12,
                    textAlign: 'center',
                  }}>
                  Copyright 2023 <br /> All rights reserved
                </div>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justifyContent='flex-end'>
                <div
                  onClick={() => setOpenModal('contactUs')}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    fontSize: 10,
                  }}>
                  <Grid
                    direction='column'
                    container
                    alignItems='flex-end'
                    rowGap={0.3}
                    justifyContent='space-between'>
                    <img
                      style={{ width: 50, height: 'auto' }}
                      src={norwayFlag}
                    />
                    <strong>Kontakt oss</strong>
                    <div>
                      Kundeservice@isteady.no <br />
                    </div>
                    <div>+47 22 34 33 80</div>
                    <div style={{ textDecoration: 'underline' }}>
                      ISTEADY AS 927622734
                    </div>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  ) : (
    <></>
  )
}

export default BottomBar
