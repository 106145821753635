import { Grid } from '@mui/material'
import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { colors } from '../../themes/generalStyles'
import {
  CameraButton,
  ChatButton,
  LeaveMeeting,
  MicButton,
  SettingsButton,
} from './VideoButtons'
import { WhereByType } from '../../hooks/useWhereby'
import VideoSettingModal from '../../Modals/VideoSettingModal'

const UnreadMessagesBubble = ({
  unreadMessages,
}: {
  unreadMessages: number
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        backgroundColor: 'red',
        color: 'white',
        position: 'inherit',
        width: 20,
        height: 20,
        fontSize: 12,
        transform: 'translate(70px, 10px)',
      }}>
      {unreadMessages}
    </div>
  )
}

const BottomBar = ({
  setActiveChat,
  unreadMessages,
  activeCamera,
  activeMic,
  toggleMic,
  toggleCamera,
  showBottomBar,
}: {
  setActiveChat: Dispatch<SetStateAction<boolean>>
  unreadMessages: number
  activeCamera: boolean
  activeMic: boolean
  toggleMic: WhereByType['toggleMic']
  toggleCamera: WhereByType['toggleCamera']
  showBottomBar: boolean
}) => {
  const [settings, setSettings] = useState(false)

  const style = {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1102,
    bottom: 0,
    left: 0,
    width: '100%',
  } as CSSProperties
  return (
    <>
      {showBottomBar ? (
        <div style={style}>
          <Grid
            sx={{
              maxWidth: 650,
              borderRadius: 8,
              backgroundColor: colors.darkgrey,
              opacity: 0.8,
            }}
            container
            justifyContent='space-around'
            alignItems='center'>
            <Grid item>
              <LeaveMeeting />
            </Grid>

            <Grid item>
              <SettingsButton onClick={() => setSettings(true)} />
            </Grid>
            <Grid item>
              <CameraButton
                activeCamera={activeCamera}
                onClick={() => toggleCamera(!activeCamera)}
              />
            </Grid>
            <Grid item>
              <MicButton
                activeMic={activeMic}
                onClick={() => toggleMic(!activeMic)}
              />
            </Grid>
            <Grid item>
              {!!unreadMessages && (
                <UnreadMessagesBubble unreadMessages={unreadMessages} />
              )}
              <ChatButton
                onClick={() => setActiveChat(prevState => !prevState)}
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
      <VideoSettingModal setOpen={setSettings} open={settings} />
    </>
  )
}

export default BottomBar
