import SelectTraining from './SelectTraining'
import { Grid } from '@mui/material'
import { WorkoutContextProvider } from '../../../Contexts/WorkoutContext'
import TopLogoCard from '../TopLogoCard'
import AddNewWorkout from '../../../Modals/AddNewWorkout'
import WorkoutDetailsSmallDevices from '../../../Modals/WorkoutDetailsSmallDevices'
import { useContext } from 'react'
import userContext from '../../../Contexts/UserContext'
import WelcomeView from '../../WelcomeView/WelcomeView'

const LoggedInPage = () => {
  return (
    <WorkoutContextProvider>
      <Grid container direction='column' gap={1}>
        <AddNewWorkout />
        <WorkoutDetailsSmallDevices />
        <TopLogoCard button={true} content='Din ukeplan' />
        <SelectTraining />
      </Grid>
    </WorkoutContextProvider>
  )
}

export default LoggedInPage
