import {
  ChatMessage,
  RemoteParticipant,
  useLocalMedia,
  UseLocalMediaResult,
  useRoomConnection,
} from '@whereby.com/browser-sdk/react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export type WhereByType = {
  userId: string
  activeCamera: boolean
  toggleCamera: (value: boolean) => void
  activeMic: boolean
  toggleMic: (value: boolean) => void
  localStream: MediaStream | undefined
  ownerStream: RemoteParticipant['stream'] | undefined
  settings: boolean
  setSettings: Dispatch<SetStateAction<boolean>>
  chatMessages: ChatMessage[]
  sendChatMessage: (message: string) => void
  namesInMeeting: { [key: string]: string }
  localMedia: UseLocalMediaResult
  streamOwner: RemoteParticipant | undefined
}

const useWhereby = (url: string, displayName: string): WhereByType => {
  const [settings, setSettings] = useState(false)
  const [initialToggle, setInitialToggle] = useState(false)
  const wherebySettings = {
    audio: true,
    video: true,
  }
  const localMedia = useLocalMedia(wherebySettings)

  const { actions: localMediaActions } = localMedia
  const roomConnection = useRoomConnection(url, {
    displayName: displayName,
    localMedia: localMedia,
  })
  const { state, actions } = roomConnection
  const { toggleMicrophone, toggleCamera } = actions
  const { localParticipant, remoteParticipants, connectionStatus } = state
  // streams to display
  const localStream = localParticipant?.stream
  const streamOwner = remoteParticipants.find(
    item =>
      (item as any)?.role &&
      ['host', 'owner'].includes((item as any).role.roleName),
  )
  const ownerStream = streamOwner ? streamOwner.stream : undefined
  const sendChatMessage = (message: string) => {
    actions.sendChatMessage(message)
  }

  const user = state?.localParticipant
  const userId = user?.id || ''

  const namesInMeeting = remoteParticipants.reduce((results, item) => {
    if (item.id && item.displayName) {
      results[item.id] = item.displayName
    }
    return results
  }, {} as { [key: string]: string })

  const toggleCameraCustom = (value: boolean) => {
    toggleCamera(value)
  }
  const toggleMic = (value: boolean) => {
    toggleMicrophone(value)
  }
  useEffect(() => {
    if (localStream && ownerStream && !initialToggle) {
      toggleMicrophone(false)
      setInitialToggle(true)
    }
  }, [localStream, ownerStream])

  const activeCamera = !!user?.isVideoEnabled
  const activeMic = !!user?.isAudioEnabled

  return {
    userId,
    activeCamera,
    toggleCamera: toggleCameraCustom,
    activeMic,
    toggleMic,
    localStream,
    ownerStream,
    settings,
    setSettings,
    chatMessages: state.chatMessages,
    sendChatMessage,
    namesInMeeting,
    localMedia,
    streamOwner,
  }
}

export default useWhereby
