import { MenuItem, Select } from '@mui/material'
import { WhereByType } from '../../hooks/useWhereby'
import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

const SelectMic = ({
  selectedMic,
  mediaDevices,
  setNewMic,
}: {
  selectedMic: string | undefined
  mediaDevices: MediaDeviceInfo[]
  setNewMic: (newMic: string) => void
}) => {
  return (
    <Select value={selectedMic} label='' fullWidth>
      {(mediaDevices || []).map((device, index) => (
        <MenuItem
          key={index}
          value={device.deviceId}
          onClick={() => setNewMic(device.deviceId)}>
          {device.label}
        </MenuItem>
      ))}
      <MenuItem value='noMic'>Ingen mic</MenuItem>
    </Select>
  )
}

export default SelectMic
