import { Button, Grid } from '@mui/material'
import { buttonErrorStyle, colors, font } from '../../themes/generalStyles'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import MicOffIcon from '@mui/icons-material/MicOff'
import MicIcon from '@mui/icons-material/Mic'
import React from 'react'
import Link from '../../Utils/Link'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ChatIcon from '@mui/icons-material/Chat'
import SettingsIcon from '@mui/icons-material/Settings'

const simulateButtonStyle = {
  transition: '0.3s',
  cursor: 'pointer',
  '&:hover': {
    borderRadius: 1,
    backgroundColor: colors.secondGray,
    opacity: 0.5,
  },
  color: colors.main,
  minHeight: 80,
  minWidth: 120,
}

export const CameraButton = ({
  activeCamera,
  onClick,
}: {
  activeCamera: boolean
  onClick: () => void
}) => {
  return (
    <Grid
      onClick={() => onClick()}
      sx={simulateButtonStyle}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        {activeCamera ? (
          <VideocamOffIcon fontSize='large' />
        ) : (
          <VideocamIcon fontSize='large' />
        )}
      </Grid>
      <Grid item>Kamera</Grid>
    </Grid>
  )
}

export const ChatButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid
      onClick={() => onClick()}
      sx={simulateButtonStyle}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        <ChatIcon fontSize='large' />
      </Grid>
      <Grid item>Chat</Grid>
    </Grid>
  )
}

export const MicButton = ({
  activeMic,
  onClick,
}: {
  activeMic: boolean
  onClick: () => void
}) => {
  return (
    <Grid
      onClick={() => onClick()}
      sx={simulateButtonStyle}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        {activeMic ? (
          <MicOffIcon fontSize='large' />
        ) : (
          <MicIcon fontSize='large' />
        )}
      </Grid>
      <Grid item>Mikrofon</Grid>
    </Grid>
  )
}

export const SettingsButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Grid
      onClick={() => onClick()}
      sx={simulateButtonStyle}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        <SettingsIcon fontSize='large' />
      </Grid>
      <Grid item>Innstillinger</Grid>
    </Grid>
  )
}

export const LeaveMeeting = () => (
  <Link to='..'>
    <Grid
      sx={simulateButtonStyle}
      container
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Grid item>
        <ExitToAppIcon fontSize='large' />
      </Grid>
      <Grid item>Avslutt</Grid>
    </Grid>
  </Link>
)
