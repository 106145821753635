import { Grid, Grow } from '@mui/material'
import WorkoutBase from '../Workouts/WorkoutBase'
import { useContext } from 'react'
import UserContext from '../../../Contexts/UserContext'
import WorkoutContext from '../../../Contexts/WorkoutContext'
import { isEmpty } from 'lodash'

const WorkoutOverView = () => {
  const { userData } = useContext(UserContext)
  const { setSelectedWorkout, selectedDay } = useContext(WorkoutContext)
  const relevantWorkouts = (userData?.allSessions || []).flatMap(item =>
    item.day === selectedDay?.toLowerCase() ? item.sessionType?.name : [],
  )
  const uniqWorkoutTypes = [...new Set(relevantWorkouts)].sort()
  return (
    <Grid container justifyContent='center' alignItems='center' gap={3}>
      {selectedDay && !isEmpty(uniqWorkoutTypes) ? (
        uniqWorkoutTypes.map((item, key) => (
          <Grid item key={key} onClick={() => setSelectedWorkout(item || '')}>
            <Grow in={true} timeout={1000} unmountOnExit>
              <div>
                <WorkoutBase
                  title={item as string}
                  image={
                    item && userData ? userData?.workoutImages[item] : undefined
                  }
                />
              </div>
            </Grow>
          </Grid>
        ))
      ) : (
        <h1 style={{ textAlign: 'center', color: '#C4C4C4' }}>
          Ingen tilgjengelige økter
        </h1>
      )}
    </Grid>
  )
}

export default WorkoutOverView
