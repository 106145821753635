import { Button } from '@mui/material'
import { buttonDisabledStyle } from '../themes/generalStyles'
import { CSSProperties } from 'react'

const ReloadButton = ({ style = null }: { style?: CSSProperties | null }) => {
  return (
    <Button
      sx={{ ...buttonDisabledStyle, ...(style ?? {}) }}
      onClick={() => location.reload()}>
      Last inn siden på nytt
    </Button>
  )
}

export default ReloadButton
