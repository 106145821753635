import { Button, Grid } from '@mui/material'
import { useContext } from 'react'
import WorkoutContext from '../../../Contexts/WorkoutContext'
import { SessionType } from '../../../helpers/apiResponsFormatter'
import { buttonMainStyle, colors } from '../../../themes/generalStyles'

const AvailableSessions = ({
  headerText,
  marginTop = false,
  workoutSessions,
  mod,
  singleLevelType = false,
}: {
  headerText: string
  marginTop?: boolean
  workoutSessions: SessionType[]
  mod: 'level' | 'time'
  singleLevelType?: boolean
}) => {
  const { setSelectedTime, setSelectedLevel, selectedLevel, selectedTime } =
    useContext(WorkoutContext)

  const { allLevels, allTimes, filteredArray } = workoutSessions.reduce(
    (results, item) => {
      results.allTimes[item.time] = [
        ...(results.allTimes[item.time] || []),
        item.sessionType?.level || 0,
      ]

      item.sessionType?.level &&
        (results.allLevels[item.sessionType.level] = [
          ...(results.allLevels[item.sessionType.level] || []),
          item.time,
        ])

      // finally filter out duplicates
      const itemToPush =
        mod === 'level' ? String(item.sessionType?.level || '') : item.time
      !results.filteredArray.includes(itemToPush) &&
        results.filteredArray.push(itemToPush)
      return results
    },
    { allLevels: {}, allTimes: {}, filteredArray: [] } as {
      allLevels: { [key: number]: string[] }
      allTimes: { [key: string]: number[] }
      filteredArray: string[]
    },
  )
  const arrayToShow =
    mod === 'time' && !singleLevelType
      ? allLevels[selectedLevel || 0] || []
      : filteredArray

  const buttonStyles = {
    border: `1px solid ${colors.darkgrey}`,
    padding: 10,
    color: colors.darkgrey,
    width: 100,
    height: 50,
    fontSize: 20,
    borderRadius: 8,
  }

  const buttonOnClick = (selected: boolean, item: string) => {
    if (singleLevelType) {
      if (selected) {
        setSelectedLevel(null)
        setSelectedTime('')
      } else {
        setSelectedLevel(0)
        setSelectedTime(item)
      }
    } else {
      if (selected) {
        if (mod === 'level') {
          setSelectedLevel(null)
          setSelectedTime('')
        } else {
          setSelectedTime('')
        }
      } else {
        if (mod === 'level') {
          setSelectedLevel(Number(item) || null)
          setSelectedTime('')
        } else {
          setSelectedTime(item)
        }
      }
    }
  }

  return (
    <Grid
      container
      direction='column'
      gap={2}
      alignItems='center'
      style={marginTop ? { marginTop: 30 } : {}}>
      <span style={{ maxWidth: 100 }}>{headerText}</span>
      {arrayToShow.sort().map((item, key) => {
        const selected =
          mod === 'level'
            ? selectedLevel === Number(item)
            : selectedTime === item

        const isUnavailable = singleLevelType
          ? false
          : mod === 'level'
          ? Boolean(
              selectedTime &&
                !(allTimes[selectedTime] || []).includes(Number(item) || 0),
            )
          : Boolean(
              selectedLevel && !(allLevels[selectedLevel] || []).includes(item),
            )
        return (
          <div key={key}>
            <Button
              sx={buttonMainStyle}
              style={{
                ...buttonStyles,
                backgroundColor: !isUnavailable
                  ? selected
                    ? colors.main
                    : 'white'
                  : colors.disabled,
              }}
              disabled={isUnavailable}
              onClick={() => buttonOnClick(selected, item)}>
              <strong>{mod === 'level' ? `Nivå ${item}` : `${item}`}</strong>
            </Button>
          </div>
        )
      })}
    </Grid>
  )
}

export default AvailableSessions
