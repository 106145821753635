import { createContext } from 'react'

type ScreenScaleContextType = {
  screenWidth: number
  screenHeight: number
  smallScreenWidth: boolean
  smallScreenHeight: boolean
}

const ScreenScaleContext = createContext<ScreenScaleContextType>(
  {} as ScreenScaleContextType,
)

export const ScreenScaleContextProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const screenWidth = screen.width
  const screenHeight = screen.height
  const smallScreenWidth = screenWidth < 600
  const smallScreenHeight = screenWidth < 1000
  const value = {
    screenWidth,
    screenHeight,
    smallScreenWidth,
    smallScreenHeight,
  }
  return (
    <ScreenScaleContext.Provider value={value}>
      {children}
    </ScreenScaleContext.Provider>
  )
}

export default ScreenScaleContext
