import ModalWrapper from './ModalWrapper'
import TextInputField from '../Utils/TextInputField'
import { useContext, useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material'
import baseColors from '../themes/baseColors'
import HouseIcon from '@mui/icons-material/House'
import PersonIcon from '@mui/icons-material/Person'
import KeyIcon from '@mui/icons-material/Key'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import { font } from '../themes/generalStyles'
import HeaderLine from '../Utils/HeaderLine'
import { postInitiateSmsAuth, updateDeleteUser } from '../api/fetch'
import useApi from '../hooks/useApi'
import userContext from '../Contexts/UserContext'
import { FormDataType } from '../Contexts/CreateUserContext'

const SeparationHeader = ({ header }: { header: string }) => (
  <>
    <div
      style={{
        ...font,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      }}>
      {header}
    </div>
    <HeaderLine />
  </>
)

const UpdateMyInfo = () => {
  const { userData, setUserLogin, setUserData } = useContext(userContext)
  const [day, setDay] = useState<string | null>(null)
  const [month, setMonth] = useState<string | null>(null)
  const [year, setYear] = useState<string | null>(null)
  const [smsTriggerDisable, setSmsTriggerDisable] = useState(false)
  const [formData, setFormData] = useState<FormDataType>({
    address: '',
    postal_code: '',
    email_address: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    birth: '',
    sms_code: '',
    gender: '',
    terms: false,
  })

  const { isLoading: updateLoading, doFetch: updateUser } =
    useApi(updateDeleteUser)

  // auto fill form data if update mode
  useEffect(() => {
    if (userData) {
      setFormData(prevState => ({
        address: userData.address,
        postal_code: userData.postalCode,
        email_address: userData.emailAddress,
        first_name: userData.firstName,
        last_name: userData.lastName,
        phone_number: userData.phoneNumber.startsWith('+47')
          ? userData.phoneNumber.slice(3)
          : userData.phoneNumber,
        birth: userData.birth,
        sms_code: '',
        gender: userData.gender,
        terms: true,
      }))
      const [yr, mn, dy] = userData.birth.split('-')
      dy && setDay(dy)
      yr && setYear(yr)
      mn && setMonth(mn)
    }
  }, [userData])

  useEffect(() => {
    if (day && month && year) {
      setFormData(prevState => ({
        ...prevState,
        birth: `${year}-${month}-${day}`,
      }))
    } else {
      setFormData(prevState => ({ ...prevState, birth: '' }))
    }
  }, [year, month, day])

  useEffect(() => {
    setSmsTriggerDisable(false)
  }, [formData.phone_number])

  // we ignore phone number until further
  const notValidForms =
    Object.values(formData).filter(item => !item).length !== 1
  const buttonStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: notValidForms ? 'grey' : baseColors.buttonColorPrimary,
    color: 'white',
    height: 40,
  }
  const onButtonClick = () => {
    updateUser({
      data: {
        action: 'update',
        ...formData,
      },
      onSuccess: () => {
        location.href = '/min-side'
      },
    })
  }
  return (
    <>
      <ModalWrapper
        openModalKey={['becomeMember', 'updateMember']}
        header='Oppdater min info'
        styles={{
          position: 'absolute',
          top: 0,
          width: 600,
          height: 500,
          maxWidth: 1000,
          maxHeight: 1000,
        }}>
        <div>
          <Grid container>
            <Grid item xs={8}>
              <TextInputField
                startAdornment={<HouseIcon />}
                propKey='address'
                value={formData.address}
                label='Adresse'
                setField={setFormData}
              />
            </Grid>
            <Grid item xs={4}>
              <div style={{ marginLeft: 10 }}>
                <TextInputField
                  startAdornment={<HouseIcon />}
                  propKey='postal_code'
                  value={formData.postal_code}
                  label='Post kode'
                  setField={setFormData}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <TextInputField
                startAdornment={<AlternateEmailIcon />}
                propKey='email_address'
                value={formData.email_address}
                label='Epost'
                setField={setFormData}
              />
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginLeft: 10 }}>
                <TextInputField
                  disabled={true}
                  startAdornment={<span>+47</span>}
                  propKey='phone_number'
                  value={formData.phone_number}
                  label='Telefon'
                  setField={setFormData}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <div>
                <TextInputField
                  startAdornment={<PersonIcon />}
                  propKey='first_name'
                  value={formData.first_name}
                  label='Fornavn'
                  setField={setFormData}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginLeft: 10 }}>
                <TextInputField
                  startAdornment={<PersonIcon />}
                  propKey='last_name'
                  value={formData.last_name}
                  label='Etternavn'
                  setField={setFormData}
                />
              </div>
            </Grid>
          </Grid>
          <SeparationHeader header='Fødselsdato' />
          <Grid
            container
            direction='row'
            style={{ marginTop: 20 }}
            gap={1}
            justifyContent='space-between'>
            <Grid item xs={3.5}>
              <Autocomplete
                fullWidth
                disablePortal
                value={day}
                id='some id again 22'
                options={[...Array(31)].map((_, key) => String(key + 1))}
                onChange={(_, option, a) => {
                  if (a === 'clear') {
                    setDay(null)
                  } else {
                    setDay(option)
                  }
                }}
                renderInput={params => <TextField {...params} label='Dag' />}
              />
            </Grid>
            <Grid item xs={3.5}>
              <Autocomplete
                fullWidth
                disablePortal
                value={month}
                id='some id again 222'
                options={[...Array(12)].map((_, key) => String(key + 1))}
                onChange={(_, option, a) => {
                  if (a === 'clear') {
                    setMonth(null)
                  } else {
                    setMonth(option)
                  }
                }}
                renderInput={params => <TextField {...params} label='Måned' />}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                disablePortal
                value={year}
                id='some id again 2222'
                options={[...Array(122)].map((_, key) => String(key + 1900))}
                onChange={(_, option, a) => {
                  if (a === 'clear') {
                    setYear(null)
                  } else {
                    setYear(option)
                  }
                }}
                renderInput={params => (
                  <TextField {...params} value={year} label='År' />
                )}
              />
            </Grid>
          </Grid>
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 20,
            }}>
            <Button
              disabled={notValidForms}
              onClick={() => onButtonClick()}
              style={{ ...buttonStyle }}>
              {updateLoading ? (
                <CircularProgress size={30} />
              ) : (
                'Oppdater informasjon'
              )}
            </Button>
          </span>
        </div>
      </ModalWrapper>
    </>
  )
}

export default UpdateMyInfo
