import GenderPick from './GenderPick'
import TextInputField from '../../../Utils/TextInputField'
import PersonIcon from '@mui/icons-material/Person'
import { useContext } from 'react'
import createUserContext from '../../../Contexts/CreateUserContext'
import { Grid } from '@mui/material'
import BirthDateSelect from '../../../Utils/BirthDateSelect'

const PageOne = () => {
  const { formData, setFormData } = useContext(createUserContext)
  return (
    <Grid container justifyContent='center'>
      <GenderPick />
      <BirthDateSelect />
      <TextInputField
        startAdornment={<PersonIcon />}
        propKey='first_name'
        value={formData.first_name}
        label='Fornavn'
        setField={setFormData}
      />
      <TextInputField
        startAdornment={<PersonIcon />}
        propKey='last_name'
        value={formData.last_name}
        label='Etternavn'
        setField={setFormData}
      />
    </Grid>
  )
}

export default PageOne
