import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { SessionType } from '../helpers/apiResponsFormatter'
import userContext from './UserContext'
import ActionResponse from '../Modals/ActionResponse'
import SessionInfo from '../Modals/SessionInfo'
import modalContext from './ModalContext'
import screenScaleContext from './ScreenScaleContext'
import useAutoSelectNextWorkout from '../hooks/useAutoSelectNextWorkout'

export type ApiModalType = {
  key: string
  header: string
  day: string
  level: string
  workoutName: string
  time: string
} | null

export type WorkoutContextType = {
  autoSelectNextWorkout: () => void
  page: number
  selectedDay: string
  setSelectedDay: Dispatch<SetStateAction<string>>
  selectedWorkout: string
  selectedWorkoutInfo: SessionType | null
  setPage: Dispatch<SetStateAction<number>>
  setSelectedWorkout: Dispatch<SetStateAction<string>>
  setSelectedWorkoutInfo: Dispatch<SetStateAction<SessionType | null>>
  selectedLevel: number | null
  setSelectedLevel: Dispatch<SetStateAction<number | null>>
  selectedTime: string
  setSelectedTime: Dispatch<SetStateAction<string>>
  setApiModal: Dispatch<SetStateAction<ApiModalType>>
  increment: number
}

const WorkoutContext = createContext({} as WorkoutContextType)

export const WorkoutContextProvider = ({
  children,
}: {
  children: JSX.Element
}) => {
  const { assignedSessions } = useContext(userContext)
  const { smallScreenWidth, screenWidth } = useContext(screenScaleContext)
  const { openModal } = useContext(modalContext)
  const [selectedDay, setSelectedDay] = useState<string>('Mandag')
  const [selectedWorkout, setSelectedWorkout] = useState<string>('')
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null)
  const [selectedTime, setSelectedTime] = useState<string>('')
  const [selectedWorkoutInfo, setSelectedWorkoutInfo] =
    useState<WorkoutContextType['selectedWorkoutInfo']>(null)
  const [startupRoutineFinished, setStartupRoutineFinished] = useState(false)
  const [apiModal, setApiModal] = useState<ApiModalType>(null)
  const [page, setPage] = useState<number>(0)
  const increment = smallScreenWidth ? 10 : screenWidth < 700 ? 2 : 3
  useEffect(() => {
    setSelectedWorkout('')
    setSelectedLevel(null)
    setSelectedTime('')
  }, [selectedDay])

  useEffect(() => {
    setSelectedTime('')
    setSelectedLevel(null)
  }, [selectedWorkout])

  useEffect(() => {
    if (selectedDay || selectedWorkout) {
      setSelectedWorkoutInfo(null)
    }
  }, [selectedDay, selectedWorkout])

  const autoSelectNextWorkout = useAutoSelectNextWorkout(
    setSelectedWorkoutInfo,
    increment,
    setPage,
  )

  // instantiate workout info with the closest session to current day
  useEffect(() => {
    if (!startupRoutineFinished && Object.keys(assignedSessions).length !== 0) {
      autoSelectNextWorkout()
    }
  }, [])
  const value = {
    autoSelectNextWorkout,
    page,
    selectedDay,
    setSelectedDay,
    selectedWorkout,
    selectedWorkoutInfo,
    setPage,
    setSelectedWorkout,
    setSelectedWorkoutInfo,
    selectedLevel,
    setSelectedLevel,
    selectedTime,
    setSelectedTime,
    setApiModal,
    increment,
  }
  return (
    <WorkoutContext.Provider value={value}>
      <ActionResponse apiModal={apiModal} setApiModal={setApiModal} />
      <SessionInfo />
      {children}
    </WorkoutContext.Provider>
  )
}

export default WorkoutContext
