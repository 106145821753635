import { UserContextType } from '../Contexts/UserContext'
import { SessionType } from './apiResponsFormatter'

export const days = [
  'søndag',
  'mandag',
  'tirsdag',
  'onsdag',
  'torsdag',
  'fredag',
  'lørdag',
]
const createDayList = (from = 'søndag'): string[] => {
  const ind = days.indexOf(from) || 0
  return [...days.slice(ind, days.length), ...days.slice(0, ind)]
}

const autoSelected = (
  assignedSessions: UserContextType['assignedSessions'],
): null | SessionType => {
  const allowedDelayTime = 15 // min
  const days = createDayList()
  // determine which day and time it is
  const dayKey = new Date().getDay()
  const day = days[dayKey] || 'mandag'
  const now = new Date()
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
  )
  const diff =
    Math.floor((now.getTime() - midnight.getTime()) / (1000 * 60)) -
    allowedDelayTime // minutes since midnight
  const daysToLoop = createDayList(day)
  const { objectFound } = daysToLoop.reduce(
    (results, item) => {
      const sessions = assignedSessions[item] || []
      if (!results.objectFound && sessions.length !== 0) {
        const minuteArray = sessions
          .map(it => {
            const [hour, min] = it.time.split(':')
            return Number(hour) * 60 + Number(min)
          })
          .sort((a, b) => a - b)
        if (day === item) {
          const sortedArray = [...minuteArray, diff].sort((a, b) => a - b)
          const currentTimeIndex = sortedArray.indexOf(diff)
          if (currentTimeIndex !== -1 && sortedArray[currentTimeIndex + 1]) {
            const nextTime = sortedArray[currentTimeIndex + 1]
            const hour_ = Math.floor(nextTime / 60)
            const min_ = String(nextTime - hour_ * 60)
            const hourStringified =
              String(hour_).length === 1 ? '0' + String(hour_) : String(hour_)
            const minStringified = min_.length === 1 ? '0' + min_ : min_
            results.objectFound =
              sessions.find(
                it => it.time === `${hourStringified}:${minStringified}`,
              ) || null
          }
        } else {
          const hour_ = Math.floor(minuteArray[0] / 60)
          const min_ = minuteArray[0] - hour_ * 60
          results.objectFound =
            sessions.find(it => {
              const [h, m] = it.time.split(':')
              return Number(h) === hour_ && Number(m) === min_
            }) || null
        }
      }
      return results
    },
    { objectFound: null } as { objectFound: null | SessionType },
  )
  return objectFound
}

export default autoSelected
