import { Box, CircularProgress, Grid } from '@mui/material'
import { colors } from '../../themes/generalStyles'
import WorkoutSlot from './WorkoutSlot'
import { SessionType } from './Schedule'
import { isEmpty, sortBy } from 'lodash'

const ColumnContainer = ({
  sessions,
  day,
  isLoading,
  setOpenModal,
}: {
  sessions: SessionType[]
  day: string
  isLoading: boolean
  setOpenModal: (prev: string) => void
}) => {
  const daySessions = sortBy(
    sessions.filter(session => session.day === day.toLowerCase()),
    ['time'],
  )
  const disabled = isEmpty(daySessions)
  const paddingSides = 10
  const totalWidth = disabled ? '10%' : '20%'

  return disabled ? (
    <></>
  ) : (
    <Box
      sx={{
        width: 200,
        marginBottom: 5,
      }}>
      <Box
        sx={{
          backgroundColor: colors.lightGray,
          display: 'flex',
          justifyContent: 'center',
          height: 35,
          borderRadius: 2,
          fontSize: disabled ? 20 : 25,
        }}>
        <Grid container justifyContent='center' alignContent='center'>
          {day}
        </Grid>
      </Box>
      <Grid container justifyContent='center' sx={{ height: '100%' }}>
        <Box
          sx={{
            width: `calc(100% - ${paddingSides}px)`,
            backgroundColor: colors.secondGray,
            height: '100%',
            borderBottomRightRadius: 4,
            borderBottomLeftRadius: 4,
          }}>
          {isLoading ? (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              sx={{ marginTop: 10 }}>
              <CircularProgress size={50} />
            </Grid>
          ) : !disabled ? (
            daySessions.map((item, key) => (
              <WorkoutSlot
                key={key}
                session={item}
                setOpenModal={setOpenModal}
              />
            ))
          ) : (
            <Grid
              container
              justifyContent='center'
              alignContent='center'
              sx={{ marginTop: 10 }}>
              <div style={{ textAlign: 'center' }}>Ingen timer</div>
            </Grid>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default ColumnContainer
