import { Button, Grid, TextField } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { colors } from '../../themes/generalStyles'
import SendIcon from '@mui/icons-material/Send'
import ChatHistory from './ChatHistory'
import { WhereByType } from '../../hooks/useWhereby'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import baseColors from '../../themes/baseColors'

export const ChatField = ({
  offset,
  sendChatMessage,
}: {
  offset: string
  sendChatMessage: WhereByType['sendChatMessage']
}) => {
  const [chat, setChat] = useState<string | null>(null)
  const [opacity, setOpacity] = useState(0) // Start with opacity 0

  useEffect(() => {
    setOpacity(1) // Change opacity to 1 to trigger the transition
  }, [])

  const onSendChat = (text: string | null) => {
    if (text) {
      setChat('')
      sendChatMessage(text)
    }
  }

  const colorStyle = colors.main
  const chatStyle = {
    position: 'absolute',
    zIndex: 1105,
    width: offset,
    bottom: 0,
    transition: 'opacity 1s ease-in-out',
    opacity: opacity,
    right: 0,
    '& label.Mui-focused': {
      color: colorStyle,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: colorStyle,
    },
    '& .MuiFilledInput-root': {
      '&.Mui-focused': {
        borderColor: colorStyle,
      },
    },
  }

  return (
    <TextField
      onKeyUp={e => e.code === 'Enter' && onSendChat(chat)}
      sx={chatStyle}
      value={chat || ''}
      onChange={e => setChat(e.target.value)}
      id='filled-multiline-flexible'
      label='Skriv en melding'
      rows={1}
      variant='filled'
      InputProps={{
        endAdornment: (
          <Button
            size='large'
            onClick={() => onSendChat(chat)}
            sx={{ color: colors.main }}
            endIcon={<SendIcon />}>
            Send
          </Button>
        ),
      }}
    />
  )
}

const ChatBox = ({
  offset,
  setActiveChat,
  chatMessages,
  sendChatMessage,
  userId,
  namesInMeeting,
}: {
  offset: string
  setActiveChat: Dispatch<SetStateAction<boolean>>
  namesInMeeting: WhereByType['namesInMeeting']
  chatMessages: WhereByType['chatMessages']
  sendChatMessage: WhereByType['sendChatMessage']
  userId: string | null
}) => {
  const [opacity, setOpacity] = useState(0) // Start with opacity 0

  useEffect(() => {
    setOpacity(1) // Change opacity to 1 to trigger the transition
  }, [])
  return (
    <div
      style={{
        transition: 'opacity 1s ease-in-out',
        opacity: opacity,
        position: 'absolute',
        zIndex: 1104,
        top: 0,
        right: 0,
        width: offset,
        height: '100%',
        borderRadius: 2,
        backgroundColor: baseColors.backgroundColor,
      }}>
      <h4
        onClick={() => setActiveChat(false)}
        style={{
          display: 'flex',
          justifyContent: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}>
        <KeyboardBackspaceIcon /> Lukk chat
      </h4>
      <ChatHistory
        namesInMeeting={namesInMeeting}
        chatMessages={chatMessages}
        userId={userId || 'Ukjent navn'}
      />
    </div>
  )
}

export default ChatBox
