import MainCard from '../MainPage/MainCard'
import { CardHeader, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import { standardWidthLarger } from '../../themes/generalStyles'
import settings from '../../config'

const CookieTerms = () => {
  const height = 800
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='..'
                sx={{ width: '100%' }}
                content='iSteady personvern'
              />
            </Grid>
          }
        />
      }
      sx={{ width: standardWidthLarger, marginBottom: 10 }}>
      <Grid container justifyContent='center' gap={3}>
        <h1>iSteady Personvern</h1>
        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
          "Informasjonskapsler (cookies) er små datafiler som lagres på
          datamaskinen, nettbrettet eller mobilenheten din når du besøker et
          nettsted. Disse filene inneholder informasjon om din
          nettleseraktivitet og lar nettstedet huske deg når du kommer tilbake,
          slik at du kan ha en mer personlig og effektiv opplevelse.
          Informasjonskapsler kan brukes til å huske innloggingsdetaljer, lagre
          handlekurven din i nettbutikker, tilpasse innhold og annonser, og
          samle statistikk om nettstedsbruk.
        </div>
        <br />
        <br />
        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
          Vi bruker informasjonskapsler for å forbedre din opplevelse på vårt
          nettsted. Ved å bruke vårt nettsted, samtykker du i bruken av disse
          informasjonskapslene i henhold til våre retningslinjer for personvern.
          Du har imidlertid alltid muligheten til å kontrollere og administrere
          informasjonskapsler gjennom innstillingene i nettleseren din. Du kan
          lese mer om hvordan vi bruker informasjonskapsler og hvilke valg du
          har, i vår personvernerklæring."
        </div>
        <iframe
          title='Personvern'
          style={{ width: '100%', height: height }}
          src={`${settings.bucket}/legal/privacyTerms.pdf`}
        />
      </Grid>
    </MainCard>
  )
}

export default CookieTerms
