import {
  buttonErrorStyle,
  buttonWarningStyle,
  colors,
  standardWidthLarger,
} from '../../themes/generalStyles'
import GridIt from '../../Utils/GridIt'
import MainCard from '../MainPage/MainCard'
import {
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material'
import HeaderLine from '../../Utils/HeaderLine'
import StarIcons from '../../Utils/StarIcons'
import StripeCustomerPortalLink from '../../Utils/StripeCustomerPortalLink'
import { useContext } from 'react'
import UserContext from '../../Contexts/UserContext'
import modalContext from '../../Contexts/ModalContext'
import dataFormatter from './dataFormatter'
import useApi from '../../hooks/useApi'
import { simpleUserUpdate } from '../../api/fetch'
import TopLogoCard from '../MainPage/TopLogoCard'
import MyPageUserDetails from './MyPageUserDetails'

const MyPage = () => {
  const { userData, setUserLogin, updateUserData, mediumScreen } =
    useContext(UserContext)
  const { setOpenModal } = useContext(modalContext)
  const { doFetch, isLoading } = useApi(simpleUserUpdate)
  const sidePaddings = mediumScreen ? 0 : 150
  const customButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
    borderRadius: 1,
    minWidth: 100,
    height: 50,
    fontSize: 16,
    width: 100,
  }
  const underlineStyle = { style: { textDecoration: 'underline' } }
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='/'
                sx={{ width: '100%' }}
                content='Din side'
              />
            </Grid>
          }
        />
      }
      sx={{
        width: standardWidthLarger,
        color: colors.darkgrey,
        marginBottom: 'auto',
      }}>
      <div style={{ paddingLeft: sidePaddings, paddingRight: sidePaddings }}>
        <GridIt
          columns={4}
          placements={[
            ['1', '1:5'],
            ['2', '1:5'],
            ['3', '1:5'],
          ]}>
          <MainCard
            style={{
              backgroundColor: colors.lightGray,
            }}>
            <>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                columns={2}>
                <h4>Antall gruppetimer gjennomført: </h4>
                <div>
                  <Grid container gap={5}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: 50,
                          margin: 10,
                          color: colors.main,
                        }}>
                        <GridIt
                          columns={1}
                          placements={[
                            ['1:3', '1'],
                            ['2', '1'],
                          ]}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}>
                            {userData ? userData.performedSessions : 'Nan'}
                          </div>
                          <div>
                            <HeaderLine
                              styles={{ marginTop: 0, width: 80, height: 4 }}
                            />
                          </div>
                        </GridIt>
                      </div>
                      <div style={{ marginLeft: '0px' }}>
                        <StarIcons
                          count={(userData && userData.performedSessions) || 0}
                        />
                      </div>
                    </div>
                  </Grid>
                </div>
              </Grid>
              <div
                style={{
                  position: 'relative',
                  bottom: -5,
                  right: -18,
                  float: 'right',
                  backgroundColor: colors.disabled,
                  paddingRight: 10,
                  padding: 5,
                  borderRadius: 3,
                }}>
                Medlem siden: {userData && dataFormatter(userData.created)}
              </div>
            </>
          </MainCard>
          <div>
            <MyPageUserDetails />
            <Grid container sx={{ marginTop: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    isLoading ? (
                      <CircularProgress size={25} />
                    ) : (
                      <Checkbox
                        checked={!userData?.notifications}
                        onChange={(_, checked) =>
                          doFetch({
                            data: { notifications: !checked },
                            onSuccess: () => updateUserData(),
                          })
                        }
                      />
                    )
                  }
                  label='Deaktiver sms varsling før time. (anbefales ikke)'
                />
              </FormGroup>
            </Grid>
          </div>
          <div>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              gap={2}
              style={{ marginTop: 20 }}>
              <Button
                sx={{ ...buttonWarningStyle, ...customButtonStyle }}
                onClick={() => setOpenModal('updateMember')}
                style={{}}>
                Rediger
              </Button>
              <StripeCustomerPortalLink customButtonStyle={customButtonStyle} />
              <Button
                sx={{ ...buttonErrorStyle, ...customButtonStyle }}
                onClick={() => setOpenModal('logout')}>
                Logg ut
              </Button>
              <span
                onClick={() => setOpenModal('deleteUser')}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Slett konto
              </span>
            </Grid>
          </div>
        </GridIt>
      </div>
    </MainCard>
  )
}

export default MyPage
