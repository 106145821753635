import { Link as LinkOriginal } from 'react-router-dom'
import { CSSProperties, useContext } from 'react'
import userContext from '../Contexts/UserContext'

const Link = ({
  children,
  to,
  style = {},
}: {
  children: JSX.Element
  to: string
  style?: CSSProperties
}) => {
  const { setPath } = useContext(userContext)
  return (
    <LinkOriginal
      to={to}
      style={{ textDecoration: 'none', color: 'inherit', ...style }}
      onClick={() => setPath(to)}>
      {children}
    </LinkOriginal>
  )
}

export default Link
