import { Box, Button, Grid, Modal } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import MainCard from '../Views/MainPage/MainCard'
import {
  buttonSuccessStyle,
  buttonWarningStyle,
  centerStyle,
  colors,
  font,
} from '../themes/generalStyles'

const RemoveWorkoutModal = ({
  setOpen,
  open,
  exitWorkout,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>
  open: boolean
  exitWorkout: () => void
}) => {
  const buttonStyles = {
    color: colors.darkgrey,
    backgroundColor: colors.lightGray,
    fontSize: 20,
    width: 100,
    borderRadius: 3,
  }

  return (
    <Modal style={centerStyle} open={open} onClose={() => setOpen(false)}>
      <Box>
        <Grid
          sx={{
            padding: 5,
            backgroundColor: colors.lightGray,
            maxWidth: 400,
            border: `2px solid ${colors.darkgrey}`,
            borderRadius: 2,
            ...font,
          }}
          container
          justifyContent='space-between'
          direction='column'>
          <h3 style={{ textAlign: 'center' }}>
            Er du sikker på du har lyst til å melde deg av denne timen?{' '}
          </h3>
          <div>
            <Grid container justifyContent='space-around'>
              <Button
                sx={{ ...buttonStyles, border: `2px solid ${colors.main}` }}
                onClick={() => {
                  setOpen(false)
                  exitWorkout()
                }}>
                Ja
              </Button>
              <Button
                onClick={() => setOpen(false)}
                sx={{ ...buttonStyles, border: `2px solid ${colors.warning}` }}>
                Nei
              </Button>
            </Grid>
          </div>
        </Grid>
      </Box>
    </Modal>
  )
}

export default RemoveWorkoutModal
