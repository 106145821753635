import { Button, CircularProgress, Grid } from '@mui/material'
import { useContext, useEffect } from 'react'
import userContext, { UserContextType } from '../../../Contexts/UserContext'
import workoutContext from '../../../Contexts/WorkoutContext'
import { SessionType } from '../../../helpers/apiResponsFormatter'
import { days } from '../../../helpers/autoSelected'
import useApi from '../../../hooks/useApi'
import { getMeetingInfo } from '../../../api/fetch'
import {
  buttonDisabledStyle,
  buttonSuccessStyle,
} from '../../../themes/generalStyles'
import { useNavigate } from 'react-router-dom'
import useTime, { NowTimeType } from '../../../hooks/useTime'

export const checkTimeAndDay = (
  selectedWorkoutInfo: SessionType | null,
  now: NowTimeType,
): boolean => {
  const [day, hour, min] = now
  const openBeforeMin = 10
  const timeOpenAfterMeetingStart = -20
  const dayStringForm = days[day]
  if (selectedWorkoutInfo) {
    // is correct day
    if (dayStringForm === selectedWorkoutInfo.day) {
      const workoutTimes = selectedWorkoutInfo.time.split(':')
      const [hr, mn] = workoutTimes
      // less than openBeforeMin to:
      if (hr && mn) {
        // number of minutes before workout is scheduled to open
        const diffInMinutes = Number(hr) * 60 + Number(mn) - (hour * 60 + min)
        if (
          diffInMinutes <= openBeforeMin &&
          diffInMinutes >= timeOpenAfterMeetingStart
        ) {
          return true
        }
      }
    }
  }
  return false
}

const JoinWorkoutButton = () => {
  const { userData, loggedIn } = useContext(userContext)
  const { selectedWorkoutInfo } = useContext(workoutContext)
  const now = useTime()
  const navigate = useNavigate()
  const userLoggedInAndValidSubscription = Boolean(
    userData && userData.validSubscription && loggedIn,
  )
  const { doFetch, data, isLoading } = useApi(getMeetingInfo)
  const correctTimeSlot = checkTimeAndDay(selectedWorkoutInfo, now)
  const sessionId: number | null = data?.sessionId || null
  const buttonIsDisabled =
    !userLoggedInAndValidSubscription || !correctTimeSlot || !sessionId
  useEffect(() => {
    if (selectedWorkoutInfo && selectedWorkoutInfo.sessionType) {
      doFetch({
        data: {
          session_id: selectedWorkoutInfo.sessionType.id,
          time: selectedWorkoutInfo.time,
        },
      })
    }
  }, [selectedWorkoutInfo, now])

  const buttonOnClick = () => {
    if (selectedWorkoutInfo && selectedWorkoutInfo.sessionType) {
      if (sessionId) {
        navigate(`/session?sessionId=${sessionId}`)
      }
    }
  }
  return (
    <div style={{ width: 240 }}>
      <Button
        sx={buttonIsDisabled ? buttonDisabledStyle : buttonSuccessStyle}
        disabled={buttonIsDisabled}
        onClick={buttonOnClick}
        style={{
          fontSize: 22,
          fontWeight: 'bold',
          height: 60,
          width: '100%',
          borderRadius: 10,
        }}>
        {isLoading ? <CircularProgress size={30} /> : 'Bli med på trening'}
      </Button>
      <span style={{ fontSize: 15, marginTop: 15, width: '100%' }}>
        <Grid container justifyContent='center'>
          <div style={{ textAlign: 'center', padding: 5 }}>
            Tilgang åpnes ca. 10 minutter før trening starter.
          </div>
        </Grid>
      </span>
    </div>
  )
}

export default JoinWorkoutButton
