import { Grid } from '@mui/material'

const WelcomeToPage = () => {
  return (
    <Grid container direction='column' alignItems='center'>
      <h1>Velkommen til iSteady</h1>
      <Grid container justifyContent='center' sx={{ marginTop: 0 }}>
        <div>
          <ul style={{ maxWidth: 500 }}>
            <li>Du har nå opprettet en iSteady-bruker.</li>
            <li>
              Utforsk gjerne nettsiden, men husk at du må aktivere et medlemskap
              for å ta i bruk tjenesten.
            </li>
          </ul>
        </div>
      </Grid>
    </Grid>
  )
}

export default WelcomeToPage
