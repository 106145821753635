import { Box, Grid } from '@mui/material'

const GridUpDown = ({
  children,
  props = {},
}: {
  children: JSX.Element[]
  props?: object
}) => {
  return (
    <Grid container direction='column' {...props}>
      {children.map((item, key) => (
        <Grid item key={key}>
          <Box
            sx={{
              transition: '0.5s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}>
            {item}
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default GridUpDown
