import { VideoView } from '@whereby.com/browser-sdk/react'
import { colors } from '../../themes/generalStyles'
import { Grid } from '@mui/material'
import { CSSProperties } from 'react'

const LocalVideo = ({
  stream,
  offset,
}: {
  stream: MediaStream | undefined
  offset: string
}) => {
  const allStyles = {
    position: 'absolute',
    transition: 'right 0.5s',
    top: 20,
    right: `calc(20px + ${offset})`,
    zIndex: 1103,
    borderRadius: 10,
    backgroundColor: colors.disabled,
    minHeight: 150,
    maxHeight: 200,
    height: 'auto',
    width: 200,
  } as CSSProperties
  return stream ? (
    <VideoView style={allStyles} mirror stream={stream} muted />
  ) : (
    <Grid
      style={allStyles}
      container
      justifyContent='center'
      alignItems='center'>
      <h4 style={{ color: 'white', textAlign: 'center' }}>
        Kobler til kamera...
      </h4>
    </Grid>
  )
}

export default LocalVideo
