import { MenuItem, Select } from '@mui/material'

const SelectCamera = ({
  mediaDevices,
  selectedCamera,
  setNewCamera,
}: {
  mediaDevices: MediaDeviceInfo[] | null
  selectedCamera: string | undefined
  setNewCamera: (cameraId: string) => void
}) => {
  return (
    <Select value={selectedCamera} label='' fullWidth>
      {(mediaDevices || []).map((device, index) => (
        <MenuItem
          key={index}
          value={device.deviceId}
          onClick={() => {
            setNewCamera(device.deviceId)
          }}>
          {device.label}
        </MenuItem>
      ))}
      <MenuItem value='noCamera'>Default</MenuItem>
    </Select>
  )
}

export default SelectCamera
