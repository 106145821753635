import { Box } from '@mui/material'
import { CSSProperties } from 'react'

const HeaderLine = ({ styles = {} }: { styles?: CSSProperties }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 2,
        backgroundColor: '#00bcd4',
        borderRadius: 1,
        ...styles,
      }}
    />
  )
}

export default HeaderLine
