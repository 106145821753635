import { createContext } from 'react'
import useWhereby, { WhereByType } from '../hooks/useWhereby'

type VideoContextType = WhereByType

const VideoContext = createContext({} as VideoContextType)

export const VideoContextProvider = ({
  children,
  wherebyUrl,
  name,
}: {
  children: JSX.Element
  wherebyUrl: string
  name: string
}) => {
  const {
    userId,
    activeCamera,
    toggleCamera,
    activeMic,
    toggleMic,
    localStream,
    ownerStream,
    settings,
    setSettings,
    chatMessages,
    sendChatMessage,
    namesInMeeting,
    localMedia,
    streamOwner,
  } = useWhereby(wherebyUrl, name)

  const value = {
    localMedia,
    userId,
    activeCamera,
    toggleCamera,
    activeMic,
    toggleMic,
    localStream,
    ownerStream,
    settings,
    setSettings,
    chatMessages,
    sendChatMessage,
    namesInMeeting,
    streamOwner,
  }
  return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
}

export default VideoContext
