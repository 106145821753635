import { font } from '../../../themes/generalStyles'
import { Grid } from '@mui/material'

const NoDaySelectedPlaceholder = () => {
  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ height: 300 }}>
      <h1 style={{ ...font, color: '#C4C4C4' }}>Ingen dag valgt</h1>
    </Grid>
  )
}

export default NoDaySelectedPlaceholder
