import { CircularProgress, Grid } from '@mui/material'
import { useContext } from 'react'
import UserContext from '../../Contexts/UserContext'
import LoggedInPage from './WorkoutCalender/LoggedInPage'
import UnLoggedPage from './UnLoggedPage'
import { font } from '../../themes/generalStyles'

const MainPage = ({ loadingPage }: { loadingPage: boolean }) => {
  const { loggedIn, fetchingUserData } = useContext(UserContext)

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        style={{
          maxWidth: 1000,
          ...font,
        }}>
        {fetchingUserData || loadingPage ? (
          <div style={{ position: 'fixed', top: '40%' }}>
            <CircularProgress size={100} />
          </div>
        ) : !loggedIn ? (
          <UnLoggedPage />
        ) : (
          <LoggedInPage />
        )}
      </Grid>
    </>
  )
}

export default MainPage
