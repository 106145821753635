import { useContext, useEffect } from 'react'
import UserContext from '../../Contexts/UserContext'
import useApi from '../../hooks/useApi'
import { fetchNewsData } from '../../api/fetch'
import { isEmpty, sortBy } from 'lodash'
import {
  colors,
  standardPadding,
  standardWidthLarger,
} from '../../themes/generalStyles'
import { Box, CardHeader, CircularProgress, Grid } from '@mui/material'
import TopLogoCard from '../MainPage/TopLogoCard'
import NewsCard from './NewsCard'
import MainCard from '../MainPage/MainCard'

export type NewsType = {
  title: string
  info: string
  created: string
  image__dn: string
}

const News = () => {
  const { userLogin, setUserLogin, userData, setUserData } =
    useContext(UserContext)
  const { data, doFetch } = useApi(fetchNewsData)
  useEffect(() => {
    if (isEmpty(data) && userLogin && userData) {
      doFetch({
        data: {
          phone_number: userLogin.phoneNumber,
          user_id: userData.id,
        },
        onSuccess: () =>
          setUserData(prevState =>
            prevState ? { ...prevState, newsCount: 0 } : prevState,
          ),
      })
      setUserLogin({ ...userLogin, newsCount: userData.newsCount })
    }
  }, [userData])

  const sortedArr = sortBy(data?.news || [], 'created').reverse()
  return (
    <MainCard
      cardHeader={
        <CardHeader
          title={
            <Grid container>
              <TopLogoCard
                backToButton='/'
                sx={{ width: '100%' }}
                content='Nyheter'
              />
            </Grid>
          }
        />
      }
      sx={{ width: standardWidthLarger, marginBottom: 10 }}>
      <Box sx={{ paddingLeft: standardPadding, paddingRight: standardPadding }}>
        {data && data.news ? (
          isEmpty(data.news) ? (
            <h1
              style={{
                marginTop: 40,
                display: 'flex',
                justifyContent: 'center',
                color: colors.darkgrey,
              }}>
              Ingen nyheter tilgjengelig enda
            </h1>
          ) : (
            sortedArr.map((news: NewsType, key: any) => (
              <NewsCard news={news} key={key} />
            ))
          )
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 150,
            }}>
            <CircularProgress size={60} />
          </div>
        )}
      </Box>
    </MainCard>
  )
}

export default News
