import { CircularProgress, Grid } from '@mui/material'
import HistoryTable from './HistoryTable'
import { shopHistory } from '../../api/fetch'
import useApi from '../../hooks/useApi'
import { useContext, useEffect } from 'react'
import userContext from '../../Contexts/UserContext'
import { isEmpty } from 'lodash'
import { CartType } from './WebShop'

export type ShopHistoryType = {
  articles: CartType[]
  created: string
  id: number
  status: string
  stripe_id: string
}

const ShoppingHistory = () => {
  const { userData } = useContext(userContext)
  const { isLoading, data, doFetch } = useApi(shopHistory)
  useEffect(() => {
    if (!isEmpty(userData) && isEmpty(data)) {
      doFetch({})
    }
  }, [userData])
  const orders = isEmpty(data) ? null : (data.orders as ShopHistoryType[])
  return (
    <Grid
      direction='column'
      container
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        border: '1px dashed black',
        borderRadius: 2,
        height: '100%',
        overflowX: 'hidden',
      }}>
      <h1>Historikk</h1>
      {isLoading ? <CircularProgress /> : <HistoryTable orders={orders} />}
    </Grid>
  )
}

export default ShoppingHistory
