import useApi from '../../hooks/useApi'
import { checkOrderStatus } from '../../api/fetch'
import { useContext, useEffect, useState } from 'react'
import userContext from '../../Contexts/UserContext'
import { isEmpty } from 'lodash'
import { CircularProgress, Grid } from '@mui/material'
import modalContext from '../../Contexts/ModalContext'

const StripeValidationPage = () => {
  const { setOpenModal } = useContext(modalContext)
  const { userData } = useContext(userContext)
  const [isError, setIsError] = useState(false)
  const { doFetch } = useApi(checkOrderStatus)
  useEffect(() => {
    if (!isEmpty(userData)) {
      doFetch({
        onSuccess: () => {
          location.href = '../nettbutikk'
        },
        onError: () => setIsError(true),
      })
    }
  }, [userData])

  return (
    <Grid container justifyContent='center' sx={{ marginTop: '20%' }}>
      {isError ? (
        <div>
          <h1>Det oppstod en feil</h1>
          <div>
            Vennligst{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setOpenModal('contactUs')}>
              kontakt oss.
            </span>
          </div>
        </div>
      ) : (
        <CircularProgress size={100} />
      )}
    </Grid>
  )
}

export default StripeValidationPage
